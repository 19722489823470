<template>
  <div class="modal fade" id="support" tabindex="-1" role="dialog" aria-labelledby="supportLabel"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{tr('Support')}}</h5>
        </div>
        <div class="modal-body form-material">
            <div class="form-row">
                <button type="button" class="btn btn-primary mb-2 mx-2" @click="sendLogs">
                    {{tr('Send Logs by Email')}}
                </button>
                <button type="button" class="btn btn-primary mb-2" @click="clearCache" v-if="systemManager">
                    {{tr('Clear Cache')}}
                </button>
                <button type="button" class="btn btn-primary mb-2" @click="clearDashboard">
                    {{tr('Clear Dashboard')}}
                </button>
            </div>
            <div class="form-row text-small">
                <label class="col-12"><b>Commit Front</b>: {{commit}}</label>
            </div>
            <div class="form-row text-small">
                <label class="col-12"><b>Commit Back</b>: {{commitBack}}</label>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="close">{{tr('Close')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import commit from '@/commit.txt';
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'support',
    mixins: [escape],
    data () {
        return {
            userId: null,
            commit: commit,
            commitBack: null,
        }
    },
    computed: {
        ...mapState({
            systemManager: state => state.main.systemManager,
        }),
    },

    async mounted () {
        this.commitBack = await api.get('/api/get_commit/');
        $('#support').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    methods: {
        async sendLogs () {
            let res = await api.post('/api/send_logs/', JSON.stringify(console.logs));
            alert(tr('Message sent'));
        },
        async clearCache () {
            let res = await api.get('/api/clear_cache_all/');
            alert(tr('Done'));
        },
        close () {
            if (this.userId) {
                let user = _.find(api.tables.user, (r) => r.id == this.userId);
                //api.currentUser = user;
                //this.$store.commit('setUser', user);
            }
            this.$emit('update:show', false);
            this.$emit('close');
            $('#support').modal('hide');
        },
        clearDashboard () {
            localStorage.setItem('dashboard', '');
        }
    },
}
</script>
