<template>
    <div v-if="user" class="row">
        <div class="col-12">
          <div class="row px-3">
            <div class="col-3 form-material">
              <select-input :fieldOptions="statusOptions"
                  label="Status"
                  :currentValue.sync="status"
                  @change="getTickets"
              ></select-input>
            </div>
            <div class="col-3 form-material">
                 <button type="button" class="btn btn-secondary mt-2 mr-3" @click="addNewTicket">NEW TICKET</button>
            </div>
          </div>
        </div>
        <div class="col-12">
            <abm v-if="ready" endpoint="/api/tickets/get" :options="options" tableName="ticket" ref="abm" key="ticket"></abm>
        </div>
        <ticket-comments
            :newTicket="newTicket"
            v-if="ticketId || newTicket"
            :id.sync="ticketId"
            :newTicket.sync="newTicket"
        ></ticket-comments>
    </div>
</template>

<script>
import abm from '@/components/tools/abm';
import Vue from 'vue/dist/vue.esm';
import {ClientTable, Event} from 'vue-tables-2'
let theme;
Vue.use(ClientTable, [theme = 'bootstrap5']);
import ticketComments from '@/components/tools/TicketComments';

import { mapState } from 'vuex';
export default {
    name: 'customer-dashboard',
    components: {
        ClientTable,
        abm,
        'ticket-comments': ticketComments

    },
    data () {
        return {
            status: 'OPEN',
            options: null,
            ready: false,
            newTicket: false,
            statusOptions: [
                { value: 'OPEN', label: 'Open'},
                { value: 'CLOSED', label: 'Closed'},
                { value: 'ALL', label: 'All'},
            ],
            tickets: [],
            options: {},
            ticketId: null,
        }
    },
    async mounted () {
        let options = await api.getModuleFieldsOptions('ticket');
        this.options = _.cloneDeep(options);
        this.options.filters = { Status: this.status };
        this.options.hideDashboard = true;
        this.options.hideFilters = true;
        this.options.fieldsAbmList = this.columnsNames;
        let filters = {
            Status: this.status
        }
        this.options.filters = filters;
        this.options.canAdd = false;
        this.options.showRecord = async (id) => {
            await this.$nextTick();
            this.newTicket = false;
            this.ticketId = id;
        }
        this.ready = true;
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
        customerId () {
            return api.currentUser.CustomerId;
        },
        columnsNames () {
            let res = ['id', 'Subject', 'CreatedUserId', 'CreatedUTC', 'Status', 'ModifiedUTC', 'Priority', 'AssignedUserId'];
            if (!this.customerId) {
                res.push('CustomerId')
                res.push('PriorityValue')
                res.push('TicketTypeId');
            }
            return res;
        }

    },
    methods: {
        async getTickets () {
            let filters = {
                Status: this.status
            }
            this.options.filters = filters;
            this.$refs.abm.fetchList(true);
        },
        addNewTicket () {
            this.newTicket = true;
        },


    }
}
</script>

