class View {

    static options () {
        return {
            fieldsOrder: ['Name']
        }
    }

    static get serverSide () {
        return process.env.SERVER_SIDE[this.table];
    }

    static async getTableOptions (options) {
        let res = options;
        if (!res) {
            res = {
                title: this.table,
                canAdd: true,
            }
        }
        let opt = this.options();
        res.fieldsOrder = opt.fieldsOrder;
        res.actions = opt.actions;
        if (!res.fields) {
            let table = this.table;
            if (options && options.tableName) table = options.tableName;
            let serverFields = await await api.getTableFields(table);
            res.fields = tools.serverTypes(serverFields, res)
        }
        return res;
    }

}

export default View;
