<template>
    <div class="card report-card" :class="{'abm-card': card.component == 'abm'}" v-if="ready">
        <h6 class="section-title mb-0 mt-2 ml-4" v-if="card.Title">
            <label class="ml-4">{{tr(card.Title)}}</label>
        </h6>
        <div class="card-body card-report-body">
            <component :is="component" v-if="card.component"
              v-bind="{...props}"></component>
        </div>
    </div>
</template>

<script>
export default {
    name: 'dashboard-card',
    props: ['card'],
    components: {
    },
    data () {
        return {
            ready: false,
            component: null,
            props: {},
            c: null,
        }
    },
    async mounted () {
        if (this.card.folder) {
            try {
                this.c = importVueComp(this.card.folder, this.card.component);
                this.component = async () => await importVueComp(this.card.folder, this.card.component);
            } catch(ex) {
                return;
            }
            this.props = this.card.props;
            if (!this.props.options && this.card.props.tableName) {
                this.props.options = await api.getModuleFieldsOptions(this.card.props.tableName);
            }
        }
        if (this.c) {
            this.ready = true;
        }
    },

}
</script>
