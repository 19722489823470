<template>
  <div class="modal fade bd-example-modal-lg" id="notification-modal" tabindex="-1" role="dialog"
      data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document"">
      <div class="modal-content">
        <div class="modal-body py-2">
          <div class="row">
                <div class="col-12" v-for="t of text">
                    {{t}}
                </div>
          </div>
        </div>
        <div class="modal-footer mx-3">
            <button @click="close" class="btn waves-effect waves-light btn-primary ml-1">
              {{tr('Done')}}
            </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'notification-modal',
    mixins: [escape],
    props: ['text'],
    async mounted () {
        $('#notification-modal').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    methods: {
        close () {
            this.$emit('update:text', null);
            $('#notification-modal').modal('hide');
        },
    },
}
</script>

<style scoped>
</style>
