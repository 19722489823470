pdfMake.vfs = pdfFonts.pdfMake.vfs;

class PDF {

    constructor (r) {
        this.record = r;
    }

    async create () {
        var docDefinition = {
        content: [
        {
            image: await this.getBase64Image(this.record.Image.id, this.record.Image.url, null, 0.5),
            width: 515,
            margin: [ 0, 0, 0, 5]
        },
        {
          layout: 'noBorders', // optional
          table: {
            widths: [ '35%', '15%', '15%', '35%' ],
            body: [
              [
                { text: tr('PROPOSE OF TRAVEL FOR') + ':', style: 'header1' },
                { text: tr('DURATION') + ':', style: 'header1' },
                { text: tr('TRIP MODE' + ':'), style: 'header1' },
                { text: tr('DIFFICULTY') + ':', style: 'header1' },
              ],
              [
                { text: this.record.PaxName, style: 'header2' },
                { text: this.record.BookingDays.length + ' ' + tr('Days'), style: 'header2' },
                { text: tr(this.record.TripMode.Name), style: 'header2' },
                { text: tr(this.record.Difficulty), style: 'header2' },
              ]
            ]
          }
        },
        {
            columns: [
                {
                  layout: 'noBorders', // optional
                  fillColor: '#F1F1F2',
                  table: {
                    widths: [ '3%', '94%', '3%'],
                    body: [
                      [ {},
                        {
                          alignment: 'center',
                          layout: 'noBorders', // optional
                          fillColor: '#224061',
                          table: {
                            widths: [ '100%'],
                            body: [
                              [{
                                    image: await this.getBase64Image(this.record.User.Image.id, this.record.User.Image.url, {width: 10, color: '#ffffff'}),
                                    width: 80,
                                    alignment: 'center',
                                    margin: [ 0, 5, 0, 0],
                                }],
                              [{ text: this.record.User.FirstName + ' ' + this. record.User.LastName, style: 'userProfile'}],
                              [{ text: 'Travel Advisor', style: 'userProfile' }],
                              [{ text: this.record.User.Email, style: 'userProfile' }],
                              [{ text: this.record.User.Skype, style: 'userProfile' }],
                              [{ text: this.record.User.Phone, style: 'userProfile' }]
                            ]
                          }
                       }, {}],
                      [{}, {text: this.getUserProfile, italics: true, fontSize: 8, alignment: 'justify'}, {}]
                    ]}
                },
                {
                  width: '33%',
                  stack: this.getStacks([
                        {
                          text: this.record.Title,
                          style: 'bookingTitle',
                          margin: [ 0, 0, 0, 3],
                        },
                        {
                          text: this.record.Summary,
                          style: 'bookingSummary',
                          html: true
                        },
                    ])
                }
            ],
            margin: [ 0, 5, 0, 5],
            columnGap: 10
        },
        {
          layout: 'noBorders', // optional
          table: {
            widths: [ '100%'],
            body: await this.getDaysObjects(),
          }
        },
        ],
            pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
                 if (currentNode.id=='day0') return true;
                 return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
            },
            styles: {
                header1: {
                    color: '#ffffff',
                    background: '#224061',
                    fontSize: 8,
                    bold: false,
                    alignment: 'center',
                    fillColor: '#224061',
                    margin: [10, 5, 10, 0]

                },
                header2: {
                    color: '#ffffff',
                    background: '#224061',
                    fontSize: 8,
                    bold: true,
                    alignment: 'center',
                    fillColor: '#224061',
                    margin: [10, 0, 10, 5]
                },
                bookingTitle: {
                    color: '#224061',
                    fontSize: 12,
                    bold: true,
                    italics: true,
                    alignment: 'left',
                },
                bookingSummary: {
                    color: '#224061',
                    lineHeight: 1.2,
                    fontSize: 10,
                    bold: false,
                    italics: true,
                    alignment: 'justify',
                },
                serviceText: {
                    color: '#224061',
                    lineHeight: 1.2,
                    fontSize: 8,
                    alignment: 'justify',
                },
                userProfile: {
                    alignment: 'center',
                    lineHeight: 1.2,
                    bold: true,
                    fontSize: 7,
                    color: '#ffffff',
                    margin: [ 2, 0, 2, 0],
                },


            }
        };
        //console.log(docDefinition)
        pdfMake.createPdf(docDefinition).open();
        this.$emit('update:processingPDF', false);
        clearInterval(this.intervalPDF);

    }

    replaceTagP (t) {
        let start = t.indexOf('<p ');
        let end = t.indexOf('>', start);
        let tag = t.substring(start, end+1);
        return t.replace(tag, '');
    }

    getStacks (objects) {
        let res = [];
        for (let o of objects) {
            if (!o.html) {
                res.push(o)
            } else {
                if (!o.text) continue;
                let texts = o.text
                    .replace(/&nbsp;/g, '')
                    .replace(/<p>/g, '')
                    .replace(/<ul>/g, '')
                    .replace(/<\/ul>/g, '')
                    .replace(/(\r\n|\n|\r)/g,'<br>')
                    .replace(/<li><br><\/li>/g,'')
                    .replace(/<br><\/li>/g,'')
                    .replace(/<\/p>/g, '<br>')
                    .split('<br>');

                let nextTag;
                for (let t of texts) {
                    this.processingText = t;
                    if (nextTag) {
                        t = '<' + nextTag + '>' + t;
                    }
                    let lastTag = this.getLastTag(t);
                    if (lastTag) {
                        t += '</' + lastTag + '>';
                        nextTag = lastTag;
                    } else {
                        nextTag;
                    }
                    let r = {
                        style: o.style
                    }
                    r.text = this.getStyleText(t);
                    res.push(r);
                }
            }
        }
        let r = [];
        for (let p of res) {
            let bullets = false;
            for (let t of p.text) {
                if (t.bullet) {
                    bullets = true;
                    r.push({
                        style: p.style,
                        bullet: {level: 0},
                        text: [t]
                    })
                }
            }
            if (!bullets) {
                r.push(p);
            }
        }
        //console.log(r)
        return r;
    }

    getStyleText (t) {
        let res = t;
        if (res.indexOf('<p ')>-1) {
            res = this.replaceTagP(res);
        }
        res = res.replace(/&amp;/g, '&');
        let styles = [];
        let style = this.processTag(res);
        //console.log(1, style.style.text)
        while (style && style.text) {
            if (style.style) {
                styles.push(style.style);
            }
            style = this.processTag(style.text);
            //console.log(2, style.style.text)
        }
        if (style && style.style) {
            styles.push(style.style);
        }
        return styles;
    }

    processTag (t) {
        let nextTag = this.getTag(t);
        if (nextTag) {
            if (nextTag.start>0) {
                let style = {};
                style.text = t.substring(0, nextTag.start);
                return {style: style, text: t.substring(nextTag.start, t.length)}
            } else {
                let text = t.substring(nextTag.start, nextTag.closeTag);
                return {style: this.getTagStyle(text), text: t.substring(nextTag.closeTag, t.length)};
            }
        } else {
            let style = {};
            style.text = t.substring(0, t.length);
            return {style: style, text: null}
        }
    }

    getTagStyle (text, object) {
        let t = text;
        let res = {}
        if (object) res = object;
        if (t.indexOf('style')>-1 || t.indexOf('class=')>-1) {
            if (t.indexOf('color: rgb')>-1) {
                let colors = t.substring(t.indexOf('rgb(')+4, t.indexOf(')', t.indexOf('rgb(')));
                let c = colors.split(',');
                let color = this.fullColorHex(parseInt(c[0]), parseInt(c[1]), parseInt(c[2]));
                res.color = '#' + color;
            }
            //let tag = t.substring(t.indexOf('<')+1, t.indexOf(' ', t.indexOf('<')));
            let tag = this.getTagName(t, t.indexOf('<'), t.length-1);
            if (t.indexOf('ql-align-justify')>-1) res.justify = true;
            if (tag=='strong') res.bold = true;
            if (tag=='em') res.italics = true;
            if (tag=='li') res.bullet = true;
            if (tag=='u') res.decoration = 'underline';
            let tagEnd = t.indexOf('>', t.indexOf('<')+1);
            let completeTag = t.substring(t.indexOf('<'), tagEnd+1);
            t = t.replace(completeTag, "").replace("</" + tag +">", "");
        }
        if (t.indexOf("<strong>")>-1) {
            t = t.replace("<strong>", "").replace("</strong>", "");
            res.bold = true;
        }
        if (t.indexOf("<em>")>-1) {
            t = t.replace("<em>", "").replace("</em>", "");
            res.italics = true;
        }
        if (t.indexOf("<u>")>-1) {
            t = t.replace("<u>", "").replace("</u>", "");
            res.decoration = 'underline';
        }
        if (t.indexOf("<ul>")>-1) {
            t = t.replace("<ul>", "").replace("</ul>", "");
        }
        if (t.indexOf("<li>")>-1) {
            t = t.replace("<li>", "").replace("</li>", "");
            res.bullet = true;
        }
        let containsTags = (t.indexOf('<')>-1 && t.indexOf('</')>-1);
        if (containsTags) {
            res = this.getTagStyle(t, res);
            return res;
        } else {
            res.text = t;
            return res;
        }
    }

    getTag (t) {
        let tagStart = t.indexOf("<");
        let tagEnd = -1;
        if (tagStart>-1) {
            tagEnd = t.indexOf(">", tagStart);
        }
        if (tagStart>-1 && tagEnd>-1) {
            let tag = this.getTagName(t, tagStart, tagEnd);
            //let tag = t.substring(tagStart+1, tagEnd);
            let closeTag = t.indexOf("</" + tag + ">", tagEnd);
            //console.log(t, tag, closeTag);
            return {tag: t.substring(tagStart+1, tagEnd), start: tagStart, end: tagEnd, closeTag: closeTag + tag.length + 3};
        }
    }

    getLastTag (t) {
        let tagStart = t.lastIndexOf("<");
        if (t[tagStart+1]=='/') return;
        let tagEnd = -1;
        if (tagStart>-1) {
            tagEnd = t.indexOf(">", tagStart);
        }
        if (tagStart>-1 && tagEnd>-1) {
            let tag = this.getTagName(t, tagStart, tagEnd);
            let closeTag = t.indexOf("</" + tag + ">", tagEnd);
            if (closeTag==-1) {
                return tag;
            }
        }
    }

    getTagName (text, start, end) {
        let t = text.substring(start, end+1);
        let tagEnd1 = t.indexOf(" ");
        let tagEnd2 = t.indexOf(">");
        let lTags = [];
        if (tagEnd1>-1) lTags.push(tagEnd1);
        if (tagEnd2>-1) lTags.push(tagEnd2);
        let tagEnd = Math.min(...lTags);
        return t.substring(t.indexOf('<')+1, tagEnd);
    }

    rgbToHex (rgb) {
      let hex = Number(rgb).toString(16);
      if (hex.length < 2) {
           hex = "0" + hex;
      }
      return hex;
    }

    fullColorHex (r,g,b) {
      let red = this.rgbToHex(r);
      let green = this.rgbToHex(g);
      let blue = this.rgbToHex(b);
      return red+green+blue;
    }

    async getDaysObjects () {
        let res = [];
        for (let i in this.getSortedDays) {
            let day = this.getSortedDays[i];
            res.push([await this.dayPrintObject(day, i)]);
        }
        return res;
    }

    async dayPrintObject (day, dayNr) {
        let res =  {
          id: 'day' + day.DayNr,
          layout: 'noBorders', // optional
          layout: {
                hLineWidth: function (i, node) {
                    return 1;
                },
                vLineWidth: function (i, node) {
                    return 0.5;
                },
                hLineColor: function (i, node) {
                    return 'black';
                },
                vLineColor: function (i, node) {
                    return '#224061';
                },
          },

          table: {
            widths: [ '10%', '50%', '40%'],
            body: [
              [
                {
                    border: [false, false, true, false],
                    fillColor: '#F1F1F2',
                    stack: [
                        {
                            text: tr('Day'),
                            alignment: 'center',
                            color: '#224061',
                            fontSize: 10
                        },
                        {
                            text: this.getDatesText(day),
                            alignment: 'center',
                            bold: true,
                            color: '#224061',
                            fontSize: 10
                        }
                    ]
                },
                {
                    border: [false, false, false, false],
                    fillColor: '#F1F1F2',
                    margin: [ 10, 0, 0, 0],
                    stack: [
                        {
                            text: this.getCitiesNames(day),
                            alignment: 'left',
                            color: '#224061',
                            bold: true,
                            fontSize: 10
                        },
                        {
                            text: day.Title,
                            alignment: 'left',
                            color: '#224061',
                            fontSize: 10
                        }
                    ]
                },
                {
                    border: [false, false, false, false],
                    fillColor: '#F1F1F2',
                    text: ''
                }
              ],
              [
                {
                    border: [false, false, true, false],
                    margin: [ 0, 5, 0, 0],
                    fillColor: '#FFFFFF',
                    text: '',
                },
                {
                    border: [false, false, false, false],
                    margin: [ 10, 5, 0, 0],
                    fillColor: '#FFFFFF',
                    stack: this.getServiceObjects(day)
                },
                {
                    border: [false, false, false, false],
                    image: await this.getBase64Image(day.Image.id, day.Image.url, null, 0.5),
                    width: 180,
                    alignment: 'center',
                    margin: [ 0, 5, 0, 0],
                    fillColor: '#FFFFFF',
                }
              ]

            ]
          }

        }
        return res;

    }

    setSelectedHotel (dayNr, i) {
        this.hotelSelected[dayNr] = i;
        this.hotelSelected = Object.assign({}, this.hotelSelected);
    }

    getServiceObjects (day) {
        let stacks = [];
        this.processingDayNr = day.DayNr + 1;
        for (let s of day.BookingDayServices) {
            stacks.push({text: s.Description, html: true, style: 'serviceText'});
            if (!this.typeOfServices[day.DayNr] && s.Service.ServiceType && s.Description && s.Description.length>0) {
                let text = 'Type of service' + ': ' + s.Service.ServiceType.Name;
                //console.log(33, text);
                stacks.push({text: text, html: true, italics: true, bold: true, style: 'serviceText' });
            }
        }
        let res = this.getStacks(stacks);
        return res;
    }

    getHotelObjects (hotelDescription) {
        let stacks = [];
        stacks.push({text: hotelDescription, html: true, style: 'serviceText'});
        let res = this.getStacks(stacks);
        return res;
    }

}
