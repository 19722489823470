<template>
    <div
        class="tab-pane fade"
        :class="{'show active': i==0 && !firstTabFields}"
        :id="'details-' + arrayField.name" role="tabpanel"
        :aria-labelledby="'details-tab-' + arrayField.name" >
      <div class="form-row form-material d-flex align-items-baseline">
        <div v-for="rowField of arrayField.rowFields"
          :class="getColumns(rowField)"
          v-if="!hideIf(rowField)">
            <label>{{tr(rowField.label)}}</label>
        </div>
      </div>

      <div class="form-row form-material d-flex align-items-center mt-1 pt-2"
        v-for="(row, rowNr) of recordRows"
        v-if="!recordRows[rowNr].Closed && (!arrayField.filterRow || arrayField.filterRow(row))">
          <div v-for="rowField of arrayField.rowFields"
            :class="getColumns(rowField)"
            v-if="!hideIf(rowField, row)">
              <row-input-field
                :invalid="record.invalid[arrayField.name] && record.invalid[arrayField.name][rowField.name]"
                :rowField="rowField"
                :defValue.sync="recordRows[rowNr][rowField.name]"
                :arrayField="arrayField"
                :labelAlways="arrayField.labelAlways"
                :imageObject.sync="recordRows[rowNr].Image"
                :row="recordRows[rowNr]"
                :rowNr="rowNr"
                @change="changeInputRow"
              ></row-input-field>
          </div>
          <div class="col pl-3" v-if="!arrayField.readonly">
            <i class="add-item cursor-pointer" @click="removeRow(rowNr)">
                <font-awesome-icon icon="trash-alt"/>
            </i>
          </div>
      </div>
      <div class="form-row">
          <div v-for="field of arrayField.fields"
            class="form-group" :class="getColumns(field)"
            v-if="!field.hidden">
              <input-field
                v-if="!hideIf(field, record)"
                :field="field"
                :invalid="record.invalid[field.name]"
                :defValue.sync="record[field.name]"
                :relationObject="record[field.setRelationTo]"
                :imageObject.sync="record[field.setRelationTo]"
                :attachList.sync="record.attachList"
                :attached.sync="record.attached"
                @change="changeInput(arrayField.name, field.name)"
              ></input-field>
          </div>
      </div>
      <div class="form-group col-md-2 mt-3" v-if="!arrayField.readonly">
        <i class="add-item cursor-pointer" @click="addRow">
            <font-awesome-icon icon="plus-circle"/>
        </i>
      </div>
    </div>

</template>

<script>
export default {
  name: 'record-window-row',
	props: ['arrayField', 'i', 'recordRows', 'record', 'firstTabFields', 'modal'],
	components: {
	},
	computed: {
	    fieldName () {
	        return this.arrayField.fieldName ? this.arrayField.fieldName: this.arrayField.name;
	    },
	},
	methods: {
        async addRow (){
            await vueTools.addRow(this, null, this.arrayField.name, null, this.modal);
        },
        async removeRow (rowNr) {
            if (this.arrayField.closeRow) {
                await vueTools.closeRow(this, null, this.fieldName, rowNr, this.modal);
                return;
            }
            await vueTools.removeRow(this, null, this.fieldName, rowNr, this.modal);
        },
        hideIf (rowField, row) {
            if (rowField.hidden) return true;
            if (rowField.hideIf && row && rowField.hideIf(row)) {
                return true;
            }
        },
        changeInput (arrayFieldName, fieldName) {
            this.$emit('change', [arrayFieldName], fieldName, this.record[arrayFieldName][fieldName]);
        },
        changeInputRow (options, fieldName, value) {
            this.$emit('change', options, fieldName, value);
        },
        getColumns (field) {
            let obj = {};
            let cols = 'col';
            //if (field.columns) return 'col-md-' + field.columns;
            //if (this.columns) return 'col-md-' + this.columns;
            //return 'col';
            if (field.columns) cols = 'col-md-' + field.columns;
            if (this.columns) cols = 'col-md-' + this.columns;
            obj[cols] = true;
            if (field.divRowClass) {
                obj[field.divRowClass] = true;
            }
            return obj;

        },
	}
}
</script>
