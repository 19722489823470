<template>
    <div class="card">

    <div class="form-row form-material p-4">
        <div class="col-8 form-group form-default">
            <input v-model="url" type="text" class="form-control"  :class="{'fill': url}">
                <span class="form-bar"></span>
                <label class="float-label" >URL</label>
            </input>
        </div>
        <div class="col-4 form-group form-default">
            <input v-model="bookingRef" type="text" class="form-control"  :class="{'fill': bookingRef}">
                <span class="form-bar"></span>
                <label class="float-label" >Booking Ref</label>
            </input>
        </div>
        <div class="col-4 form-group form-default">
            <input v-model="contentType" type="text" class="form-control"  :class="{'fill': contentType}">
                <span class="form-bar"></span>
                <label class="float-label" >Content Type</label>
            </input>
        </div>
        <div class="col-4 form-group form-default">
            <input v-model="request" type="text" class="form-control"  :class="{'fill': request}">
                <span class="form-bar"></span>
                <label class="float-label" >URL request</label>
            </input>
        </div>
        <div class="col-4">
            <button type="button" class="btn btn-primary" @click="send">Enviar</button>
        </div>
        <div class="col-12 form-group form-default">
            <textarea v-model="body"  class="form-control" name="body" rows="12" :class="{'fill': body}"></textarea>
            <span class="form-bar"></span>
            <label for="body" class="float-label" >Body</label>
        </div>
        <div class="col-12 form-group form-default">
            <textarea v-model="xml" class="form-control" name="response" rows="12" disabled :class="{'fill': response}"></textarea>
            <span class="form-bar"></span>
            <label for="response" class="float-label" >Response</label>
        </div>
        <vue-json-editor v-model="json" :expandedOnStart="true"></vue-json-editor>
    </div>
    </div>
</template>

<script>
import vueJsonEditor from 'vue-json-editor';
import xml2json from '@/tools/xml2json';
export default {
    name: 'request-page',
    components: {
        'vue-json-editor': vueJsonEditor
    },
    data () {
        return {
            url: 'http://localhost:9500/api/test_xml/',
            body: null,
            contentType: 'text/xml',
            bookingRef: null,
            response: null,
            xml: null,
            json: null,
            request: 'request_url',
        }
    },
    async mounted () {
        let data = localStorage.getItem('api-request');
        if (data) {
            data = JSON.parse(data);
            this.url = data.url;
            this.body = data.body;
            this.contentType = data.contentType;
        }
    },
    computed: {
    },
    methods: {
        async send () {
            let data = {
                url: this.url,
                body: this.body,
                contentType: this.contentType
            }
            localStorage.setItem('api-request', JSON.stringify(data));
            let res = await api.post(`/api/${this.request}/`, JSON.stringify(data), null, (r) => {
                this.response  = r;
            });
            if (res) {
                this.response = res;
                this.xml = res.xml;
                this.json = res.json
            }

                /*if (!this.bookingRef) {
                    let data = {
                        url: this.url,
                        body: this.body,
                        contentType: this.contentType
                    }
                    localStorage.setItem('api-request', JSON.stringify(data))
                    let res = await api.post('/api/request_url/', JSON.stringify(data), null, (r) => {
                        this.response  = r;
                    }, 'https://api-tickets.nubbo.io');
                    if (res) {
                        this.response = res;
                        this.xml = res.xml;
                        this.json = res.json
                    }
                } else {
                    let data = {
                        bookingRef: this.bookingRef,
                        url: this.url,
                        contentType: this.contentType
                    }
                    let res = await api.post('/api/request_booing_ref/', JSON.stringify(data), null, (r) => {
                        this.response  = r;
                    });
                    if (res) {
                        this.response = res;
                        this.xml = res.xml;
                        this.json = res.json
                }*/
        },
        getJSON () {
                this.response = xml2json(this.response, "")
        }

    }
}
</script>

<style scoped>
textarea {
    min-height: 300px;
}
</style>

