import Vue from 'vue';
var EventBus = require('@/tools/event-bus').default;
import * as Sentry from '@sentry/browser';
import Router from '@/router/router';
import store from '@/store/store';
const login = importVueComp('components/tools', 'Login');
const logout = importVueComp('components/tools', 'Logout');
const home = importVueComp('components/pages', 'Home');
import modules from '@/tools/modules';
const abmTable = importVueComp('components/pages', 'AbmTable');
const report = importVueComp('components/pages', 'Report');
const recordWindow = importVueComp('components/tools', 'RecordWindow');
import changePassword from '@/components/pages/ChangePassword';
import dashboard from '@/components/pages/CustomerDashboard';
import requestPage from '@/components/pages/RequestPage';

Vue.use(Router);

let routes = [
        { path: '*', redirect: '/' },
        //{ path: '/', name: 'home', component: home, props: true },
        { path: '/', name: 'home', component: dashboard },
        { path: '/login', component: login, name: 'logout' },
        { path: '/logout', component: logout, name: 'login' },
        { path: '/changepassword', name: 'change-password', component: changePassword },
        { path: '/abm/:table', name: 'abm', component: abmTable, props: true},
        { path: '/abm/:table/:id?', name: 'record', component: recordWindow, props: true},
        { path: '/report/:report', name: 'report', component: report, props: true},
        { path: '/dashboard', name: 'dashboard', component: dashboard },
        { path: '/request', name: 'request', component: requestPage },
]


const router = new Router({
    routes: routes
});


function setRouteHistory (path) {
    let p = path.split('/');
    if (p[1] && p[1]=='public') return;
    if (!parseInt(p[p.length-1])) return;

    let routerHistory = localStorage.getItem('router-history');
    if (!routerHistory) {
        routerHistory = JSON.stringify([]);
    }
    routerHistory = JSON.parse(routerHistory);
    let f = _.findIndex(routerHistory, (r) => r.path == path);
    if (f==-1) {
        if (routerHistory.length>=11) {
            routerHistory.splice(0, 1)
        }
    } else {
        routerHistory.splice(f, 1);
    }
    routerHistory.push({path, name: ''});
    localStorage.setItem('router-history', JSON.stringify(routerHistory));
    EventBus.$emit('update-history');
}

function checkAgency (to, from, next, user, isPublic) {
    if (isPublic) return true;
    if (user && user.AgencyId && to.path != '/logout') {
        api.get("/logout");
        api.logout();
        next('/login');
        return;
    }
    return true;

}

router.beforeEach(async function(to, from, next) {
    console.log(from.path, to.path)
    let changePassword = '/changepassword';
    let modified = store.getters.modified || store.getters.isNew;
    if (modified && to.path != from.path) {
        store.commit('setCallConfirmClose', true);
        store.commit('setToPath', to);
        return;
    }
    let isPublic = false;
    if (to.path.split('/')[1]=='public') {
        isPublic = true;
    }
    let currentUser = api.currentUser;
    if (!checkAgency(to, from, next, currentUser, isPublic)) return;
    if (isPublic) {
        next();
    } else if (to.path == '/login') {
        if (!currentUser) {
            currentUser = await api.getCurrentUser();
        }
        if (currentUser) {
            if (!checkAgency(to, from, next, currentUser, isPublic)) return;
            next('/');
        } else {
            next();
        }
    } else if (to.path == '/logout') {
        next();
        return;
    } else {
        if (!currentUser) {
            let user = await api.getCurrentUser();
            if (!checkAgency(to, from, next, user, isPublic)) return;
            if (!user) {
                next('/login');
                return;
            } else {
                store.commit('setUser', user);
                Sentry.setUser({"name": user.UserName});
                if (user && user.ChangePassword && to.path != changePassword) {
                    router.push(changePassword);
                    return;
                }
            }
        } else if (currentUser && currentUser.ChangePassword && to.path != changePassword) {
            if (to.path != changePassword) {
                if (router.currentRoute.path != changePassword) router.push(changePassword);
            }
            return;
        }
        let matchedPath = frontTools.getMatchedPath(to);
        let userAccess = tools.canAccess(store.state.main.user, 'view', 'GET', matchedPath);
        if (to.path==changePassword) {
            next();
            return;
        } else if (to.path=='/') {
            next();
            return;
        } else if (userAccess) {
            let endpoint = {
                path: to.path,
                name: to.path.replace('/abm/', '').replace('/report/', ''),
                report: to.path.includes('report')
            }
            if (to.params && to.params.table) {
                endpoint.table = to.params.table;
            }
            let module = frontTools.findModule(endpoint);
            if (module) {
                if (!tools.canAccessModule(store.state.main.user, module)) {
                    //if (router.currentRoute.name != 'home') next('/');
                    return;
                }
            }
            next();
            return;
        } else {
            //if (router.currentRoute.name != 'home') next('/');
            return;
        }
    }
});

router.afterEach((to, from) => {
    setRouteHistory(to.path)
    EventBus.$emit('check-marked', to.path);
    if (to.path.split('/')[1]=='public') {
        document.title = process.env.APP_NAME;
        const link = document.querySelector("[rel='icon']")
        link.setAttribute('href', to.meta.icon)
    }

})

export default router;
