<template>
    <div v-if="user">
        <report-window
            v-if="ready"
            endpoint="/reports/timer_list"
            :perPage="500"
            :fields="fields"
            :title="title"
            :headers="headers"
            :current.sync="current"
            :columnClick="columnClick"
            :templates="templates"
            :columnsClasses="columnsClasses"
            :slotsFields="slotsFields"
            :callbacks="callbacks"
            :updateReport="updateReport"
            :allowChart="!!current.GroupBy"
            :chartOptions="chartOptions"
            :totalsOn="['Time', 'TotalTime', 'Cost']"
            :runMounted="true"
        ></report-window>
        <ticket-comments
            v-if="ticketId"
            :id.sync="ticketId"
        ></ticket-comments>

    </div>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import ticketComments from '@/components/tools/TicketComments';
import { mapState } from "vuex";
export default {
    name: "timer-list",
    props: [],
    components: {
        ReportWindow,
        'ticket-comments': ticketComments
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
        }),
    },
    async mounted () {
        frontTools.setReportValues(this);
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data () {
        let self = this;
        return {
            current: {},
            updateReport: false,
            ticketId: null,
            ready: false,
            fields: [
                {name: 'FromDate', editor: 'date', label: 'From'},
                {name: 'ToDate', editor: 'date', label: 'To'},
                {name: 'UserId', label: 'User', editor: 'vue-select', addBlank: true,
                    optionLabels: 'UserName', relation: 'user',
                    filters: (row) => {
                        if (row.Closed) return false;
                        if (row.CustomerId) return false;
                        return true;
                    },
                },
                {name: 'CustomerId', label: 'Customer', editor: 'vue-select', addBlank: true,
                    optionLabels: 'Name', relation: 'customer',
                },
                {name: 'TicketTypeId', label: 'Type', editor: 'vue-select', addBlank: true,
                    optionLabels: 'Name', relation: 'tickettype',
                },
                {name: 'Billable', label: 'Billable', editor: 'select', defValue: 'ALL',
                    options: [
                        { label: 'All', value: 'ALL'},
                        { label: 'Billable', value: 'YES'},
                        { label: 'Not Billable', value: 'NO'},
                    ]
                },
                {name: 'GroupBy', label: 'Group by', editor: 'select', addBlank: true,
                    options: [
                        { label: 'User', value: '1'},
                        { label: 'Customer', value: '2'},
                        { label: 'Type', value: '3'},
                    ]
                },
            ],
            title: "Timer List",
            headers: {
            },
            templates: [
                {name: 'StartDateTime', callback: (row) => {
                        if (!row.StartDateTime) return '';
                        return moment.utc(row.StartDateTime).local().format("DD MMM YYYY HH:mm");
                    }
                },
                {name: 'EndDateTime', callback: (row) => {
                        if (!row.EndDateTime) return '';
                        return moment.utc(row.EndDateTime).local().format("DD MMM YYYY HH:mm");
                    }
                },
                {name: 'TotalTime', callback: (row) => {
                        if (row.calculated && row.__total_row__) {
                            if (!row.TotalTime) return;
                            const hours = parseInt(row.TotalTime/3600);
                            const minutes = parseInt((row.TotalTime - hours * 3600) / 60);
                            const seconds = row.TotalTime - hours * 3600 - minutes * 60;
                            return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
                        };
                        if (row.StartDateTime && row.EndDateTime) {
                            let t2 = moment.utc(row.EndDateTime);
                            let elapsedTime = moment.duration(t2.diff(moment.utc(row.StartDateTime)));
                            const hours = elapsedTime.hours();
                            const minutes = elapsedTime.minutes();
                            const seconds = elapsedTime.seconds();
                            return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
                        }
                    }
                },
                {name: 'Time', callback: (row) => {
                        if (row.Time) {
                            const hours = parseInt(row.Time / 60);
                            const minutes = row.Time % 60;
                            return `${this.padZero(hours)}:${this.padZero(minutes)}`;
                        }
                    }
                },
                {name: 'Cost', callback: (row) => {
                        if (!row.Cost) return '';
                        return tools.toNumber(row.Cost, 2);
                    }
                },

            ],
            columnClick: {
                TimerId: (row) => {
                    this.$root.$children[0].openModalRecord({modalRecord: null, modalTable: 'timer', modalId: row.TimerId});
                },
                TicketId: (row) => {
                    this.ticketId = row.TicketId;
                    //this.$root.$children[0].openModalRecord({modalRecord: null, modalTable: 'ticket', modalId: row.TicketId});
                }
            },
            slotsFields: [
                {name: 'Run', editor: 'button', label: 'Start', icon: 'play',
                    classes: 'mb-0', inputClass: ' text-center cursor-pointer',
                }
            ],
            callbacks: {
                'Run': async (fieldName, row, report) => {
                    let data = {
                        ticketId: row.TicketId,
                        subject: row.Subject,
                        customerId: row.CustomerId,
                        ticketTypeId: row.TicketTypeId,
                    }
                    let res = await api.post('/api/timer/start_timer/', JSON.stringify(data))
                    if (res) {
                        this.$root.$children[0].$refs.navBar.$refs.mainTimer.refresh();
                    }

                },
            },
            columnsClasses: {
                TimerId: 'cursor-pointer-report',
                TicketId: 'cursor-pointer-report',
            },
            chartOptions: {
                y: 'Name',
                x: ['Time']
            }

        };
    },
    methods: {
        padZero(num) {
            return num < 10 ? `0${num}` : num;
        },

    }

};
</script>
