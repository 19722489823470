<template>
    <div>
        <nav id="sidebar" :class="{'active': !mobile}">
            <ul class="list-unstyled components">
                <li v-for="(module, name) of modules"  v-if="access[name]">
                    <a :href="'#'+ name" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"
                        @click="toggleShow(name)" :id="name + 'Dropdown'">
                        <font-awesome-icon :icon="module.icon" class="fa-fw mr-2" v-if="module.icon"/> {{tr(module.label)}}
                    </a>
                    <ul class="collapse list-unstyled" :id="name">
                        <menu-item
                            v-for="route of routes[name]"
                            :key="getRoute(route)"
                            :endpoint="route.name"
                            :route="route.route"
                            :report="route.report"
                            :componentName="route.componentName"
                            :apiEndpoint="route.apiEndpoint"
                            :tableName="route.tableName"
                            :callback="route.callback"
                            :label="route.label"
                            :module="module.label"
                            addClass="py-1"
                        ></menu-item>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
const menuItem  = importVueComp('components/tools', 'VueMenuItem');
import { mapState } from "vuex";
import modules from '@/tools/modules';
var sl = require('@/languages/system_languages.json');

export default {
    name: 'side-bar',
    components: {
        'menu-item': menuItem,
    },
    data () {
        return {
            access: {
                systemUser: false,
                canImportFiles: false,
                images: false,
            },
            modules: {},
        };
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            mobile: state => state.main.mobile,
        }),
        customRoutes () {
            let res = {};
        },
        routes () {
            let res = {};
            for (let name in this.modules) {
                if (!this.access[name]) continue;
                if (this.modules[name].endpoints) {
                    for (let t of this.modules[name].endpoints) {
                        if (!res[name]) res[name] = [];
                        let tableName = t.endpoint? null: t;
                        if (t.report) tableName = null;
                        let access = frontTools.routeAccess(t);
                        let def = true;
                        if (this.modules[name].items_access) {
                            def = tools.canAccess(this.$store.state.main.user, 'view', 'GET', this.modules[name].items_access, true);
                        }
                        let userAccess = tools.canAccess(this.$store.state.main.user, 'view', 'GET', `/${access}`, def);
                        if (!userAccess) continue;
                        let endpoint = t.endpoint? t.endpoint: 'abm';
                        let table = {table: tableName}
                        if (tableName) {
                            table = api.importMixinModule(tableName, 'views');
                            if (!table) {
                                table = {table: tableName}
                            }
                        }
                        res[name].push({
                            label: tr(t.label || table.title || tableName),
                            name: endpoint,
                            tableName: tableName,
                            report: t.report,
                            componentName: t.componentName,
                            apiEndpoint: '/api/' + t + '/',
                            access: access,
                        });
                    }
                }
            }
            for (let id in res) {
                res[id].sort((a, b) => {
                    if (tools.normalize(tr(a.label)) > tools.normalize(tr(b.label))) return 1;
                    if (tools.normalize(tr(a.label)) < tools.normalize(tr(b.label))) return -1;
                    return 0;
                });
            }
            return res;
        }
    },
    async mounted () {
        this.modules = modules;
        for (let name in modules) {
            this.access[name] = tools.canAccessModule(this.user, modules[name]);
            if (name == 'systemMenu'  && this.user.UserName == 'admin') {
                this.access[name] = true;
            }
        }
        this.images = tools.canAccess(this.user, "view", "GET", '/images');
        this.access.canImportFiles = true;
        this.modules = Object.assign({}, this.modules);
        this.access = Object.assign({}, this.access);
    },
    methods: {
        userCan (route) {
            if (!route) return true;
            return tools.canAccess(this.user, "api", "GET", action, false);
        },
        userView (route) {
            return tools.canAccess(this.user, "view", "GET", route.access);
        },
        getRoute (route) {
            if (route.tableName) return route.name + '-' + route.tableName;
            if (route.report) return route.name + '-' + route.report;
            return route.name;
        },
        toggleShow (name) {
            for (let i in this.modules) {
                if (i==name) continue;
                let d = $('#' + i)
                if (d[0] && d[0].classList.contains('show')) d.toggleClass('show');
            }
        }
    }
};
</script>
