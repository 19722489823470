
const serverEndpoint = process.env.SERVER_ENDPOINT;


class Api {

    constructor () {
        this.tables = {};
        this.tablesFields = {};
        this.tableOptions = {};
        this.currentUser = null;
        this.processing = false;
        this.serverEndpoint = serverEndpoint;
        this.selectYesNot = null;
        this.selectValue = null;
        /*if (process.env.DOMAINS) {
            this.DOMAINS = Object.assign([], process.env.DOMAINS);
        }*/
        this._user = false;
        this.getCurrentUser();
        this.showAlert = null;
        this.requestTimeout = null;
        this.language = 'en';
    }

    setProcessing (value) {
        this.processing = value;
    }

    async getTableFields (table) {
        if (this.tablesFields[table]) return this.tablesFields[table];
        let res = await api.get('/api/get_table_fields/' + table);
        if (res) {
            this.tablesFields[table] = res;
            return res;
        }
    }

    setTableOptions (table, options) {
        this.tableOptions[table] = options;
    }

    async getCurrencyBase () {
        this.baseCurrency = await this.get('/api/get_base_currency/');
    }

    async updateUser (res) {
        if (!res) {
            this.currentUser = await this.get('/api/current_user/');
        } else {
            this.currentUser = res;
        }
    }

    async getCurrentUser () {
        let res = this.currentUser;
        if (!res) {
            res = await this.get('/api/current_user/');
            this.currentUser = res;
            this._user = true;
        }
        return res;
    }

    logout () {
        this.currentUser = null;
    }

    getTableParams (table, params) {
        let self = this;
        return (async () => {
            let module = self.importMixinModule(table, 'views');
            //if (!params) params = {};
            let options = await module.getTableOptions(params)
            self.tableOptions[table] = options;
            return options;
        })();
    }

    newAlert (newValue) {
        if (!this.showAlert) {
            this.showAlert = newValue;
            return;
        }
        if (newValue.text != this.showAlert.text) {
            this.showAlert = null;
            setTimeout(() => {
                this.showAlert = newValue;
            }, 200);
        }
    }

    async getObjectFromStore (table, id) {
        let res;
        if (this.tables[table]) {
            let res = _.find(this.tables[table], (r) => r.id == id);
            if (res) {
                let syncVersion = await this.get('/api/get_sync_version/' + table + '/' + id );
                if (syncVersion && syncVersion != res.syncVersion) {
                    let res2 = await this.get('/api/' + table + '/' + id);
                    if (res2)  {
                        res2.original_id = id;
                        this.updateToStore(table, res2);
                        return res2;
                    }
                } else {
                    return res;
                }
            }
        }
        res = await this.get('/api/' + table + '/' + id);
        if (res) return res;
    }

    getFromStore (table) {
        if (table == 'settings') return;
        if (this.tables[table]!=null) {
            return this.tables[table];
        }
    }

    async getTable (table) {
        if (table == 'settings') return;
        if (!this.tables[table] || this.tables[table].length ==0) {
            await this.setTable({table});
        }
        if (this.tables[table]!=null) {
            return this.tables[table];
        }
    }


    setTableToStore (table, values) {
        if (!values) return;
        if (this.tables[table] && values.length < this.tables[table]) {
            return
        };
        this.tables[table] = values;
    }

    async setTable ({table, closed}) {
        let c = closed? 'true': 'false';
        let values = await api.get('/api/' + table + '/?IncludeClosed=' + c);
        this.setTableToStore(table, values);
    }

    updateToStore (table, record) {
        let records = this.tables[table];
        if (!records) return;
        let i = _.findIndex(records, (c)=>c.id==record.id);
        if (i>-1) {
            this.tables[table][i] = Object.assign({}, record);
        } else {
            this.tables[table].push(record);
        }
        //this.tables[table] = Object.assign([], this.tables[table]);
    }

    removeFromStore (table, id) {
        let records = this.tables[table];
        if (!records) return;
        let i = _.findIndex(records, (c)=>c.id==id);
        if (i>=0) {
            this.tables[table].splice(i, 1);
            //this.tables[table] = Object.assign([], this.tables[table]);
        }
    }


    async getUser() {
        var user = await this.get('/api/current_user/');
        if (user) {
            user.original_id = user.id;
            return user;
        }
    }

    async get (e, params, handleError, callback) {
        let self = this;
        let endpoint = new URL(this.serverEndpoint + e);
        if (params) {
            endpoint.search = new URLSearchParams(params).toString();
        }
        return new Promise((resolve, reject) => {
            fetch(endpoint, {
                    method: 'get',
                    mode: 'cors',
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    credentials: 'include',
                })
                .then(async function(response) {
                    if (response.status === 200) {
                        if (callback) {
                            callback(resolve(response.json()))
                        } else {
                            resolve(response.json())
                        }
                    } else {
                        let r = tools.responseHelper(response.status, await response.json());
                        console.log('GET', e, 'status = ', response.status);
                        if (handleError && r) {
                            handleError(r)
                        } else if (r && r.detail) {
                            api.showAlert = {text: r.detail , level: 1};
                        }
                        self.setProcessing(false);
                        //resolve(false)
                    }
                })
                .catch((err) => {
                    self.setProcessing(false);
                    api.showAlert = {text: 'Internet connection error', level: 0};
                    let r = tools.responseHelper('Connection refused', null);
                    if (handleError && r) handleError(r);
                    resolve(false)
                    console.log('GET', err.message, err.stack, e, params);
                    //alert(err.message)
                    //reject(err);
                });
        })
    }

    async post (endpoint, data, headers, handleError, server) {
        let self = this;
        let h = headers;
        if (!h) {
            h = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        if (!server) server = this.serverEndpoint;
        return new Promise((resolve, reject) => {
            fetch( server + endpoint, {
                    method: 'POST',
                    mode: 'cors',
                    credentials: 'include',
                    headers: h,
                    body: data
                })
                .then(async function(response) {
                    if (response.status === 200) {
                        //console.log('POST RESPONSE', response)
                        resolve(response.json());
                    } else {
                        let r = tools.responseHelper(response.status, await response.json());
                        if (handleError && r) {
                            handleError(r)
                        } else if (r && r.detail) {
                            api.showAlert = {text: r, level: 1};
                            self.setProcessing(false);
                        }
                        resolve(false)
                    }
                })
                .catch(function(err) {
                    self.setProcessing(false);
                    api.showAlert = {text: 'Internet connection error', level: 0};
                    let r = tools.responseHelper(err.message, null, handleError);
                    if (handleError && r) handleError(r);
                    resolve(false)
                    //console.log(3, 'POST', err.message, err.stack, endpoint);
                    //reject(err);
                });
        })
    }

    async remove (endpoint, id, handleError) {
        let self = this;
        return new Promise((resolve, reject) => {
            fetch(self.serverEndpoint + endpoint + id, {
                    method: 'DELETE',
                    mode: 'cors',
                    credentials: 'include',
                })
                .then(async function(response) {
                    if (response.status === 200) {
                        resolve(response.json())
                    } else {
                        let r = tools.responseHelper(response.status, await response.json());
                        if (handleError && r) {
                            handleError(r, response)
                        } else if (r && r.detail) {
                            api.showAlert = {text: r, level: 1};
                        }
                        self.setProcessing(false);
                    }
                })
                .catch(function(err) {
                    self.setProcessing(false);
                    api.showAlert = {text: 'Internet connection error', level: 0};
                    console.log('REMOVE', err.message, err.stack, endpoint, id);
                    resolve(false);
                    reject(err);
                });
        })
    }

    save (endpoint, record, attachFiles, tableName, handleError) {
        let self = this;
        return new Promise((resolve, reject) => {
            self.post(endpoint, JSON.stringify(record), null, handleError)
                .then(async function(response) {
                    if (response && attachFiles && attachFiles.length>0) {
                        await self.saveAttachments(tableName, response.id, attachFiles, (r) => {
                            response.filesError = r;
                        });
                    }
                    resolve(response)
                })
                .catch(function(err) {
                    console.log('SAVE', err.message, err.stack, endpoint);
                    reject(err)
                });
        })
    }

    async saveAttachments (tableName, id, attachFiles, handleError) {
        console.log('saveAttachments', attachFiles)
        var files = new FormData();
        let folders = {};
        for (let i in attachFiles) {
            let f = attachFiles[i];
            if (f.file) {
                files.append('files[' + i + ']', f.file);
                if (f.folder) folders[i] = f.folder;
            } else {
                files.append('files[' + i + ']', f);
            }
            //files.append('files[' + i + ']', attachFiles[i]);
        }
        files.append('folders', JSON.stringify(folders));
        let res = await this.post('/api/' + tableName + '/files/' + id, files, {}, handleError);
        console.log('response', res)
        return res
    }


    async savePrintScreen (img, fileName, handleError) {
        let data = {img, fileName}
        let res = await this.post('/api/save_file/', JSON.stringify(data), null, handleError);
        return res
    }


    async getModuleFieldsOptions (view, refresh, params) {
        let options;
        if (!this.tableOptions[view] || refresh) {
            let module = this.importMixinModule(view, 'views');
            if (!module) {
                module = this.importMixinModule('view', 'views');
                module.table = view;
            }
             module.table = view;
            //if (!params) params = {};
            options = await module.getTableOptions(params);
            this.setTableOptions(view, options);
        } else {
            options = await this.tableOptions[view];
        }
        return options;
    }

    importMixinModule (name, path) {
        let res;
        try {
            res = require(`@/base/${path}/${name}`).default;
        } catch (err) {
            res = require(`@/base/views/view`).default;
            //console.log('error', name, path, `@/base/${path}/${name}`)
            //console.log(err)
        }
        //let res = require(`@/base/${path}/${name}`).default;
        return res;

    }


    async getImage (url) {
        return url;
    }

    async getTableId (table, id) {
        let records = await this.getTable(table);
        if (records && records.length > 0) {
            return _.find(records, (r) => r.id == id);
        }
    }

}
window.api = new Api();
//api.getExtraFieldsPaths();
