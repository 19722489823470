<template>
    <transition name="fade" mode="out-in">
        <div v-if="abm && ready">
            <app-notification
                :notifications="abm.notifications"
            ></app-notification>

            <div class="row px-3">
                <div class="d-flex col-md-9 align-items-center">
                    <button type="button" class="btn btn-primary action-button mt-0 desktop" @click="addNew" v-if="canAdd">
                        <font-awesome-icon icon="plus"/>
                    </button>
                    <button type="button" class="btn btn-primary action-button mt-0 ml-2 desktop" @click="addToDashboard"
                        :class="{'btn-success-i': inDashboard}" v-if="!options.hideDashboard">
                        <font-awesome-icon icon="home"/>
                    </button>
                    <div class="cursor-pointer ml-4 filters-div desktop" @click="filters = !filters"
                        v-if="!options.hideFilters"
                        :class="{'row-primary-button': filters}">
                        <svg-filters class="service-actions"></svg-filters>
                        <span v-if="filtersCnt" class="badge badge-filters">{{filtersCnt}}</span>
                    </div>
                    <div class="ml-3 mr-2 desktop" v-if="filters">
                        <input type="checkbox" class="" id="showClosed" v-model="abm.showClosed" @change="fetchList">
                        <label class="smaller ml-1 form-check-label">{{tr('Archiveds')}}</label>
                    </div>
                    <div class="ml-3 mr-2 form-material" v-for="filter of customFilters" v-if="filters">
                        <input-field
                            :key="filter.name"
                            :field="filter"
                            :defValue.sync="filtersValues[filter.name]"
                            :fieldOptions="filter.options? field.options: {}"
                            @change="updateFiltersValues(filter)"
                        ></input-field>
                    </div>
                </div>
                <div class="d-flex col-md-3 align-items-center">
                    <search-box class="form-row text-right" addClass="w-100" v-model="abm.search"></search-box>
                </div>
            </div>
            <div v-if="!processing || (abm && abm.fieldOptions)" class="row px-4">
                <div class="container-fluid card">
                    <v-client-table :data="list" :columns="columnsNames" :options="tableOptions" @row-click="onRowClick"
                        @sorted="sorted"
                        ref="serverTable" v-if="!options.serverSide" class="pt-2">
                        <template v-for="field of textAreaColumnsNames" slot-scope="props" :slot="field.name">
                            <text-rows-style
                                :key="field.name"
                                @click="onRowClick(props)"
                                :text="props.row[field.name]"
                            ></text-rows-style>
                        </template>
                        <template v-for="field of buttons" :slot="field.name" slot-scope="props">
                            <button
                                :key="field.name"
                                type="button"
                                class="btn btn-primary btn-sm template-button"
                                @click="callButton(field.action, props.row)"
                                data-toggle="tooltip"
                                data-placement="top"
                                :title="field.title">
                                    <font-awesome-icon :icon="field.icon" v-if="field.icon"/>
                                <span v-if="field.label">{{tr(field.label)}}</span>
                            </button>
                        </template>
                        <template v-for="field of slotFields" :slot="field.name" slot-scope="props">
                            <div :style="'color: ' + props.row[field.name]" v-if="field.abmSlot=='color'">
                                <font-awesome-icon icon="circle" size="lg" v-if="props.row[field.name]"/>
                            </div>
                            <div :class="field.colorIf? field.colorIf(props.row): ''" v-if="field.abmSlot=='spanColor'">
                                {{props.row[field.name]}}
                            </div>
                        </template>
                    </v-client-table>
                    <v-server-table
                        class="pt-2"
                        :columns="columnsNames"
                        :options="tableOptions"
                        ref="serverTable"
                        @row-click="onRowClick"
                        v-else>
                    </v-server-table>
                </div>
            </div>
        </div>
    </transition>
</template>


<script>
const appNotification = importVueComp('components/tools', 'Notification');
import svgFilters from '@/components/svg/Filters';
import { mapState } from 'vuex';
import Vue from 'vue/dist/vue.esm'
import {ServerTable, ClientTable, Event} from 'vue-tables-2';
var EventBus = require('@/tools/event-bus').default;
let theme;
Vue.use(ClientTable, [theme = 'bootstrap5']);
Vue.use(ServerTable, [theme = 'bootstrap5']);
export default {
    name: 'abm',
    props: ['endpoint', 'options', 'tableName', 'refresh'],
    components: {
        'app-notification': appNotification,
        'svg-filters': svgFilters,
        ClientTable,
        ServerTable
    },
    data () {
        let self = this;
        return {
            abm: null,
            filters: null,
            filtersValues: {},
            customFilters: [],
            tablePath: null,
            columns: 2,
            canAdd: false,
            ready: false,
            searchInterval: null,
            columnsNames: [],
            inDashboard: false,
            tableOptions: {
                orderBy: { ascending: self.options.sortDesc? false: true, column: self.options.sortColumn },
                filterable: this.options.serverSide,
                perPageValues: [5, 10, 15, 20, 25, 50],
                perPage: this.options.perPage ? this.options.perPage : 15,
                headings: {},
                initFilters: {GENERIC: ''},
                destroyEventBus: true,
                requestFunction: async function (data) {
                    return await self.abm.requestFunction(data);
                },
                texts: vueTools.vueTableText(),
                columnsClasses: {},
                templates: {},
                skin: 'table table-striped table-hover',
            }
        }
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            stateSearch: state => state.main.search,
            abmFilters: state => state.main.abmFilters,
            tablePage: state => state.main.tablePage,
            tableOrderBy: state => state.main.tableOrderBy,
            processing: state => state.main.processing,
            stateFilters: state => state.main.filters,
        }),
        hasClosed  () {
            return this.abm.hasClosed;
        },
        abmFields () {
            return this.abm.abmFields;
        },
        list () {
            return this.abm.abmList();
        },
        textAreaColumnsNames (){
            return _.filter(this.abm.fields, (r) => r.editor == 'text-area-style');
        },
        buttons (){
            return _.filter(this.abm.fields, (r) => r.editor == 'button');
        },
        slotFields (){
            return _.filter(this.abm.fields, (r) => {
                return r.abmSlot == 'spanColor' || r.abmSlot == 'color';
            });
        },
        search () {
            return this.abm? this.abm.search: null;
        },
        requestFunction () {
            return this.abm? this.abm.requestFunction: null;
        },
        filtersCnt () {
            let res = 0;
            if (this.abm.showClosed) res += 1;
            for (let filter of this.customFilters) {
                if (this.filtersValues[filter.name]) res += 1;
            }
            return res;
        }
    },
    async mounted () {
        api.setProcessing(true);
        await this.getAbm();
        if (this.abmFilters) this.filtersValues = this.abmFilters;
        let ambFilters = localStorage.getItem('abm-filters');
        if (ambFilters) ambFilters = JSON.parse(ambFilters);
        let route = this.$router.currentRoute.name;
        if (ambFilters && ambFilters[route]) {
            for (let filterName in ambFilters[route]) {
                if (filterName == 'ShowDue') continue;
                let filter = _.find(this.customFilters, (r) => r.name == 'filterName');
                if (filter && filter.editor == 'date') continue;
                let value = ambFilters[route][filterName];
                this.filtersValues[filterName] = value;
            }
        }
        if (this.stateFilters) this.filters = this.stateFilters;
        if (!this.options.serverSide) await this.fetchList();
        this.watchSearch();
        if (this.options.cols) this.columns = this.options.cols;
        this.canAdd = tools.canAccess(this.user, 'api','POST', this.endpoint) && this.options.canAdd;
        this.tableOptions.headings = _.mapValues(this.abm.headings, (v) => tr(v));
        this.tableOptions.templates = this.abm.templates;
        this.getColumns();
        if (this.stateSearch[this.endpoint]) {
            this.abm.search = this.stateSearch[this.endpoint];
            if (this.options.serverSide) {
                this.tableOptions.initFilters.GENERIC = this.stateSearch[this.endpoint];
            }
        }
        this.tablePath = this.$router.currentRoute.params.table;
        if (!this.tablePath) this.tablePath = this.tableName;
        this.tableOptions.columnsClasses = this.getColumnsClasses();
        this.ready = true;
        await this.$nextTick();
        if (this.options.serverSide) {
            this.$refs.serverTable.setFilter(this.search? this.search: '');
        }
        if (this.tablePage && this.$refs.serverTable) {
            this.$refs.serverTable.setPage(this.tablePage);
            if (this.tableOrderBy.column) {
                this.$refs.serverTable.setOrder(this.tableOrderBy.column, this.tableOrderBy.ascending)
            }
        } else {
            let abmDefaultOrder = localStorage.getItem('abm-order');
            if (abmDefaultOrder) {
                abmDefaultOrder = JSON.parse(abmDefaultOrder);
                let key = [this.endpoint, this.tableName];
                if (abmDefaultOrder && abmDefaultOrder[key] && this.$refs.serverTable) {
                    this.$refs.serverTable.setOrder(abmDefaultOrder[key].column, abmDefaultOrder[key].ascending);
                }
            }
        }
        let r = this.getInDashboard();
        this.inDashboard = r.index > -1;
        api.setProcessing(false);
    },
    watch: {
        search () {
            this.$store.commit('setSearch', {value: this.search, key: this.endpoint});
            if (this.options.serverSide && this.$refs.serverTable) {
                this.$refs.serverTable.setFilter(this.search);
            }
        },
        filters () {
            this.$store.commit('setFilters', this.filters);
        },
    },
    methods: {
        async getAbm () {
            let endpoint = this.applyFilters();
            this.abm = await new abm({table: this.tableName, endpoint, options: this.options});
        },
        async showRecord (c) {
            if (this.options.showRecord) {
                this.options.showRecord(c.id);
                return;
            }
            this.$router.push({ name: 'record', params: { table: this.tablePath, id: c.id }})
        },
        addCopy (record) {
            this.abm.addCopy(record);
        },
        addNew  () {
            if (this.options.addNew) {
                this.options.addNew();
                return;
            }
            this.$router.push({ name: 'record', params: { table: this.tablePath, id: 'new'}})
        },
        async fetchList (refresh) {
            api.setProcessing(true)
            if (!this.options.serverSide) {
              await this.abm.fetchList(this.tableName, undefined, this.options.filters, this.refresh || refresh)
            } else {
              await this.$refs.serverTable.refresh()
            }
            api.setProcessing(false)
        },
        onRowClick (data) {
            //api.setProcessing(true);
            this.$store.commit('setTablePage', this.$refs.serverTable.page);
            this.$store.commit('setTableOrderBy', this.$refs.serverTable.orderBy);
            this.showRecord(data.row);
        },
        callButton (callback, data) {
            callback(data, this)
        },
        watchSearch () {
            this.searchInterval = setInterval(() => {
                try {
                    let s = $('.VueTables__search-field').find('input').val()
                    if (s) {
                        this.$store.commit('setSearch', {value: s, key: this.endpoint});
                    }
                } catch {
                }
            }, 200);
        },
        updateFiltersValues (filter) {
            this.filtersValues = Object.assign({}, this.filtersValues);
            this.$store.commit('setAbmFilters', this.filtersValues);
            if (filter.callback) {
                filter.callback();
            }
            if (filter.editor != 'date' && filter.name != 'ShowDue') {
                let route = this.$router.currentRoute.name;
                let ambFilters = localStorage.getItem('abm-filters');
                if (ambFilters) ambFilters = JSON.parse(ambFilters);
                if (!ambFilters) ambFilters = {};
                if (!ambFilters[route]) ambFilters[route] = {};
                ambFilters[route][filter.name] = this.filtersValues[filter.name];
                localStorage.setItem('abm-filters', JSON.stringify(ambFilters));
            }
        },
        getInDashboard () {
            let dashboard = localStorage.getItem('dashboard');
            if (dashboard) dashboard = JSON.parse(dashboard);
            if (!dashboard) dashboard = [];
            let index = _.findIndex(dashboard, (r) => {
                return r.props.endpoint == this.endpoint && r.props.tableName == this.tableName && r.component == 'abm';
            });
            return {index, dashboard};
        },
        addToDashboard () {
            let r = this.getInDashboard();
            let dashboard = r.dashboard;
            if (r.index==-1) {
                let title = this.options.title;
                if (!title) title = this.tableName;
                dashboard.push({component: 'abm', folder: 'components/tools', columns: 12, Title: title,
                  props: {endpoint: this.endpoint, tableName: this.tableName}
                })
                this.inDashboard = true;
            } else {
                dashboard.splice(r.index, 1);
                this.inDashboard = false;
            }
            localStorage.setItem('dashboard', JSON.stringify(dashboard));
        },
        getColumnsClasses () {
            let res = {};
            for (let field of this.abm.fields) {
                if (field.editor == 'number') {
                    res[field.name] = 'text-right';
                }
            }
            return res;
        },
        applyFilters () {
            return this.endpoint;
        },
        sorted (values) {
            if (this.options.serverSide) return;
            let key = [this.endpoint, this.tableName];
            let abmDefaultOrder = localStorage.getItem('abm-order');
            if (!abmDefaultOrder) {
                abmDefaultOrder = {};
            } else {
                abmDefaultOrder = JSON.parse(abmDefaultOrder);
            }
            abmDefaultOrder[key] = values;
            localStorage.setItem('abm-order', JSON.stringify(abmDefaultOrder));
        },
        getColumns () {
            let columns = this.abm.columnsNames;
            if (this.options.fieldsAbmList) {
                columns = this.options.fieldsAbmList;
            }
            if (window.innerWidth < 500) {
                columns = columns.slice(0, 3);
            }
            this.columnsNames = columns;
        }
    },
    beforeDestroy() {
        clearInterval(this.searchInterval);
        //EventBus.$off('search-value', this.setSearchValue);
    },

}
</script>

<style scoped>
.btn-success-i {
    background-color: rgb(40, 167, 69) !important;
}
</style>