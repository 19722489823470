<template>
    <transition name="fade" mode="out-in">
        <div>
            <div class="blockUI blockOverlay" style="z-index: 1100; border: none; margin: 0px; padding: 0px; width: 100%; height: 100%; top: 0px; left: 0px; background-color: rgb(0, 0, 0); opacity: 0.6; cursor: wait; position: fixed;">
            </div>
            <div class="blockUI blockMsg blockPage" style="z-index: 1111; position: fixed; padding: 0px; margin: 0px; width: 30%; top: 40%; left: 35%; text-align: center; color: rgb(0, 0, 0); border: 0px; cursor: wait;">
                <div class="openerp oe_blockui_spin_container" style="background-color: transparent;">
                    <div>
                        <div style="color:white">
                            {{tr('Loading')}}...
                        </div>
                        <div class="linear-activity">
                            <div class="indeterminate"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: 'block-ui',
    computed: {
        ...mapState({
            processing: state => state.main.processing,
        }),
    },
    data () {
        return {
            timeout: null,
            requestTimeout: 60000,
        }
    },
    mounted () {
        let requestTimeout = this.requestTimeout;
        if (api.requestTimeout) requestTimeout = api.requestTimeout;
        this.timeout = setTimeout (() => {
            if (this.processing) {
                api.setProcessing(false);
                api.showAlert = {text: 'The operation could not be completed', level: 1};
            }
        }, requestTimeout)
    },
    methods: {
        tr (t) {
            if (window.tr) return tr(t);
        }
    },
    beforeDestroy() {
        clearTimeout(this.timeout);
    },
}
</script>

<style scoped>
    .processing-spin {
      animation-name: spin;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: steps(12);
    }
</style>
