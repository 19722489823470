<template>
        <report-window
            v-if="ready"
            endpoint="/reports/user_access"
            :perPage="500"
            :fields="fields"
            :title="title"
            :headers="headers"
            :current.sync="current"
            :headerColumns="headerColumns"
            :templates="templates"
        ></report-window>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import { mapState } from "vuex";
let langDict = require('@/languages/system_languages.json');
let dict = langDict['es'];
export default {
    name: "user-access-report",
    props: ["options", "tableName", "id"],
    components: {
        ReportWindow,
    },
    async mounted () {
        frontTools.setReportValues(this);
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data() {
        let self = this;
        return {
            current: {},
            ready: false,
            fields: [
                {
                    name: "Type",
                    label: "Type",
                    required: true,
                    columns: 2,
                    editor: 'select',
                    options: [{value: 'api', label: 'api'}, {value: 'view', label: 'view'}]
                },
                {
                    name: "Method",
                    label: "Method",
                    required: true,
                    columns: 2,
                    editor: 'select',
                    options: [{value: 'GET', label: 'GET'}, {value: 'POST', label: 'POST'}, {value: 'DELETE', label: 'DELETE'}]
                },
                {
                    name: "Endpoint",
                    label: "Endpoint",
                    required: true,
                    columns: 2,
                },
                {
                    name: "DefValue",
                    label: "DefValue",
                    columns: 2,
                    editor: 'select',
                    options: [{value: true, label: 'True'}, {value: false, label: 'False'}]
                },
            ],
            title: "User Access",
            headerColumns: ['UserName', 'Access'],
            headers: {
            },
            templates: [
                {
                    name: "Access",
                    callback: (row) => {
                        if  (tools.canAccess(row.User, this.current.Type, this.current.Method, this.current.Endpoint, this.current.DefValue)) return tr('YES');
                        return tr('NO');

                    }
                },
            ],
        };
    },
    methods: {
    }
};
</script>
