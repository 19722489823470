
import BaseModel from '@/base/model/base-model';

class Record extends BaseModel {

    constructor({fields, tableName, options, endpoint, record}) {
        let f = options? options.fields: null;
        if (fields) f = fields;
        super({fields: f, record, tableName});
        let self = this;
        return (async () => {
            let initPromise = new Promise((resolve, reject) => {
                resolve(self);
            })
            let user = await api.getCurrentUser();
            self = await initPromise;
            self.tableName = tableName;
            self.showClosed = false;
            self.endpoint = endpoint? endpoint: '/api/' + tableName + '/';
            self.search = null;
            self.initNotifications();
            self.attached = [];
            self.attachList = [];
            self.canAdd = tools.canAccess(user, 'api','POST', self.endpoint);
            self.userCanEdit = tools.canAccess(user, 'api','POST', self.endpoint);
            self.userCanDelete = tools.canAccess(user, 'api','DELETE', self.endpoint);
            if (options) {
                if (options.afterSave) self.afterSave = options.afterSave;
                if (options.beforeSave) self.beforeSave = options.beforeSave;
                if (options.serverSide) self.serverSide = options.serverSide;
                if (options.evalDelete) self.evalDelete = options.evalDelete;
                if (options.evalEdit) self.evalEdit = options.evalEdit;
                if (options.setTitleName) self.setTitleName = options.setTitleName;
            }
            //self.me = user;
            self.processingAttach = false;
            if (self.id) self._modified = false;
            if (!self.id) self._new = true;
            self._recordLoaded = true;
            self.dependencies = false;
            return self;
        })();
    }

    static async clone ({fields, record, parent}) {
        let r = await super.clone({fields, record, parent});
        r.endpoint = record.endpoint;
        r.tableName = record.tableName;
        r._modified = true;
        r._new = true;
        return r;
    }


    getHasClosed () {
        for (let f of this.fields) {
            if (f === 'Closed' || f.name === 'Closed') return true;
        }
        return false;
    }

    async save () {
        if (this.beforeSave) {
            let res = this.beforeSave(this);
            if (res) {
                await this.init(res);
                return;
            }
        }
        let res = await this.saveRecord();
        if (this.afterSave) {
            this.afterSave(this);
        }
        return res;
    }

    async remove () {
        this.initNotifications();
        let self = this;
        self.dependencies = false;
        let res = await api.remove(this.endpoint, this.id , (r, response) => {
            if (response && response.status == 420) self.dependencies = true;
            this.addError(r.err, r.detail)
        });
        if (res) {
            api.removeFromStore(this.tableName, this.id);
            return res;
        }
    }

    async init (record) {
        await super.init(record);
        this.setModified(false);
    }

    clean (rec) {
        tools.clean(rec);
    }

    async saveRecord () {
        this.notifications.errors = [];
        this.notifications.warning = null;
        let errors = this.checkFields();
        if (errors) {
            api.setProcessing(false)
            this.addError(errors.join('. '));
            return;
        }
        this.initNotifications();
        let rec = _.cloneDeep(this);
        this.clean(rec);
        let r = tools.plainObject(rec);
        let result = await api.save(this.endpoint, r, this.attachList, this.tableName, (response) => {
            this.addError(response.err, response.detail, response.field);
        });
        if (result) {
            if (result.filesError) {
                this.addError('Attachments errors', result.filesError.detail, result.filesError.field);
            }
            this._recordLoaded = false;
            this.attachList = [];
            await this.init(result)
            await this.getAttached();
            this.original_id = this.id;
            api.updateToStore(this.tableName, result);
            this.addNotification(tr("Record Saved"));
            if (result._warnings) {
                this.notifications.warning = result._warnings.join('; ');
            } else {
                this.notifications.warning = null;
            }
            this._recordLoaded = true;
            return result;
        }
    }

    addNotification (msg) {
        this.notifications.message = msg;
        this.notifications.timer = 0;
        const k = 500;
        if (this.notifications.interval) {
            clearInterval(this.notifications.interval);
        }
        this.notifications.interval = setInterval(() => {
            this.notifications.timer += k;
            if (this.notifications.timer > 5000) {
                clearInterval(this.notifications.interval)
                this.notifications.message = null;
                this.notifications.timer = 0;
            }
        }, k);

        /*setTimeout(() => {
            this.notifications.message = null;
        }, 5000);*/
    }

    initNotifications () {
        this.notifications = {
            errors: [],
            errorDetail: null,
            message: null,
            warning: null,
            interval: null,
            timer: 0,
        }
    }

    addError (msg, detail, fieldName) {
        let e = msg;
        if (fieldName) {
            let label = this.getFieldLabel(fieldName);
            e = `${tr(msg)} ${tr(label)}`;
        }
        if (this.notifications.errors.indexOf(e)==-1) {
            this.notifications.errors.push(e);
            if (detail) this.notifications.errorDetail = detail;
            this.notifications.message = null;
        }
    }

    get titleName () {
        if (this.setTitleName) return this.setTitleName(this);
        if (this.Name && this.id) return this.id + " - " + this.Name;
        if (this.Name) return this.Name;
        return '';
    }

    get canDelete () {
        if (!this.userCanDelete) return false;
        if (this.evalDelete) {
            return this.evalDelete(this);
        }
        return true
    }

    get canEdit () {
        if (!this.userCanEdit) return false;
        if (this.evalEdit) {
            return this.evalEdit(this);
        }
        return true
    }

    get attachPending () {
        return this.attachList;
    }

    async getAttached () {
        if (this && this.id) {
            this.processingAttach = true;
            let res = await api.get('/api/get_attach_list/' + this.tableName + '/' + this.id);
            if (res) {
                this.attached = res;
                this.processingAttach = false;
            }
        } else {
            this.attached = [];
        }
    }

    static async get ({id, tableName, options, endpoint}) {
        let record = await api.getObjectFromStore(tableName, id);
        if (record) {
            let r = await new this({tableName, options, endpoint, record});
            if (r.id) {
                r._recordLoaded = true;
                await r.getAttached();
            }
            return r;
        }
    }



}

export default Record;
