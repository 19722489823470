<template>
  <div class="modal fade" id="changeLog" tabindex="-1" role="dialog" aria-labelledby="changeLogLabel"
      data-backdrop="static" aria-hidden="true" v-if="show">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h4 class="modal-title">{{tr('Version News')}}</h4>
          <button type="button" class="close" @click="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div v-for="date of dates" v-if="showDate(date)">
                <div class="border-bottom mb-3">
                    <h5><strong>{{getDate(date)}}</strong></h5>
                    <ul>
                        <li v-for="(value, i) of values[date]" class="mt-2">
                            <strong>{{tr(value.title)}}.</strong>
                            <span class="ml-1 cursor-pointer text-primary" @click="showDetail(date, i, value.component)" v-if="value.component">
                                <span v-if="details[key(date, i)]">{{tr('Hide')}}</span>
                                <span v-else>{{tr('Detail')}}</span>
                            </span>
                            <div v-if="details[key(date, i)]" class="pr-5">
                                <component :is="details[key(date, i)]"></component>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <span class="ml-2 cursor-pointer text-primary text-small" @click="total += 3" v-if="dates.length > total">
                <i>{{tr('Load more')}}</i>
            </span>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="close">{{tr('Close')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

let changes = require('@/components/changelog/items.json');
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'change-log',
    mixins: [escape],
    data () {
        return {
            details: {},
            lastView: null,
            total: 2,
            show: false,
        }
    },
    mounted () {
        this.checkLastView();
        //$('#changeLog').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    computed: {
        values () {
            return this.getValues();
        },
        dates () {
            return Object.keys(this.values).sort((a, b) => {
                if (a > b) return -1;
                if (b > a) return 1;
            });
        },
        showList () {
            let res = [];
            for (let date of this.dates) {
                let add = true;
                if (this.lastView && this.lastView >= date) add = false;
                if (add) {
                    res.push(date)
                }
            }
            let toAdd = Math.max(this.total - res.length, 0);
            for (let i=0; i<toAdd; i++) {
                if (this.dates[i]) {
                    if (res.indexOf(this.dates[i]) == -1) res.push(this.dates[i]);
                }
            }
            return res;
        }
    },
    methods: {
        getValues () {
            let res = {};
            for (let row of changes) {
                if (!res[row.date]) res[row.date] = [];
                res[row.date].push(row);
            }
            return res;
        },
        getDateList () {
            return Object.keys(this.getValues()).sort((a, b) => {
                if (a > b) return -1;
                if (b > a) return 1;
            });
        },
        async checkLastView () {
            if (!api.currentUser) return
            if (!api.currentUser.id) return
            let r = await api.get(`/api/changelog/get_last/${api.currentUser.id}`);
            if (!r) {
                this.open();
                return;
            }
            this.lastView = r.LastView;
            let dates = this.getDateList();
            if (dates[0] && dates[0] > r.LastView) {
                this.open();
            }
        },
        async open () {
            if (!this.dates[0]) return;
            this.show = true;
            await this.$nextTick();
            $('#changeLog').modal({backdrop: 'static', keyboard: false}, 'show');
        },
        async close () {
            if (api.currentUser && api.currentUser.id && this.dates[0]) {
                api.post(`/api/changelog/read/${api.currentUser.id}/${this.dates[0]}`);
            }
            this.total = 2;
            $('#changeLog').modal('hide');
            await this.$nextTick();
            this.show = false;
            //this.$emit('update:show', false);
        },
        getDate (d) {
            return moment(d).locale(api.language).format('DD MMM YYYY');
        },
        key (date, i) {
            return `${date}-${i}`;
        },
        async showDetail(date, i, component) {
            let key = this.key(date, i);
            if (!this.details[key]) {
                this.details[key] = async () => await importVueComp('components/changelog', component);
            } else {
                this.details[key] = false;
            }
            this.details = Object.assign({}, this.details);
        },
        showDate (date) {
            if (this.showList.indexOf(date)>-1) return true;
        }
    },

}
</script>

<style scoped>
.modal-content, .modal-footer {
    //background: #F1F1F1 !important;
}

</style>