<template>
    <datetime :type="type" v-model="v" @close="setDate" :disabled="disabled" zone="local" value-zone="local" v-if="ready"
        input-class="form-control" :class="{'disabled': disabled}">
        <span class="form-bar"></span>
    </datetime>
</template>

<script>
import Vue from 'vue';
import {Datetime} from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
Vue.use(Datetime)

export default {
    name: 'm-date-time',
    props: ['value', 'type', 'format', 'disabled', 'fieldName'],
    components: {
        'datetime': Datetime
    },
    computed: {
        utc () {
            if (this.fieldName && this.fieldName.includes('UTC')) return true;
        }
    },
    data () {
        return {
            v: null,
            ready: false,
        }
    },
    mounted () {
        this.v = this.value;
        if (this.utc) {
            this.v = moment.utc(this.v).local().locale(api.language).format(this.format);
        }
        this.ready = true;
    },
    methods: {
        setDate () {
            if (this.v) {
                if (this.utc) {
                    this.v = moment.utc(this.v).local().locale(api.language).format(this.format);
                } else {
                    this.v = moment(this.v).format(this.format);
                }
            } else {
                this.v = null;
            }
            this.$emit('update:value', this.v);
            this.$emit('change');
        }
    },
}
</script>
