<template>
  <div @contextmenu="handleClick($event)">
    <transition name="fade" mode="out-in">
    <div class="container-fluid">
      <div v-if="ready && record && record.fieldsObject">
        <nav class="row d-flex px-3 record-header">
            <div class="col-lg-6 col-xs-10">
                <div class="mb-0 pb-0 display-4">
                  <h5>{{tr(titleName())}}
                      <i v-if="!record.id">({{tr('New')}})</i>
                      <i v-else-if="modified" class="record-status"> - {{tr('Modified')}}</i>
                  </h5>
                </div>
            </div>
        </nav>
        <hr v-if="modal">

        <app-notification
            v-if="!modal"
            class="mt-1"
            :notifications.sync="_notifications || recordStore.notifications"
            :dependencies="recordStore.dependencies"
            :id="record.id"
            :tableName="recordStore.tableName"
        ></app-notification>

        <div class="card">
          <div class="card-body">
            <div v-if="tabHeaderFields" class="mt-4">
              <div v-for="editorRow of editorRows" class="form-material form-row">
                  <div v-for="field of editorRow"
                    :class="getColumns(field)"
                    v-if="field.headerField && !field.hidden">
                      <input-field
                        v-if="!hideIf[field.name]"
                        :field="field"
                        :record="record"
                        :defValue.sync="record[field.name]"
                        :imageObject.sync="record[field.setRelationTo]"
                        :invalid="record.invalid[field.name]"
                        :relationObject="record[field.setRelationTo]"
                        :attachList.sync="recordStore.attachList"
                        :attached.sync="record.attached"
                        :canDeleteAttach="canDeleteAttach"
                        @change="change"
                      ></input-field>
                  </div>
              </div>
            </div>

            <ul class="nav flex-column md-tabs" v-if="showTabs">
                <li class="nav-item" :class="{'tab-alert': record.invalid._tabData}" v-if="firstTabFields">
                  <span class="nav-link active show" :id="'fields-tab-' + _uid"
                  data-toggle="tab" :href="'#fields' + _uid" role="tab" :aria-controls="'fields' + _uid" aria-selected="true">
                    <font-awesome-icon icon="calendar-check" class="mr-1"/>
                    {{tr('Data')}}
                  </span>
                </li>
                <li class="nav-item" v-for="(arrayField, i) of arrayFields">
                  <span class="nav-link"
                    :class="{'active': i==0 && !firstTabFields, 'tab-alert': recordStore.invalid[arrayField.name], 'disabled': disabledTab(arrayField)}"
                    :id="'detail-tab-' + arrayField.name"
                    data-toggle="tab"
                    :href="'#details-' + arrayField.name"
                    role="tab"
                    @click="alert(arrayField)"
                    :aria-controls="'details-' + arrayField.name"
                    aria-selected="false">
                    <font-awesome-icon :icon="arrayField.icon" v-if="arrayField.icon" class="mr-1"/> {{tr(arrayField.label)}}
                  </span>
                </li>
                <li class="nav-item" v-for="comp of componentFields">
                  <span class="nav-link" :id="'detail-tab-' + comp.name" data-toggle="tab" :href="'#component-' + comp.name"
                      role="tab" :aria-controls="'component-' + comp.name" aria-selected="false"
                      :class="{'disabled': !componentIf(comp.name), 'active': comp.firstTab}">
                    <font-awesome-icon :icon="comp.icon" v-if="comp.icon" class="mr-1"/>
                      {{tr(comp.label)}}
                  </span>
                </li>
                <li class="nav-item" v-for="tab of tabList">
                  <span class="nav-link" :id="'fieldsTab-' + tab.name + _uid" data-toggle="tab" :href="'#fields-' + tab.name + _uid"
                      :class="{'tab-alert': record.invalid[tab.name]}"
                      role="tab" :aria-controls="'fields-' + tab.name" aria-selected="false">
                    <font-awesome-icon :icon="tab.icon" v-if="tab.icon" class="mr-1"/>
                      {{tr(tab.label)}}
                      </span>
                </li>
            </ul>

          <div class="tab-content mt-3" id="tabsContent" v-if="showTabs">
            <div class="tab-pane fade show active"
                :id="'fields' + _uid" role="tabpanel" :aria-labelledby="'fields-tab-' + _uid" v-if="firstTabFields">

                  <div v-for="editorRow of editorRows" class="form-material form-row">
                      <div v-for="field of editorRow"
                        :class="'px-3 mb-0 ' + getColumns(field)"
                        v-if="!field.hidden && !field.headerField && !field.footerRow">
                          <input-field
                            v-if="!hideIf[field.name]"
                            :field="field"
                            :record="record"
                            :invalid="record.invalid[field.name]"
                            :defValue.sync="record[field.name]"
                            :relationObject="record[field.setRelationTo]"
                            :imageObject.sync="record[field.setRelationTo]"
                            :attachList.sync="recordStore.attachList"
                            :attached.sync="record.attached"
                            :canDeleteAttach="canDeleteAttach"
                            @change="change"
                          ></input-field>
                      </div>
                  </div>
                  <div class="form-material form-row">
                      <div v-for="field of recordStore.abmFields"
                        :class="'px-3 mb-0 ' + getColumns(field)"
                        v-if="!field.hidden && !field.headerField && field.footerRow">
                          <input-field
                            v-if="!hideIf[field.name]"
                            :field="field"
                            :record="record"
                            :invalid="record.invalid[field.name]"
                            :defValue.sync="record[field.name]"
                            :relationObject="record[field.setRelationTo]"
                            :imageObject.sync="record[field.setRelationTo]"
                            :maxLength="field.length"
                            :attachList.sync="recordStore.attachList"
                            :attached.sync="record.attached"
                            :canDeleteAttach="canDeleteAttach"
                            @change="change"
                          ></input-field>
                      </div>
                  </div>

            </div>
            <record-window-row
                v-if="arrayField.rowFields"
                :key="arrayField.name"
                v-for="(arrayField, i) of arrayFields"
                :arrayField="arrayField"
                :i="i"
                :record.sync="record"
                :firstTabFields="firstTabFields"
                :recordRows.sync="record[arrayField.fieldName? arrayField.fieldName: arrayField.name]"
                :modal="modal"
                @change="change"
            ></record-window-row>
            <div v-for="comp of componentFields" class="tab-pane fade" :id="'component-' + comp.name" role="tabpanel"
              :aria-labelledby="'comp-tab-' + comp.name" v-if="componentIf(comp.name)">
                  <component
                    v-if="showComps"
                    :is="comp.component"
                    :record.sync="record"
                    :callmethod="callmethod"
                    :field="comp"
                    :tableSpec="record.tableSpec"
                    :modal="modal"
                    :ref="comp.ref"
                    @change="change"
                  ></component>
            </div>

            <div v-for="tab of tabList" class="tab-pane fade" :id="'fields-' + tab.name + _uid" role="tabpanel"
              :aria-labelledby="'fieldsTab-' + tab.name + _uid" >
                <div class="form-material form-row">
                  <div v-for="field of tab.fields"
                    :class="'form-group ' + getColumns(field)"
                    v-if="!field.hidden">
                      <input-field
                        v-if="!hideIf[field.name]"
                        :field="field"
                        :record="record"
                        :invalid="record.invalid[field.name]"
                        :defValue.sync="record[field.name]"
                        :relationObject="record[field.setRelationTo]"
                        :imageObject.sync="record[field.setRelationTo]"
                        :attachList.sync="recordStore.attachList"
                        :attached.sync="record.attached"
                        :canDeleteAttach="canDeleteAttach"
                        @change="change"
                      ></input-field>
                  </div>
                </div>
            </div>
          </div>
        </div>
        </div>
        <app-notification
            v-if="modal"
            class="mt-1"
            :notifications.sync="_notifications || recordStore.notifications"
            :dependencies="recordStore.dependencies"
            :id="record.id"
            :tableName="recordStore.tableName"
        ></app-notification>
        <div class="form-row d-flex px-3 py-0 border-top footer--sticky" :class="{'modal-footer': modal}">
          <edited-by :record="record"></edited-by>
          <div class="mr-0 p-2" v-if="(!askSave && !toDelete) || !modal">
                <button v-for="action in options.actions"
                    @click="callAction(action.method, action.id)"
                    class="btn waves-effect waves-light btn-primary ml-1"
                    :disabled="!record.id || modified || processingActions[action.id] || disabledAction(action)">
                    <span v-if="!processingActions[action.id]">{{tr(action.label)}}</span>
                    <img class="processing-gif" src="@/img/loading.gif" v-else>
                </button>

                <button
                    @click="save()" v-if="canEdit" :disabled="processing"
                    class="btn waves-effect waves-light btn-primary ml-1" data-toggle="tooltip"
                    data-html="true" title="" :data-original-title="'<em>' + tr('Save') + '</em>'">
                        <font-awesome-icon icon="save"/> {{tr('Save')}}
                </button>
                <button
                    v-if="options.canCopy && canEdit"
                    @click="copy"
                    :disabled="!record.id"
                    class="btn waves-effect waves-light btn-primary ml-1" data-toggle="tooltip"
                    data-html="true" title="" :data-original-title="'<em>' + tr('Copy') + '</em>'">
                        {{tr('Copy')}}
                </button>
                <button
                    v-if="options.canAdd && canEdit"
                    @click="addNew"
                    :disabled="modified"
                    class="btn waves-effect waves-light btn-primary ml-1" data-toggle="tooltip"
                    data-html="true" title="" :data-original-title="'<em>' + tr('New') + '</em>'">
                        {{tr('New')}}
                </button>

                <button
                    @click="close"
                    class="btn waves-effect waves-light btn-info ml-1" data-toggle="tooltip"
                    data-html="true" title="" :data-original-title="'<em>' + tr('Close') + '</em>'">
                        <font-awesome-icon icon="window-close"/>
                </button>
                <button
                    @click="showDelete" :disabled="processing" v-if="canDelete && record.id"
                    class="btn waves-effect waves-light btn-danger ml-1" data-toggle="tooltip"
                        data-html="true" title="" :data-original-title="'<em>' + tr('Delete') + '</em>'">
                        <font-awesome-icon icon="trash-alt"/>
                </button>

          </div>
          <div class="mr-0 p-2" v-else-if="askSave && modal">
              <label class="ml-3 text-danger">{{tr('Save Changes?')}}</label>
              <button type="button" class="btn btn-primary ml-1" @click="saveClose">{{tr('YES')}}</button>
              <button type="button" class="btn btn-danger ml-1" @click="confirmClose">{{tr('NO')}}</button>
              <button type="button" class="btn btn-secondary ml-1" @click="cancelClose">{{tr('Cancel')}}</button>
          </div>
          <div class="mr-0 p-2" v-else-if="toDelete && modal">
              <label class="ml-3 text-danger">{{tr('Delete Record?')}}</label>
              <button type="button" class="btn btn-secondary ml-1" @click="toDelete = false">{{tr('Cancel')}}</button>
              <button type="button" class="btn btn-danger ml-1" @click="remove">{{tr('Delete')}}</button>
          </div>

        </div>
      </div>
    </div>
    </transition>
    <confirm-delete
        @confirmRemove="remove()"
        :toDelete.sync="toDelete"
        v-if="canDelete && toDelete && !modal"
    ></confirm-delete>
    <confirm-close
        @cancelClose="cancelClose"
        @confirmClose="confirmClose"
        @saveClose="saveClose"
        v-if="askSave && !modal"
     ></confirm-close>
  </div>
</template>


<script>
const editedBy = importVueComp('components/tools', 'EditedBy');
const confirmCloseComp = importVueComp('components/tools', 'ConfirmClose');
const appNotification = importVueComp('components/tools', 'Notification');
const confirmDelete = importVueComp('components/tools', 'ConfirmDelete');
const recordWindowRow = importVueComp('components/tools', 'RecordWindowRow');
var EventBus = require('@/tools/event-bus').default;
import { mapState } from 'vuex';
import { v1 as uuidv1 } from 'uuid';

export default {
    name: 'record-window',
    props: ['table', 'id', 'supId', 'modal', 'newRecord', 'view', 'tab', 'notifications'],
    components: {
        'edited-by': editedBy,
        'confirm-close': confirmCloseComp,
        'app-notification': appNotification,
        'confirm-delete': confirmDelete,
        'record-window-row': recordWindowRow,
    },
    data () {
        return {
            endpoint: '/api/' + this.table + '/',
            record: null,
            toDelete: false,
            ready: false,
            callmethod: false,
            askSave: false,
            nextClose: false,
            processingActions: {},
            removed: false,
            saving: false,
            isNew: false,
            showComps: true,
            options: null,
            pathName: null,
            pathRoute: null,
            module: null,
            hideIf: {},
            recordView: null,
            socket: null,
            recordName: null,
            _id: null,
            _notifications: null,
            allowContextMenu: false,
            showTabs2: true,
        }
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            callConfirmClose: state => state.main.callConfirmClose,
            toPath: state => state.main.toPath,
            //recordStore: state => this.modal? state.main.recordModal: state.main.record,
            processing: state => state.main.processing,
        }),
        recordStore () {
            if (this.modal) return this.$store.state.main.modalRecord;
            return this.$store.state.main.record;
        },
        modified () {
            if (!this.recordStore) return false;
            //if (!this.recordStore.id) return true;
            return this.recordStore._modified;
        },
        columns () {
            return 12 / this.cols;
        },
        editorRows () {
            if (!this.recordStore) return [];
            return [_.filter(this.recordStore.abmFields, (f) => {
                return (f.editor != 'component') && !Array.isArray(f.editor) &&
                    (f.editor != 'tab') && !(typeof f.editor === 'object') && !f.hidden;
            })];
        },
        tabHeaderFields () {
            if (!this.recordStore) return [];
            return _.filter(this.recordStore.fields, (r) =>  {
                return (r.headerField);
            }).length;
        },
        firstTabFields () {
            if (!this.recordStore) return [];
            return _.filter(this.recordStore.fields, (r) => {
                return (!r.headerField && !r.hidden && !Array.isArray(r.editor) && r.editor!='component' &&
                    r.editor!='tab' && typeof r.editor !== 'object');
            }).length;
        },
        showTabs () {
            if (!this.showTabs2) return;
            return this.firstTabFields || this.arrayFields.length || this.tabList.length || this.componentFields.length;
        },
        abmFields () {
            return this.recordStore? this.recordStore.abmFields: [];
        },
        arrayFields () {
            return this.recordStore? this.recordStore.arrayFields: [];
        },
        tabList () {
            return this.recordStore? this.recordStore.tabList: [];
        },
        componentFields () {
            let res = []
            if (!this.recordStore) return res;
            for (let f of this.recordStore.abmFields) {
                if (f.component){
                    if (f.componentName) {
                        let c = importVueComp(f.componentPath, f.componentName);
                        if (c) f.component = c;
                    }
                    res.push(f)
                }
            }
            return res;
        },
        canDelete () {
            return this.recordStore? this.recordStore.canDelete: false;
        },
        canEdit () {
            return this.recordStore? this.recordStore.canEdit: false;
        },
        attachList () {
            return this.recordStore? this.recordStore.attachPending: [];
        },
        changeNr () {
            if (this.recordStore) return this.recordStore._changeNr;
        },
        canDeleteAttach () {
            if (!this.recordStore) return false;
            let tableName = this.recordStore.tableName;
            let action = `delete_attach_${tableName}`;
            return tools.canAccess(this.user, 'api', 'DELETE', action, false);
        },
        breadcrumbList () {
            if (this.pathRoute.split('/').length == 3) {
                return [{title: this.module}, {title: this.pathName, route: this.pathRoute}, {title: this.titleName()}];
            }
            if (this.pathRoute.split('/').length == 5) {
                let p = this.pathRoute.split('/');
                let r1 = `/${p[1]}/${p[2]}`;
                let r2 = `/${p[1]}/${p[2]}/${p[3]}`;
                let res = [{title: this.module}]
                let rName = p[3];
                if (api.tableOptions[p[2]]) {
                    let tableName = api.tableOptions[p[2]].tableName;
                    let t = _.find(api.tables[tableName], (r) => r.id == parseInt(rName));
                    if (t) rName = t.Name;
                }
                res.push({title: tr(p[2]), route: r1});
                res.push({title: rName, route: r2});
                res.push({title: this.titleName()});
                return res;
            }
            return [{title: this.module}, {title: this.pathName, route: this.pathRoute}, {title: this.titleName()}];
        }
    },
    async mounted () {
        api.setProcessing(true)
        this._notifications = this.notifications;
        let pathList = this.$router.currentRoute.path.split('/');
        pathList.pop()
        this.pathName = pathList[pathList.length-1];
        this.pathRoute = pathList.join('/');
        this.module = frontTools.getModuleByPath(this.pathName);
        //let view = this.table;
        //if (this.view) view = this.view;
        //this.options = await api.getModuleFieldsOptions(view);
        await this.onMount();
        EventBus.$on('set-record', this.setRecord);
        this.setRouterHistoryName();
        this.afterMount();
        this.ready = true;
        api.setProcessing(false)
        await this.$nextTick();
        if (this.tab) {
            $('#' + this.tab).tab('show');
        }
        let bookingVersion;
        if (this.$router.currentRoute.path.includes('bookingversion')) bookingVersion = true;
        if (api.currentUser && api.currentUser.id && this.recordStore && this.recordStore.id && !bookingVersion
            && process.env.NODE_ENV=='production') {

            this._id = uuidv1();
            let table = this.table;
            if (this.options && this.options.tableName) {
                table = this.options.tableName;
            }
            let recordName = this.recordStore.endpoint;
            this.recordName = `${recordName}${this.recordStore.id}`;
            this.recordView = setInterval(async () => {
                let res = await api.post('/api/record_view/', JSON.stringify({
                    recordName: this.recordName,
                    table,
                    recordId: this.recordStore.id,
                    syncVersion: this.recordStore.syncVersion,
                    userName: api.currentUser? api.currentUser.UserName: null,
                    id: this._id
                }));
                if (res) this.showAlert(res);
            }, 10000);

        }
        /*if (process.env.SOCKET && api.currentUser && api.currentUser.id && this.recordStore.id) {
            let recordName = this.recordStore.endpoint.replace(/\//g, '_');
            this.recordName = `${recordName}${this.recordStore.id}`;
            this.socket = io(api.serverEndpoint);
            api.post('/api/record_view/', JSON.stringify({recordName: this.recordName, userName: api.currentUser.UserName}));
            this.recordView = setInterval(() => {
                api.post('/api/record_view/', JSON.stringify({recordName: this.recordName, userName: api.currentUser.UserName}));
            }, 10000);
            this.socket.on('record_view', (n) => {
                if (n.userName != api.currentUser.UserName && n.recordName == this.recordName) {
                    api.showAlert = {text: `${n.userName} ${tr('is working on this record')}`, level: 0};
                }
            });
        }*/

    },
    watch: {
        attachList: {
            async handler (oldValue, newValue) {
                        if (this.ready && oldValue && oldValue.length != newValue.length) {
                            //this.$store.commit('setModified', true);
                        }
                    },
                    deep: true,
        },
        callConfirmClose () {
            if (this.callConfirmClose) {
                this.close();
            }
        },
        modified () {
            this.$store.commit('setModified', this.modified);
        },
        changeNr () {
            this.updateHideIf();
        }

    },
    methods: {
        componentIf (name) {
            let field = _.find(this.componentFields, (r) => r.name == name);
            if (field && field.showIf) {
                return field.showIf(this.recordStore);
            }
            return true;
        },
        disabledTab (arrayField) {
            if (arrayField.disabledTab) {
                return arrayField.disabledTab(this.recordStore);
            }
        },
        alert (arrayField) {
            if (arrayField.disabledTab && arrayField.disabledTab(this.recordStore)) {
                alert(tr('Record must be saved'));
            }
        },
        afterMount () {},
        async onMount () {
            let view = this.table;
            if (this.view) view = this.view;
            this.options = await api.getModuleFieldsOptions(view);
            let endpoint = this.endpoint;
            let table = this.table;
            if (this.options.apiEndpoint) {
                endpoint = this.options.apiEndpoint;
            }
            if (this.options.tableName) {
                table = this.options.tableName;
            }
            let model;
            if (modelTables[table]) {
                model = api.importMixinModule(table, 'model');
            } else {
                model = api.importMixinModule('record', 'model');
            }
            let record;
            if (this.id == 'new') {
                this.isNew = true;
                this.$store.commit('setNew', true);
                if (this.newRecord) {
                    record = await new model({tableName: table, options: this.options, endpoint: endpoint, record: this.newRecord});
                } else {
                    record = await new model({tableName: table, options: this.options, endpoint: endpoint});
                }
                vueTools.setModified(true);
            } else {
                record = await model.get({id: this.id, tableName: table, options: this.options, endpoint: endpoint});
            }
            this.$store.commit('initRecord', {record, modal: this.modal});
            this.setRecord();
        },
        setRecord () {
            this.record = tools.plainObject(this.recordStore);
            api.setProcessing(false)
            EventBus.$emit('update-child');
        },
        showDelete () {
            this.toDelete = true;
        },
        close () {
            if (this.modified || (this.recordStore && !this.recordStore.id)) {
                this.askSave = true;
                this.nextClose = true;
            } else {
                this.closePath();
            }
        },
        async confirmClose () {
            this.$store.commit('setCallConfirmClose', false);
            this.$store.commit('setNew', false);
            vueTools.setModified(false);
            this.askSave = false;
            this.nextClose = false;
            this.closePath();
        },
        cancelClose () {
            this.$store.commit('setCallConfirmClose', false);
            this.askSave = false;
            this.nextClose = false;
        },
        saveClose () {
            this.$store.commit('setCallConfirmClose', false);
            this.askSave = false;
            this.save()
        },
        closePath () {
            if (this.modal) {
                this.$emit('close');
                api.setProcessing(false);
                return;
            }
            if (this.toPath!=null) {
                this.$router.push({name: this.toPath.name, params: this.toPath.params});
                this.$store.commit('setToPath', null);
            } else {
                this.$router.push({ name: 'abm', params: { table: this.$router.currentRoute.params.table }});
            }
        },
        async addNew () {
            api.setProcessing(true);
            let table = this.table;
            let endpoint = this.endpoint;
            if (this.options.apiEndpoint) {
                endpoint = this.options.apiEndpoint;
            }
            if (this.options.tableName) {
                table = this.options.tableName;
            }
            this.$store.commit('setModified', false);
            if (!this.modal) {
                this.$router.replace({params: {id: 'new', table: table, notifications: null}})
                api.setProcessing(false);
                $('#record-window-modal').animate({
                    scrollTop : 0
                }, 500);
            } else {
                let model;
                if (modelTables[table]) {
                    model = api.importMixinModule(table, 'model');
                } else {
                    model = api.importMixinModule('record', 'model');
                }
                let record = await new model({tableName: table, options: this.options, endpoint: endpoint});
                this.$store.commit('initRecord', {record, modal: this.modal});
                this.setRecord();
            }
        },
        async copy () {
            api.setProcessing(true);
            let table = this.table;
            if (this.options.apiEndpoint) {
                //endpoint = this.options.apiEndpoint;
            }
            if (this.options.tableName) {
                table = this.options.tableName;
            }
            let model;
            if (modelTables[table]) {
                model = api.importMixinModule(table, 'model');
            } else {
                model = api.importMixinModule('record', 'model');
            }
            let record = await model.clone({record: this.recordStore});
            if (!this.modal) {
                this.$store.commit('setModified', false);
                this.$router.replace({params: {id: 'new', newRecord: record, table: table}})
                $('#record-window-modal').animate({
                    scrollTop : 0
                }, 500);
            } else {
                this.$store.commit('initRecord', {record, modal: this.modal});
                this.setRecord();
            }
            api.setProcessing(false);
        },
        getColumns (field) {
            //return 'col col-min';
            if (field.columns) return 'col-md-' + field.columns;
            if (this.columns) return 'col-md-' + this.columns;
            return 'col-md-3';
        },
        async save () {
            api.setProcessing(true);
            this.saving = true;
            let res = await this.recordStore.save();
            this._notifications = null;
            this.setRecord();
            if (!res) return;
            this.$store.commit('setModified', false);
            this.$store.commit('setNew', false);
            this.showComps = false;
            setTimeout(() => {
                this.showComps = true;
            }, 1);
            if (this.nextClose) {
                this.closePath();
            }
            if (this.isNew) {
                this.replacePath();
            }
            api.setProcessing(false);
            this.afterSave();
        },
        afterSave () {
            if (this.recordStore) EventBus.$emit('after-save', true, this.recordStore.tableName);
        },
        replacePath () {
            if (this.modal) return;
            this.$router.push({ name: 'record', params: {
                table: this.table,
                id: this.recordStore.id.toString(),
                notifications: this.recordStore.notifications,
            }})
        },
        async remove () {
            this.toDelete = false;
            api.setProcessing(true)
            let res = await this.recordStore.remove();
            if (res) {
                if (this.recordStore) EventBus.$emit('after-save', true, this.recordStore.tableName);
                this.closePath();
            }
            //api.setProcessing(false)
        },
        disabledAction (action) {
            if (action.disabledIf) {
                return action.disabledIf(this.recordStore);
            }
        },
        async callAction (method, id) {
            let self = this;
            this.processingActions[id] = true;
            this.processingActions = Object.assign({}, this.processingActions);
            await this.recordStore[method]((error, result) => {
                if (error) {
                    self.recordStore.addError(error.err, error.detail)
                    self.record = tools.plainObject(self.recordStore);
                }
                self.processingActions[id] = false;
                self.processingActions = Object.assign({}, self.processingActions);
                if (result && result.route) {
                    this.$router.replace({name: result.route.name, params: {id: result.route.id.toString(), table: result.route.table}})
                }
                if (result && result.modal) {
                    this.$root.$children[0].openModalRecord({modalRecord: result.record, modalTable: result.table, modalId: 'new'});
                }
                if (result && result.update) {
                    self.setRecord();
                    self.showComps = false;
                    setTimeout(() => {
                        self.showComps = true;
                    }, 1);
                }
            });
        },
        getId () {
            return this.recordStore.id;
        },
        titleName () {
            let res;
            if (this.getWindowTitle) res = this.getWindowTitle(this.record);
            if (!res) res = this.recordStore.titleName;
            this.$emit('setTitle', res);
            return res;
        },
        isActive (i, show, field) {
            if (i==0 && !this.firstTabFields) {
                return 'active';
                if (field && this.recordStore.invalid[field]) res += ' tab-alert';
                return res;
            }
        },
        async change (options, fieldName, value) {
            await vueTools.change(this, options, fieldName, value, this.modal);
            EventBus.$emit('set-record');
        },
        setRouterHistoryName () {
            if (!this.recordStore) return;
            if (!this.recordStore.getName) return;
            let routerHistory = localStorage.getItem('router-history');
            if (!routerHistory) return;
            routerHistory = JSON.parse(routerHistory);
            let path = this.$router.currentRoute.path;
            let histIndex = _.findIndex(routerHistory, (r) => r.path == path);
            if (histIndex>-1) {
                routerHistory[histIndex].name = this.recordStore.getName;
                localStorage.setItem('router-history', JSON.stringify(routerHistory));
                EventBus.$emit('update-history');
            }
        },
        downloadData () {
            let data = JSON.stringify(tools.plainObject(this.record));
            let name = `${this.table}-${this.record.id}.txt`;
            tools.download(name, data);
        },
        uploadData () {
            var input = document.createElement('input');
            input.type = 'file';
            input.onchange = e => {
               var file = e.target.files[0];
               var reader = new FileReader();
               reader.readAsText(file,'UTF-8');
               reader.onload = readerEvent => {
                  var content = readerEvent.target.result;
                  this.record = JSON.parse(content);
               }
            }
            input.click();
        },
        updateHideIf () {
            let res = {};
            if (!this.recordStore) return res;
            if (!this.recordStore.abmFields) return res;
            for (let field of this.recordStore.abmFields) {
                res[field.name] = false;
                if (field.hidden) res[field.name] = true;
                if (field.hideIf && field.hideIf(this.recordStore)) {
                    res[field.name] = true;
                }
            }
            this.hideIf = res;
        },
        showAlert (views) {
            if (views.length == 0) api.showAlert = null;
            if (!api.currentUser) return;
            let row = _.find(views, (r) => {
                return r.modified;
            });
            if (row) {
                api.newAlert({text: row.modified, level: 0, notClose: true});
                return;
            }
            row = _.find(views, (r) => r.user_id != api.currentUser.id);
            if (row) {
                let user = _.find(api.tables.user, (r) => r.id == row.user_id);
                if (user) {
                    api.newAlert({text: `${user.UserName} ${tr('is working on this record')}`, level: 0, notClose: true});
                } else {
                    api.newAlert({text: `${tr('Other user')} ${tr('is working on this record')}`, level: 0, notClose: true});
                }
                return;
            }
            row = _.find(views, (r) => r.user_id == api.currentUser.id);
            if (row) {
                api.newAlert({text: `${api.currentUser.UserName} ${tr('is working on this record')} ${tr('in other tab')}`, level: 0, notClose: true});
            }
        },
        handleClick (event) {
            if (this.options.allowContextMenu) {
                this.$root.$children[0].handleClick(event);
            }
        }


    },
    beforeDestroy () {
        if (!this.modal) vueTools.setModified(false);
        this.$store.commit('initRecord', {record: {}, modal: this.modal});
        EventBus.$off('set-record', this.setRecord);
        if (this.recordView) clearInterval(this.recordView)
        api.showAlert = null;
    }
}
</script>
