<template>
    <div class="alert alert-danger" role="alert" :class="{'alert-popup-right': level == 0, 'alert-popup-middle': level == 1 }">
      <button type="button" class="close ml-3" @click="close" v-if="!notClose">
        <span aria-hidden="true">&times;</span>
      </button>
      <span>{{tr(text)}}</span>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'alert-popup',
    data () {
        return {
            text: null,
            level: 0,
            notClose: false,
        }
    },
    mounted () {
        this.text = api.showAlert.text;
        this.level = api.showAlert.level;
        if (api.showAlert.notClose) this.notClose = true;
    },
    methods: {
        close () {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>
    .alert-popup-right {
        position: fixed;
        z-index: 1000;
        bottom: 10%;
        right: 1%;
    }
    .alert-popup-middle {
        position: fixed;
        z-index: 1000;
        top: 30%;
        right: 30%;
    }
</style>

