class Tools {

    canAccess (user, type, method, endpoint, defValue=true, force=false) {
        if (!user || !user.AccessGroup) return true;
        let p = user.AccessGroup.permissionsMerge || {};
        if (typeof p[type] === 'boolean') return p[type]
        if (defValue){
            var def = (((p[type] || {}).defaults || {}).methods || {})[method];
            if (def===false && force) def = true;
            if (def===undefined) def = true;
        } else {
            var def = false;
        }
        if (!endpoint) return def
        endpoint = endpoint.replace(/(<[^:]+)?:[^>/]+>?/g, ':p')
        var perm = ((p[type] || {})[endpoint] || {}).methods
        if (typeof perm === 'object') {
            return (typeof perm === 'undefined') ? def: perm[method]
        }
        if (typeof perm === 'boolean'){
            return perm
        }
        if (!perm) {
            return def
        }
        return (typeof perm[method] === 'undefined') ? def: perm[method]
    }

    canAccessModule (user, module) {
        let v = tools.canAccess(user, "view", "GET", module.user_access , module.def);
        return v;
    }

    mergeDateAndTime (d, t, z = 'utc') {
        if (!d) return '';
        if(z == 'local')
            return moment.utc(d+' '+t, 'YYYY-MM-DD HH:mm:ss').local().format('YYYY-MM-DDTHH:mm:ss');
        return moment(d+' '+t, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss');
    }

    getDate (d) {
        if (!d) return '';
        return moment(d).format("DD/MM/YYYY");
    }

    getTime (t) {
        if (!t) return '';
        return moment(t).format("HH:mm:ss");
    }

    getHourMinute (t) {
        if (!t) return '';
        return moment(t).format("HH:mm");
    }

    getDateTime (d) {
        if (!d) return '';
        return moment(d).format("DD/MM/YYYY HH:mm");
    }

    getLocalDate (d, format) {
        if (!d) return '';
        if (format) return moment(d).local().locale(api.language).format(format)
        return moment(d).local().locale(api.language).format('DD MMM YYYY')
    }


    getLocalTime (d) {
        if (!d) return '';
        return moment(d).local().locale(api.language).format('HH:mm')
    }

    getLocalDateTime (d) {
        if (!d) return '';
        return moment.utc(d).local().locale(api.language).format('DD MMM YYYY HH:mm')
    }


   	dateIsAfter (date1, date2){
   		if (moment(date1).isAfter(date2)) return true;
   		return false;
   	}

    formatNumber (value, {c, s, d}) {
        let currency = c;
        let showCurrency = s;
        let decimals = 0;
        if (currency && currency.Decimals) decimals = currency.Decimals;
        if (d != null && d != undefined  ) decimals = d;
        let res = value;
        if (!currency) currency = {};
        if (typeof value === 'string') res = parseFloat(value).toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        if (typeof value === 'number') res = value.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        if (showCurrency == 1 && currency.id) {res = `${res} ${currency.id}`};
        if (showCurrency == 2 && currency.id) res = `${currency.id} ${res}`;
        if (showCurrency == 3 && !currency.Symbol) {res = `${res} ${currency.id}`};
        if (showCurrency == 4 && !currency.Symbol) res = `${currency.id} ${res}`;
        if (showCurrency == 4 && currency.Symbol) res = `${currency.Symbol} ${res}`;
        if (showCurrency == 3 && currency.Symbol) res = `${res} ${currency.Symbol}`;

        return res;
    }

   	diffHours (date1, date2) {
   		return moment(date1).diff(moment(date2))/3600000;
   	}

    async downloadRecord (table, id) {
        let record = await api.getObjectFromStore(table, id);
        let text = JSON.stringify(record);
        let fileName = `${table}-${id}.txt`;
        tools.download2(text, fileName, 'text/plain')
        return record;
    }

    download2 (content, fileName, contentType) {
        var a = document.createElement("a");
        var file = new Blob([content], {type: contentType});
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
    }

    getValue (v){
        if (v && v.value!=undefined && v.value!=null){
            return v.value;
        }
        return v;
    }

    getBGColor (v){
        if (v && v.bgColor) return v.bgColor;
        return null;
    }

    getAlign (v) {
        if (v && v.align) return v.align;
        return 'left';
    }

    setAutoSizeTextArea (t){
        t.addEventListener('keyup', function() {
            tools.autoSizeTextArea(this);
        }, false);
    }

    autoSizeTextArea (self){
        self.style.overflow = 'hidden';
        self.style.height = 0;
        self.style.height = self.scrollHeight + 'px';
        if (parseInt(self.style.height)<40) self.style.height = '40px';
    }

    normalize (s, specials) {
        if (!s) return s;
        if (s.normalize != undefined) {
            s = s.normalize ("NFKD");
        }
        s = s.toString().replace(/æ/g, "ae");
        s = s.replace(/[\'`´]/g, "");
        if (specials) {
            s = s.replace(/['`´.*+?^&${}()|[\]\\]/g, '');
        } else {
            s = s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') //all chars literal

        }
        return s.replace(/[\u0300-\u036F]/g, "");
    }

    normalizeText (s) {
        if (!s) return s;
        if (s.normalize != undefined) {
            s = s.normalize ("NFKD");
        }
        s = s.toString().replace(/æ/g, "ae");
        s = s.replace(/[\'`´]/g, "");
        //s = s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') //all chars literal
        return s.replace(/[\u0300-\u036F]/g, "");
    }

    joinText (list, joiner, lastJoiner) {
        if (list.length==1) return list[0];
        if (list.length==2) {
            return list.join(lastJoiner);
        }
        if (list.length>2) {
            let res = '';
            for (let i in list) {
                res += list[i];
                if (i<list.length-2) {
                    res += joiner;
                } else if (i==list.length-2) {
                    res += lastJoiner;
                }
            }
            return res;
        }
    }

    objectsDiff (a, b) {
        let s1 = JSON.stringify(a);
        let s2 = JSON.stringify(b);
        return s1 === s2;
    }


    toNumber (value, decimals, currency) {
        if (this.isNull(value)) return '';
        if (this._toExcel) {
            if (!api.currentUser.DecimalSeparator) return value;
            return value.toString().replace('.', api.currentUser.DecimalSeparator);
        }
        let c = currency;
        if (currency && typeof currency === 'string') {
            c = _.find(api.tables.currency, (r) => r.id == currency);
        }
        if (!value) return value;
        if (c) return tools.formatNumber(value, {c});
        return parseFloat(value).toLocaleString('en', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
    }

    toTitleCase (str) {
        if (!str) return;
        return str.replace(
            /\w\S*/g,
            function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    getAbmFields (fields, listWindow) {
        let res = []
        for (let f of fields) {
            if (f.hideFromList && listWindow) continue;
            if (f.hidden) continue;
            if (typeof f.editor === 'object') continue;
            if (f.name) {
                let ff = _.cloneDeep(f)
                ff.label = f.label? f.label : f.name;
                ff.sortOrder = 1;
                ff.editor = f.editor ? f.editor : 'text';
                ff.type = f.type ? f.type: 'text';
                if (f.classes) ff.classes ? f.classes : '';
                res.push(ff);
            } else {
                res.push({ name: f, label: f, editor: 'text', sortOrder: 1 });
            }
        }
        return res;
    }

    async fetchTable (tableName, endpoint, filters, refresh, record, showClosed) {
        let f = filters;
        if (typeof(f) === 'function' && record) {
            f = f(record);
        } else if (typeof(f) === 'function' && !record) {
            f = null;
        }
        if (!refresh && !f && !endpoint) {
            let res = api.getFromStore(tableName);
            if (res) return res;
        }
        if (!showClosed) {
          showClosed = false;
        }
        let e = endpoint ? endpoint: '/api/' + tableName + '/?IncludeClosed=' + showClosed;
        if (this.hasClosed && f) {
            f['IncludeClosed'] = showClosed;
        }
        if (f) {
            f =  {filters: JSON.stringify(f)};
        }
        let res = await api.get(e, f);
        if (res) {
            if (!f && !endpoint) {
                api.setTableToStore(tableName, res);
            }
            return res;
        }
        return [];
    }

    async calculateFieldOptionsByField (field, fo, serverSide, record, showClosed, currentValue) {
        if (Array.isArray(field.options) || (typeof(field.options) === 'function')) {
            let options = field.options;
            if (typeof(field.options) === 'function') {
                options = field.options();
            }
            fo[field.name] = _.map(_.filter(options, (r)=> !r.skip), function(o) {
                let value = o;
                let label = o;
                let bgColor = null;
                let color = '#000000';
                let disabled = null;
                let icon;
                if (o.value!=null && o.value!=undefined) value = o.value;
                if (o.label) label = o.label;
                if (o.bgColor) bgColor = o.bgColor;
                if (o.color) color = o.color;
                if (o.icon) icon = o.icon;
                if (o.disabled) disabled = o.disabled;
                return {
                    label: label,
                    value: value, 
                    bgColor: bgColor, 
                    color: color, 
                    disabled: disabled, 
                    sortBy: o.sortBy,
                    icon: o.icon,
                }
            })
        } else if (field.relation && !serverSide) {
            let rows = await this.fetchTable(field.relation, field.endpoint, field.endpointFilters, false, record, showClosed);
            let opts = []
            let found;
            for (let row of rows) {
                let skip = false;
                if (field.filters) {
                    if (typeof(field.filters) === 'object') {
                        for (let f in field.filters) {
                            if (row[f] != field.filters[f]) {
                                skip = true;
                            }
                        }
                    } else if (typeof(field.filters) === 'function') {
                        if (!field.filters(row, record)) {
                            skip = true;
                        }
                    }
                }
                //console.log(field.name, currentValue, row.id)
                if (currentValue && currentValue == row.id) {
                    skip = false;
                    found = true;
                }
                if (!skip) {
                    if (typeof(field.optionLabels) === 'function') {
                        let label =  field.optionLabels(row);
                        opts.push({label: label, value: row.id});
                    } else {
                        opts.push({label: row[field.optionLabels], value: row.id});
                    }
                }
            }
            if (!found && currentValue && field.setRelationTo) {
                if (record && record[field.setRelationTo]) {
                    if (typeof(field.optionLabels) === 'function') {
                        let label =  field.optionLabels(record[field.setRelationTo]);
                        opts.push({label: label, value: currentValue});
                    } else {
                        opts.push({label: record[field.setRelationTo][field.optionLabels], value: currentValue});
                    }
                }
            }
            fo[field.name] = opts
        }
    }

    async calculateFieldOptions (abmFields, serverSide, record, showClosed, onlyHeaders, fieldName, currentValue) {
        let fo = {}
        let promises = [];
        for (let field of abmFields) {
            if (fieldName && field.name != fieldName) continue;
            await this.calculateFieldOptionsByField(field, fo, serverSide, record, showClosed, currentValue);
            if (!onlyHeaders) {
                if (field.rowFields && Array.isArray(field.editor) && Array.isArray(field.rowFields)) {
                    for (let row of record[field.name]) {
                        for (let rowField of field.rowFields) {
                            await this.calculateFieldOptionsByField(rowField, fo, serverSide, row, showClosed, currentValue);
                        }
                    }
                }
                if (field.editor == 'tab') {
                    for (let rowField of field.fields) {
                        await this.calculateFieldOptionsByField(rowField, fo, serverSide, record, showClosed, currentValue);
                    }
                }
            }
        }
        return fo;
    }

    getDisplayValue (record, field, serverSide, fieldOptions, cutName) {
        if (field.computed) {
            return field.computed(record);
        }
        if (record[field.name]===null || record[field.name]===undefined) return '';
        if (record[field.name]===true) return tr('YES');
        if (record[field.name]===false) return tr('NO');
        if (field.dateFormat && record[field.name]) {
            if (field.locale || field.name.includes('UTC')) {
                return moment.utc(record[field.name]).local().locale(api.language).format(field.dateFormat);
            }
            return moment(record[field.name]).format(field.dateFormat);
        } else if (field.editor == 'datetime' && record[field.name]) {
            return this.getLocalDateTime(record[field.name]);
        }
        if (field.relation || field.options) {
            if (serverSide) {
                let key = field.name + '_' + field.optionLabels;
                if (record[key]) return record[key];
            }
            if (fieldOptions) {
                if (!fieldOptions[field.name]) return null
                let opt = _.find(fieldOptions[field.name], (opt) => opt.value == record[field.name])
                if (opt) {
                    if (field.tr || field.options) return tr(opt.label);
                    return opt.label;
                }
            }
            if (field.setRelationTo && record[field.setRelationTo] && record[field.setRelationTo][field.optionLabels]) {
                return record[field.setRelationTo][field.optionLabels];
            }
            if (field.setRelationTo && record[field.setRelationTo] && record[field.setRelationTo].Name) {
                return record[field.setRelationTo].Name;
            }
            return record[field.name];
        }
        if (cutName) {
            let maxLength = 50;
            if (field.maxLength) maxLength = field.maxLength;
            if (record[field.name] && typeof record[field.name] === 'string') {
                if (record[field.name].length > maxLength) {
                    return record[field.name].substring(0, maxLength) + '...';
                }
            }
        }
        if (field && field.editor && field.currency && record[field.currency]) {
            return tools.toNumber(record[field.name], null, record[field.currency]);
        }
        return ""+record[field.name];
    }

    addNotification ({msg, comment, action, key, id, status}, insert) {
        let notifications = localStorage.getItem('notifications');
        notifications = JSON.parse(notifications);
        if (notifications) {
            let f = _.find(notifications, (r) => r.comment == comment);
            if (f) return;
            if (id) {
              let f = _.find(notifications, (r) => r.id == id);
              if (f) return;
            }
            if (key) {
              let f = _.find(notifications, (r) => r.key == key);
              if (f) return;
            }
        }
        if (!notifications) notifications = [];
        if (insert) {
            notifications.splice(0, 0, {msg, comment, action, key, id, status})
        } else {
            notifications.push({msg, comment, action, key, id, status});
        }
        localStorage.setItem('notifications', JSON.stringify(notifications));
    }

    responseHelper (status, response) {
        if (status==401) {
            if (window.location.hash == '#/login') {
                return {err: 'Incorrect User or Password', detail: response};
            } else {
                api.currentUser = null;
                window.location.replace(window.location.origin + '/#/login')
            }
        } else if (status==400){
            if (typeof response === 'object')  {
                if (response.codes && response.params) {
                    let m = [];
                    let i = 0;
                    for (let p of response.codes) {
                        if (p=='%s') {
                            m.push(response.params[i])
                            i += 1;
                        } else {
                            m.push(tr(p));
                        }
                    }
                    return {err: m.join(' '), detail: response, field: response.field};
                }
                return {err: response.code, detail: response, field: response.field};
            } else {
                return {err: response, detail: response};
            }
        } else if (status==420){
            return {err: 'Integrity Error', detail: response};
        } else if (status==403){
            return {err: 'Action not allowed', detail: response};
        } else if (status==500){
            return {err: 'Server Error', detail: `Server Error: ${response}`};
        } else {
            return status;
            //reject(error);
        }
    }

    getWeekDayName (w) {
        return {
            0: 'Monday',
            1: 'Tuesday',
            2: 'Wednesday',
            3: 'Thursday',
            4: 'Friday',
            5: 'Saturday',
            6: 'Sunday'
         }[w];
    }

    getClass (tabId, field, currentTab, invalid) {
        let res = '';
        if (currentTab==tabId) res += 'active ';
        if (field && invalid[field]) res += ' tab-alert';
        return res;
    }

    abmList (self) {
        if (self.search) {
            let abmFields = self.abmFields;
            /*if (self.columnsNames) {
                abmFields = self.columnsNames;
            }
            if (self.fieldsAbmList) {
                abmFields = _.filter(self.abmFields, (r) => {
                    return self.fieldsAbmList.indexOf(r.name) > 1;
                });
            }*/
            //let t1 = moment(new Date()).valueOf();
            let values = self.search.split(' ')
            let res = _.filter(self.dataList, (r) => {
                for (let value of values){
                    let found = false;
                    let re = new RegExp(tools.normalize(value, true), 'i')
                    for (let f of abmFields) {
                        let displayValue;
                        if (self.listValues) {
                            //displayValue = self.listValues[[f.name, r[f.name]]]
                        } else {
                            displayValue = tools.getDisplayValue(r, f, self.serverSide, self.fieldOptions, false)
                        }
                        displayValue = tools.getDisplayValue(r, f, self.serverSide, self.fieldOptions, false)
                        if (!displayValue) displayValue = r[f]
                        if (displayValue){
                            let m = tools.normalize(displayValue, true).match(re)
                            if (m) found = true;
                        }
                    }
                    if (!found) return false;
                }
                return true;
            })
            //let t2 = moment(new Date()).valueOf();
            //console.log('search', t2 - t1)
            return res;
        }
        return self.dataList;
    }

    getTime () {
        if (!this.t)  {
            this.t = new Date().getTime();
            return 0;
        }
        this.oldT = this.t;
        this.t = new Date().getTime();
        return this.t - this.oldT;
    }

    printLog (obj) {
        return;
        let t = this.getTime();
        console.log('ZZZ', t, this.t, this.oldT, obj, this.obj)
        this.obj = obj;
    }

    isNormalInteger (str) {
        var n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
    }

    getBases (self) {
        let res = {};
        for (let id in self) {
            if (tools.isNormalInteger(id)) {
                res[id] = self[id];
            }
        }
        return res;
    }

    ifText (s) {
        if (!s) return false;
        let t = s.replace(/ |<p>|<\/p>|<br>|\n/g, '');
        if (!t) return false;
        if (t.length>0) return true;
    }

    serverTypes (fields, options) {
        let res = [];
        let types = {
            'Text': 'text-area',
            'String': 'text',
            'Float': 'number',
            'Integer': 'number',
            'DateTime': 'datetime',
            'Time': 'time',
            'Date': 'date',
            'Boolean': 'checkbox',
            'Collection': '[]',
            'attach': 'attach',
            'LONGBLOB': 'text-area',
        }
        let closed = false;
        for (let fieldName in fields) {
            let field = fields[fieldName];
            let editor = field.type;
            if (types[editor]) editor = types[editor];
            let label = fieldName;
            if (fieldName.match(/[A-Z][a-z]+|[0-9]+/g)) {
                label = fieldName.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
            }
            if (field.label) label = field.label;
            if (fieldName == 'Closed') {
                closed = {name: fieldName, editor: editor, label: label, hideFromList: true, footerRow: true};
            } else {
                let f = {name: fieldName, editor: editor, label: label};
                if (editor == 'date') {
                    f.dateFormat = 'DD/MM/YYYY';
                }
                if (editor == 'datetime') {
                    f.dateFormat = 'DD/MM/YYYY HH:mm';
                }
                if (editor == 'number') {
                    f.decimal = 2;
                }
                if (editor == 'number' && field.type=='Integer') {
                    f.decimal = 0;
                }
                if (editor == 'text-area') {
                    f.columns = 12;
                }
                if (field.relation) {
                    f.editor = 'vue-select';
                    f.relation = field.relation;
                    f.setRelationTo = field.relation_to;
                    f.optionLabels = 'Name';
                    if (field.relation == 'user') f.optionLabels = 'UserName';
                    f.label = tools.toTitleCase(field.relation);
                    if (field.relation_to) {
                        f.label = field.relation_to;
                        if (field.relation_to.match(/[A-Z][a-z]+|[0-9]+/g)) {
                            f.label = field.relation_to.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
                        }
                    }
                }
                if (field.options) {
                    f.editor = 'select';
                    f.options = _.map(field.options, (r) => {
                        return {value: r, label: tools.toTitleCase(r)}
                    });
                }
                if (field.type == 'Collection') {
                    f.editor = [];
                    f.rowFields = tools.serverTypes(field.row_fields);
                }
                if (field.hide) f.hideFromList = true;
                if (field.hide_list) f.hideFromList = true;
                if (field.add_blank) f.addBlank = true;
                if (field.columns) f.columns = field.columns;
                if (field.length) f.length = field.length;
                if (field.footer_row) f.footerRow = true;
                if (field.hide) f.hidden = true;
                if (field.order) f.order = field.order;
                if (field.label) f.label = field.label;
                if (field.read_only) f.readonly = true;
                if (field.required) f.required = true;
                if (field.decimal) f.decimal = field.decimal;
                if (field.def_value) f.defValue = field.def_value;
                res.push(f)
            }
        }
        res.sort(function(a, b) {
            if (!a.order) return 1;
            if (!b.order) return -1;
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
        })
        if (options && options.fieldsOrder) {
           res.sort((a, b) => {
                let sa = options.fieldsOrder.indexOf(a.name);
                let sb = options.fieldsOrder.indexOf(b.name);
                if (sa==-1) sa = 100;
                if (sb==-1) sb = 100;
                return sa - sb;
            });
        }
        if (options && options.extraFields) {
            for (let field of options.extraFields) {
                res.push(field);
            }
        }

        if (closed) {
            res.push(closed)
        }
        if (options && options.fieldsDataReplace) {
            for (let fieldName in options.fieldsDataReplace) {
                let fieldIndex = _.findIndex(res, (f) => f.name == fieldName);
                if (fieldIndex>-1) {
                    for (let data in options.fieldsDataReplace[fieldName]) {
                        res[fieldIndex][data] = options.fieldsDataReplace[fieldName][data];
                    }
                }
            }
        }
        return res;
    }

    plainObject (record, onlyHeaders) {
        let r = Object.assign({}, record);
        let toDelete = [];
        for (let fieldName in record) {
            if (!onlyHeaders && fieldName[0] != '$') {
                if (Array.isArray(record[fieldName])) {
                    r[fieldName] = [];
                    for (let row of record[fieldName]) {
                        let newRow = row;
                        if (typeof newRow === 'object') {
                            newRow = this.plainObject(row);
                        }
                        r[fieldName].push(newRow);
                    }
                } else if (typeof record[fieldName] === 'object') {
                    if (record[fieldName]) {
                        r[fieldName] = this.plainObject(record[fieldName]);
                    }
                }
            }
            if (fieldName[0] == '$') {
                toDelete.push(fieldName);
            }
        }
        //if (toDelete.length>0) console.log(toDelete)
        for (let fieldName of toDelete) {
            delete r[fieldName];
        }
        //console.log(r)
        return r;
    }

    plainArray (rows) {
        let r = [];
        for (let row of rows) {
            let newRow = this.plainObject(row);
            r.push(newRow);
        }
        return r;
    }

    clean (record) {
        if (!record) return;
        if (record.fields){
            for (let field of record.fields) {
                if (field && (Array.isArray(field.editor) || field.rowFields)){
                    for (let row of record[field.name]) {
                        if (row) {
                            this.clean(row);
                        }
                    }
                } else if (field && typeof field.editor == 'object') {
                    if (record[field.name]) {
                        this.clean(record[field.name]);
                    }
                }
            }
        }
        delete record['fields'];
        delete record['fieldsObject'];
        delete record['abmFields'];
        delete record['arrayFields'];
        delete record['fieldOptions'];
        delete record['tabList'];
        delete record['tableSpec'];
        delete record['invalid'];
        delete record['_loaded'];
        delete record['_id'];
        delete record['_computedGetters'];
        delete record['_cache'];
        delete record['_isClone'];
        delete record['$parent'];
        delete record['me'];
        delete record['_new'];
        delete record['_modified'];
        delete record['serverSide'];
        delete record['search'];
        delete record['beforeSave'];
        delete record['canAdd'];
        delete record['userCanEdit'];
        delete record['userCanDelete'];
        delete record['evalDelete'];
        delete record['setTitleName'];
        delete record['evalEdit'];
        delete record['afterEdit'];
        delete record['showClosed'];
        delete record['attachList'];
        delete record['attached'];
        delete record['endpoint'];
        delete record['notifications'];
        delete record['processingAttach'];
        delete record['tableName'];
        delete record['getters'];
        delete record['_recordLoaded']
        delete record['dependencies']
        delete record['_requestCount']
        delete record['_changeNr']
        delete record['roomTypes']
        if (!record.id) delete record['id']
    }

    cleanRelations (record) {
        if (!record) return;
        if (record.fields){
            for (let field of record.fields) {
                if (field && (Array.isArray(field.editor) || field.rowFields)){
                    for (let row of record[field.name]) {
                        if (row) {
                            this.cleanRelations(row);
                        }
                    }
                } else if (field && typeof field.editor == 'object') {
                    if (record[field.name]) {
                        this.cleanRelations(record[field.name]);
                    }
                }
                if (field.setRelationTo && record[field.setRelationTo]) {
                    delete record[field.setRelationTo];
                }
            }
        }
    }

    async setRelations (record) {
        if (!record) return;
        if (record.fields){
            for (let field of record.fields) {
                if (field && (Array.isArray(field.editor) || field.rowFields)){
                    for (let row of record[field.name]) {
                        if (row) {
                            this.setRelations(row);
                        }
                    }
                } else if (field && typeof field.editor == 'object') {
                    if (record[field.name]) {
                        this.setRelations(record[field.name]);
                    }
                }
                if (field.setRelationTo && record[field.setRelationTo]) {
                    await record.setRelationTo(field);
                }
            }
        }
    }


    removeGetters (recordClass, record) {
        const proto = Object.getPrototypeOf (recordClass);
        const names = Object.getOwnPropertyNames (proto);
        const getters = names.filter (name => name.substring(0, 3)=='$$_');
        for (let getterName of getters) {
            delete record[getterName.replace('$$_', '')]
        }
    }

    checkFields (self, record) {
        let required = false;
        self.invalid = {};
        self.invalid._tabData = false;
        let fields = _.cloneDeep(self.fields);
        if (self.tabList) {
            for (let tab of self.tabList) {
                for (let field of tab.fields) {
                    field.tabName = tab.name;
                    fields.push(field);
                }
            }
        }
        for (let p in fields){
            let needsRequired = fields[p].required
            if (fields[p].requiredCallback) {
                needsRequired = fields[p].requiredCallback(self, record);
            }
            if (needsRequired) {
                let value = record[fields[p].name];
                if (value===null || value===undefined || value==='' ||
                    (fields[p].editor=='date' && value=='Invalid date')){
                    if (fields[p].tabName) {
                        self.invalid[fields[p].tabName] = true;
                    } else if (!fields[p].headerField){
                        self.invalid._tabData = true;
                    }
                    self.invalid[fields[p].name] = true;
                    required = true;
                }
            }
            if (Array.isArray(fields[p].editor)) {
                for (let row of record[fields[p].name]) {
                    if (row.Closed) continue;
                    for (let rowField of row.fields) {
                        let needsRequired = rowField.required
                        if (rowField.requiredCallback) {
                            needsRequired = rowField.requiredCallback(row, record);
                        }
                        if (needsRequired) {
                            let value = row[rowField.name];
                            if (value===null || value===undefined || value==='' ||
                                (rowField.editor=='date' && value=='Invalid date')){
                                self.invalid[fields[p].name] = {};
                                self.invalid[fields[p].name][rowField.name] = true;
                                row.invalid[rowField.name] = true;
                                required = true;
                            }
                        }
                    }
                }
            }
        }
        self.invalid = Object.assign({}, self.invalid);
        if (required) return ['Fill required fields'];
    }

    compare (a, b, parent) {
      let res = {};
      for (let i in a) {
        if (i.substring(0, 1)== '_') continue;
        if (i == 'ModifiedUTC') continue;
        if (i == 'ModifiedUserId') continue;
        if (i == 'BookingDayId') continue;
        if (i == 'BookingDayHotelId') continue;
        if (i.substring(0, 1)== '$') continue;
        if (i.substring(0, 1) != i.substring(0, 1).toUpperCase()) continue;
        if (Array.isArray(a[i])) {
          if (!b) b = [];
          let c = this.compare(a[i], b[i], i)
          if (Object.keys(c).length>0) {
            res[i] = c;
          }
        } else if (typeof a[i] === 'object') {
          if (!b) b = {};
          let c = this.compare(a[i], b[i], i)
          if (Object.keys(c).length>0) {
            res[i] = c;
          }
        } else {
          if (a && b && a[i] != b[i]) {
            res[i] = [a[i], b[i]]
          }
          if (a && !b) {
            res[i] = [a[i], b]
          }
        }
      }
      return res;
    }

    removeFieldByName (array, name) {
        let index = _.findIndex(array, (r) => {
            return r && r.name == name;
        });
        if (index > -1) delete array.splice(index, 1);
    }

    getPrice (cost, markup) {
        return cost * (1 + markup / 100.0);
    }

    getCost (price, markup) {
        return price / ((markup / 100) + 1);
    }

    getMarkup (price, cost) {
        return ((price / cost) - 1) * 100.0;
    }

    isNull (v) {
        return v===null || v===undefined || v==='' || Number.isNaN(v);
    }

    round (v, d) {
        let f = Math.pow(10, d);
        return Math.round(v * f) / f;
    }

}

window.tools = new Tools();
