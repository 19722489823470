<template>
    <div class="date date-picker form-group form-default" :id="'date-picker' + getId" v-if="show">
        <input type="text" class="form-control" v-model="value" :id="'date-picker-input'+id" :readonly="readonly"
        :disabled="disabled" @change="setDate" autocomplete="off" :class="classes">
            <span class="form-bar">
            </span>
            <label class="float-label" :class="labelClass" v-if="label">{{tr(label)}}</label>
        </input>
        <span class="input-group-addon">
            <i class="glyphicon glyphicon-calendar"></i>
        </span>
    </div>
</template>

<script>
export default {
    name: 'date-picker',
    props: ['def', 'id', 'disabled', 'readonly', 'disablePast', 'addClass', 'label', 'labelClass', 'record',
        'startDate', 'fieldName'],
    data () {
      return {
          value: null,
          show: true,
      };
    },
    computed: {
        getId () {
            if (!this.id) return this._uid;
            return this.id;
        },
        classes () {
            let r = [];
            if (this.value) r.push('fill');
            if (this.addClass) r.push(this.addClass);
            return r.join(' ');
        }
    },
    mounted () {
        if (this.readonly) return;
        if (this.def) this.value = moment(this.def, "YYYY-MM-DD").format("DD/MM/YYYY");
        this.onMount();
    },
    watch: {
        def () {
            if (this.def)  {
                this.value = moment(this.def, "YYYY-MM-DD").format("DD/MM/YYYY");
            } else {
                this.value = null;
            }
        },
        record: {
            handler () {
                if (this.fieldName != 'ToDate' && this.fieldName != 'EndDate') return;
                let date = this.getStartDate();
                let dp = $('#date-picker' + this.getId);
                dp.datepicker( "option", 'startDate', date );
                this.show = false;
                setTimeout(() => {
                    this.show = true;
                    this.onMount();
                }, 1)
            },
            deep: true,
        },
        startDate () {
            let date = this.getStartDate();
            let dp = $('#date-picker' + this.getId);
            dp.datepicker( "option", 'startDate', date );
            this.show = false;
            setTimeout(() => {
                this.show = true;
                this.onMount();
            }, 1)
        },
        readonly () {
            this.onMount();
        }
    },
    methods: {
        onMount () {
            let self = this;
            this.$nextTick(() => {
                let date = self.getStartDate();
                date.setDate(date.getDate());
                $.fn.datepicker.dates['en'] = {
                    days: [tr("Sunday"), tr("Monday"), tr("Tuesday"), tr("Wednesday"), tr("Thursday"), tr("Friday"),
                        tr("Saturday"), tr("Sunday")],
                    daysShort: [tr("Sun"), tr("Mon"), tr("Tue"), tr("Wed"), tr("Thu"), tr("Fri"), tr("Sat"), tr("Sun")],
                    daysMin: [tr("Su"), tr("Mo"), tr("Tu"), tr("We"), tr("Th"), tr("Fr"), tr("Sa"), tr("Su")],
                    months: [tr("January"), tr("February"), tr("March"), tr("April"), tr("May"), tr("June"), tr("July"),
                        tr("August"), tr("September"), tr("October"), tr("November"), tr("December")],
                    monthsShort: [tr("Jan"), tr("Feb"), tr("Mar"), tr("Apr"), tr("_May"), tr("Jun"), tr("Jul"), tr("Aug"),
                        tr("Sep"), tr("Oct"), tr("Nov"), tr("Dec")],
                    today: tr("Today"),
                    clear: tr("Clear"),
                    titleFormat: "MM yyyy"
                };
                $('#date-picker' + self.id).datepicker({
                    format: 'dd/mm/yyyy',
                    todayHighlight: true,
                    autoclose: true,
                    startDate: date,
                    language: "en",
                }).on(
                    'changeDate', () => {
                    self.value = $('#date-picker-input' + self.id).val();
                    let newValue  = moment(self.value, "DD-MM-YYYY").format("YYYY-MM-DD");
                    self.$emit('update:def', newValue);
                    self.$emit('change');
                  }
                ).on('show', () => {
                    if ($('.datepicker').length>0) {
                        var topd = parseInt($('.datepicker').css('top').replace('px', ''));
                        topd += 80;
                        $('.datepicker').css('top', topd);
                    }
                });
            })

        },
        setDate () {
            let d = moment(this.value, "DD-MM-YYYY").format("YYYY-MM-DD");
            if (d=='Invalid date') {
                d = null;
                this.value = null;
            };
            this.$emit('update:def', d);
            this.$emit('change');
        },
        getStartDate () {
            let date;
            if (this.disablePast) {
                date = new Date();
            } else if (this.startDate) {
                date = new Date(moment(this.startDate).add(1 ,'days').format("YYYY-MM-DD"));
            } else if (this.record && this.record.FromDate && this.fieldName == 'ToDate') {
                date = new Date(moment(this.record.FromDate).add(1 ,'days').format("YYYY-MM-DD"));
            } else if (this.record && this.record.StartDate && this.fieldName == 'EndDate') {
                date = new Date(moment(this.record.StartDate).add(1 ,'days').format("YYYY-MM-DD"));
            } else {
                date = new Date('1900-01-01');
            }
            return date;
        }
    },
}
</script>
