<template>
    <div v-if="user">
        <report-window
            v-if="ready"
            endpoint="/reports/user_activity"
            :perPage="500"
            :fields="fields"
            :title="title"
            :headers="headers"
            :current.sync="current"
            :columnClick="columnClick"
            :templates="templates"
            :slotsFields="slotsFields"
            :updateReport="updateReport"
        ></report-window>
    </div>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import { mapState } from "vuex";
export default {
    name: "user-activity",
    props: [],
    components: {
        ReportWindow,
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
        }),
    },
    async mounted () {
        frontTools.setReportValues(this);
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data () {
        let self = this;
        return {
            current: {},
            updateReport: false,
            ready: false,
            fields: [
                {name: 'FromDate', editor: 'date', label: 'From', required: true},
                {name: 'ToDate', editor: 'date', label: 'To', required: true},
                {name: 'UserId', label: 'User', editor: 'vue-select', addBlank: true,
                    optionLabels: 'UserName', relation: 'user',
                },
            ],
            title: "User Activity",
            headers: {
                UserName: "User",
                Date: "Date",
                Time: "Time",
            },
            templates: [
                {name: 'Date', callback: (row) => {
                        if (!row.Date) return '';
                        return moment(row.Date).format("DD MMM");
                    }
                },
                {name: 'FromTime', callback: (row) => {
                        if (!row.FromTime) return '';
                        return moment(row.FromTime).format("HH:mm");
                    }
                },
                {name: 'ToTime', callback: (row) => {
                        if (!row.ToTime) return '';
                        return moment(row.ToTime).format("HH:mm");
                    }
                },
                {name: 'Time', callback: (row) => {
                        if (!row.Time) return '';
                        let hours = parseInt(row.Time / 60);
                        let minutes = parseInt(row.Time % 60);
                        if (hours > 0 ) {
                            return hours + ' hours, ' + minutes + ' minutes';
                        } else {
                            return minutes + ' minutes';
                        }
                    }
                },
            ],
            columnClick: {},
            slotsFields: [],
        };
    },

};
</script>
