<template>
    <li><a class="cursor-pointer" @click="redirect" :class="addClass" :href="vLink.href">
        <font-awesome-icon :icon="icon" :class="iconClass" v-if="icon"/>
        {{tr(label)}}
    </a></li>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'vue-menu-item',
    props: ['endpoint', 'label', 'accessGroup', 'tableName', 'callback', 'route', 'report', 'componentName', 'module',
        'icon', 'iconClass', 'addClass'],
    data () {
        return {
            access: false,
            ready: false,
            component: null,
        };
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
        vLink () {
            return vueTools.resolve(this.$router, this.endpoint, this.tableName, this.report, this.module, this.componentName);
        }
    },
    async mounted () {
        if (this.accessGroup){
            this.access = tools.canAccess(this.user, 'view','GET', this.accessGroup)
        }else{
            this.access = tools.canAccess(this.user, 'view','GET', '/' + this.endpoint)
        }
    },
    methods: {
        async redirect (event) {
            event.preventDefault();
            localStorage.setItem('reportFilters', '');
            let body = $("html, body");
            body.stop().animate({scrollTop:0}, 500, 'swing', function() {});
            if (this.callback) {
                this.callback(this);
                return;
            }
            console.log(this.endpoint, this.tableName, this.report, this.module, this.componentName)
            vueTools.redirect(this.$router, this.endpoint, this.tableName, this.report, this.module, this.componentName)
        }
    },
}

</script>


