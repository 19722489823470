import View from '@/base/views/view';

class AccessGroupView extends View {

    static table = 'accessgroup';

    static getTableOptions () {
        return {
            fields: [
                { name: 'id', label: 'id'},
                { name: 'Description', label: 'Description'},
                { name: 'Permissions'},
                { name: 'permissions', fieldName: 'Permissions', editor: 'component', component: 'json-editor', label: 'Permissions'},

            ],
            title: 'Access Group',
            canAdd: true,
        }
    }

}

export default AccessGroupView;
