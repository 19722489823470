//const uuidv1 = require('uuid');
import { v1 as uuidv1 } from 'uuid';

class BaseModel {

    constructor ({fields, record, parent, tableName, parentField, rowNr, clone}) {
        let self = this;
        return (async () => {
            if (parent) self.setParent(parent);
            if (parentField) self.$parentField = parentField;
            if (!parent && clone) self._isClone = true;
            if (rowNr) self._rowNr = rowNr;
            self._id = uuidv1();
            //self._cache = {};
            self.fields = fields;
            if (!self.fields && self.constructor.moduleName) {
                self.fields = await this.constructor.getFields();
            }
            if (!self.fields) {
                self.serverFields = await api.getTableFields(tableName);
                self.fields = tools.serverTypes(self.serverFields)
            }
            self.invalid = {};
            await self.initFieldProperties();
            self.tableSpec = {};
            if (!clone) {
                if (record) {
                    await self.init(record);
                } else {
                    await self.create();
                }
            }
            self.getters = {}
            self.fieldsObject = self.getFieldsObject(self.fields);
            self._computedGetters = {};
            self.initComputedGetters();
            self.setComputedGetters();
            self._requestCount = 0;
            self._changeNr = 0;
            return self;
        })();
    }

    getFieldsObject (fields) {
        let res = {};
        for (let field of fields) {
            res[field.name] = field;
        }
        return res;
    }

    get tabFields () {
        let res = [];
        let tabs = _.filter(this.fields, (r) => {
            return r && r.editor == 'tab';
        });
        for (let tab of tabs) {
            if (!tab.fields) continue;
            for (let field of tab.fields) {
                res.push(field);
            }
        }
        return res;
    }

    static async getFields () {
        let options = await api.getModuleFieldsOptions(this.moduleName);
        return options.fields;
    }

    get abmFields () {
        let fields = _.filter(this.fields, (f) => {
            if (f.hideCondition) {
                if (f.hideCondition(this)) {
                    return false;
                }
            }
            return true;
        })
        return tools.getAbmFields(fields);
    }

    get headerFields () {
        let res = [];
        for (let f of this.fields) {
            if (f.editor=='tab') continue;
            if (f.editor=='button') continue;
            if (f.editor=='component') continue;
            if (typeof f.editor === 'object') continue;
            if (Array.isArray(f.editor)) continue;
            res.push(f.name);
        }
        return res;
    }

    get fieldsList () {
        return Object.assign([], this.fields).concat(this.tabFields);
    }

    async initFieldProperties () {
        let self = this;
        for (let field of this.fieldsList) {
            if (!field.name) continue;
            if (Array.isArray(field.editor) || field.rowFields) {
                this['$' + field.name] = {getters: {}, value: []};
                Object.defineProperty(this, field.name, {
                    enumerable : true,
                    configurable : true,
                    set: (value) => {
                        this['$' + field.name].value = [];
                    },
                    get: () => {
                        this.checkCache(field.name)
                        return this['$' + field.name].value;
                    }
                });

            } else if (typeof field.editor == 'object') {
                this[field.name] = await this.newClassObject(field);
            } else {
                this['$' + field.name] = {value: null, getters: {}};
                this.invalid[field.name] = false;
                Object.defineProperty(this, field.name, {
                    enumerable : true,
                    configurable : true,
                    set: (value) => {
                        this.setValue({fieldName: field.name, value});
                    },
                    get: () => {
                        this.checkCache(field.name)
                        return this['$' + field.name].value;
                    }
                });
                if (field.setRelationTo) {
                    this[field.setRelationTo] = {};
                }
            }
        }
    }

    checkCache (fieldName) {
        for (let _id in _cache.objects) {
            let obj = _cache.objects[_id];
            if (!_cache.objects[_id]) continue;
            for (let getter in obj) {
                if (fieldName && getter == fieldName) continue
                if (obj[getter].status) {
                    if (fieldName) {
                        if (!this['$' + fieldName].getters[getter]) this['$' + fieldName].getters[getter] = [];
                        if (this['$' + fieldName].getters[getter].indexOf(_id)==-1) {
                            this['$' + fieldName].getters[getter].push(_id)
                        }
                    }
                    if (!this.getters[getter]) this.getters[getter] = [];
                    if (this.getters[getter].indexOf(_id)==-1) {
                        this.getters[getter].push(_id)
                    }
                }
            }
        }
    }

    updateGetters (fieldName) {
        if (fieldName) {
            if (!this['$' + fieldName]) {
                this['$' + fieldName] = {getters: {}}
            };
            if (!this['$' + fieldName].getters) {
                this['$' + fieldName].getters = {};
            };
            for (let getter in this['$' + fieldName].getters) {
                for (let _id of this['$' + fieldName].getters[getter]) {
                    if (!_cache.objects[_id]) continue;
                    _cache.objects[_id][getter].update = true;
                }
            }
        } else {
            if (!this.getters) return;
            for (let getter in this.getters) {
                for (let _id of this.getters[getter]) {
                    if (!_cache.objects[_id]) continue;
                    _cache.objects[_id][getter].update = true;
                }
            }
        }
    }

    async setValue ({fieldName, value}) {
        let oldVal = this['$' + fieldName].value;
        this['$' + fieldName].value = value;
        let field = _.find(this.fieldsList, (c) => c.name == fieldName);
        if (value !== oldVal && !this._clone) {
            await this.afterEditField(field, value, oldVal);
        }
    }

    get _loaded () {
        if (this.$parent) {
            return this.$parent._loaded;
        } else {
            if (this._recordLoaded != undefined) return this._recordLoaded;
        }
    }

    get _clone () {
        if (this.$parent) {
            return this.$parent._clone;
        } else {
            return this._isClone;
        }
    }

    afterEdit () {
        return {}
    }

    async afterEditField (field, newVal, oldVal) {
        if (this._loaded) {
            this.updateGetters(field.name)
            let newValNull = newVal===null || newVal===undefined || newVal==='';
            let oldValNull = oldVal===null || oldVal===undefined || oldVal==='';
            if (!newValNull || !oldValNull) {
                await this.edit(field);
                if (field.afterEdit) {
                    await field.afterEdit(this, newVal, oldVal);
                }
                if (this.afterEdit && this.afterEdit()[field.name]) {
                    let f = this.afterEdit()[field.name];
                    await f(this, newVal, oldVal);
                }
            }
            this.setComputedFields();
            this.setComputedGetters(field.name);
            this.propagateChanges();
        }
    }

    propagateChanges () {
        if (this.hasOwnProperty('$parent')) {
            this.$parent.propagateChanges();
        } else {
            this._changeNr += 1;
        }
    }

    async afterEditRow (field) {
        if (this._loaded) {
            this.updateGetters(field.name)
            await this.edit(field);
            if (field.afterEdit) {
                await field.afterEdit(this);
            }
            this.setComputedFields();
            this.setComputedGetters(null, true);
        }
    }

    async addRow ({fieldName, values}){
        let field = _.find(this.fieldsList, (c) => c.name == fieldName);
        let childRow = await this.newRow(field);
        await childRow.create();
        if (values) {
            for (let f of childRow.fieldsList) {
                  if (values[f.name]) {
                      if (typeof f.editor == 'object') {
                          let objChild = childRow[f.name];
                          if (objChild) {
                              if  (objChild.setFields) objChild.setFields();
                              for (let objField of objChild.fields) {
                                  if (values[f.name][objField.name]) {
                                    if (typeof objField.editor == 'object') {
                                        let objectClass = api.importMixinModule(objField.objectClass, 'model');
                                        objChild[objField.name] = await new objectClass({record: values[f.name][objField.name], parent: this});
                                    } else {
                                        objChild[objField.name] = values[f.name][objField.name];
                                    }
                                  }
                              }
                          }
                      } else {
                          childRow[f.name] = values[f.name];
                      }
                  }
            }
        }
        let name = field.fieldName? field.fieldName: field.name;
        this[name].push(childRow);
        childRow._rowNr = this[name].length - 1;
        await this.afterEditRow(field);
        return childRow;
    }

    async removeRow ({fieldName, rowNr}) {
        this['$' + fieldName].value.splice(rowNr, 1);
        let field = _.find(this.fieldsList, (c) => c.name == fieldName);
        await this.afterEditRow(field);
    }

    async closeRow ({fieldName, rowNr}) {
        await this[fieldName][rowNr].setValue({fieldName: 'Closed', value: true})
        let field = _.find(this.fieldsList, (c) => c.name == fieldName);
        await this.afterEditField(field, false, true);
    }

    setParent (obj) {
        Object.defineProperty(this, '$parent', {enumerable: false, value: obj, configurable: true});
    }

    async newObjectByName (fieldName, obj) {
        let field = _.find(this.fieldsList, (c) => c.name == fieldName);
        if (field) {
            let r = await this.newClassObject(field, obj);
            return r;
        }
        //r._loaded = true;
    }

    async newObject (field, obj, fieldName) {
        if (this._loaded) this.updateGetters(fieldName)
        let r = await this.newClassObject(field, obj);
        if (fieldName || fieldName==0) this[fieldName] = r;
        this.setComputedFields();
        this.setComputedGetters(null, true);
        return r;
    }

    async newClassObject (field, obj, copy) {
        if (field.objectClass) {
            let objectClass = api.importMixinModule(field.objectClass, 'model');
            let r;
            if (copy) {
                r = await objectClass.clone({fields: obj? obj.fields: null, record: obj, parent: this});
            } else {
                r = await new objectClass({record: obj, parent: this});
            }
            return r;
        }
        let r;
        if (copy) {
            r = await BaseModel.clone({fields: field.fields, record: obj, parent: this});
        } else {
            r = await new BaseModel({fields: field.fields, record: obj, parent: this});
        }
        return r;
    }

    async newRowByName (fieldName, obj) {
        let field = _.find(this.fieldsList, (c) => c.name == fieldName);
        if (field) {
            let r = await this.newRow(field, obj);
            r.id = null;
            return r;
        }
        //r._loaded = true;
    }


    async newRow (field, record, fieldName) {
        if (this._loaded) this.updateGetters(field.name)
        let r = await this.newClassRow(field, record);
        if (fieldName || fieldName==0) {
            this[fieldName] = r;
        }
        await this.afterEditRow(field);
        //r._loaded = true;
        return r;
    }

    async newClassRow (field, record, copy) {
        if (field.rowClass) {
            let rowClass = api.importMixinModule(field.rowClass, 'model');
            let r;
            if (copy) {
                r = await rowClass.clone({fields: record.fields, record, parent: this, parentField: field.name});
            } else {
                r = await new rowClass({record, parent: this, parentField: field.name});
            }
            return r;
        }
        let r;
        if (copy) {
            r = await BaseModel.clone({fields: field.rowFields, record, parent: this, parentField: field.name});
        } else {
            r = await new BaseModel({fields: field.rowFields, record, parent: this, parentField: field.name
                , tableName: field.tableName});
        }
        //this.setParent(r);
        return r;
    }

    async init (record) {
        let arrayFields = record.fieldsList? record.fieldsList: record.fields;
        if (!arrayFields) arrayFields = record;
        for (let i in arrayFields) {
            let fieldName = i;
            if (record.fields) fieldName = arrayFields[i].name;
            let field = _.find(this.fieldsList, (f) => f.name == fieldName);
            if (field && (Array.isArray(field.editor) || field.rowFields)){
                this[field.name] = [];
                for (let row of record[field.name]) {
                    let childRow = await this.newClassRow(field, row);
                    this[field.name].push(childRow)
                    childRow._rowNr = this[field.name].length - 1;
                }
            } else if (field && field.editor && typeof field.editor == 'object') {
                let o = await this.newClassObject(field, record[fieldName]);
                this[fieldName] = o;
            } else {
                if (field && this._loaded && field.setRelationTo) {
                    await this.setValue({fieldName, value: record[fieldName]});
                } else {
                    try {
                        this[fieldName] = record[fieldName];
                    } catch(ex) {
                        console.log('err fieldName', fieldName)
                    }

                }
                if (field && field.setRelationTo && record[field.setRelationTo]) {
                    let empty = Object.keys(record[field.setRelationTo]).length == 0;
                    if (empty && record[fieldName]) {
                        await this.setRelationTo(field)
                    }
                }
            }
        }
        //this._loaded = true;
        //this.setModified(false);
        this.setComputedFields();
        //this.setComputedGetters();
    }

    static async clone ({fields, record, parent}) {
        if (!record) return;
        let r = await new this({fields: record.fields, parent, record, clone: true});
        r.id = null;
        for (let field of r.fields) {
            if (field.name == 'id') continue
            let fieldName = field.name;
            if (field && (Array.isArray(field.editor) || field.rowFields)){
                r[field.name] = [];
                for (let row of record[field.name]) {
                    let childRow = await r.newClassRow(field, row, true);
                    r[field.name].push(childRow)
                    childRow._rowNr = r[field.name].length - 1;
                }
            } else if (field && typeof field.editor == 'object') {
                let o = await r.newClassObject(field, record[fieldName], true);
                r[fieldName] = o;
            } else {
                r['$' + fieldName].value = record[fieldName];
                if (field.setRelationTo) {
                    r[field.setRelationTo] = Object.assign({}, record[field.setRelationTo]);
                }
            }
        }
        if (r.CreatedUTC) r.CreatedUTC = null;
        if (r.ModifiedUTC) r.ModifiedUTC = null;
        if (r.CreatedUserId) r.CreatedUserId = null;
        if (r.ModifiedUserId) r.ModifiedUserId = null;
        r.setComputedFields();
        r.setComputedGetters(null, true);
        return r;
    }

    async create () {
        this._recordLoaded = true;
        for (let f of this.fieldsList) {
            if (f.defValue) {
                let value = f.defValue;
                if (typeof f.defValue === 'function') {
                    value = f.defValue(this);
                }
                await this.setValue({fieldName: f.name, value});
            }
        }
        this.setComputedFields();
        this.setComputedGetters(null, true);
    }

    checkFields () {
        return tools.checkFields(this, this);
    }

    get arrayFields () {
        let res = [];
        for (let f of this.fieldsList) {
            if (f.hidden) continue;
            if (Array.isArray(f.editor)){
                let show = true;
                if (f.showIf && !f.showIf(this)) {
                    show = false;
                }
                if (f.hideIf && f.hideIf(this)) {
                    show = false;
                }
                if (show) res.push(f);
            }
        }
        return res;
    }

    get tabList () {
        let res = [];
        for (let f of this.fields) {
            if (f.editor=='tab'){
                res.push(f);
            }
        }
        return res;
    }

    async setRelationTo (field) {
        if (!field.relation) return;
        if (!field.setRelationTo) return;
        if (!this[field.name]) {
            this[field.setRelationTo] = {};
            return;
        }
        let res = await api.getObjectFromStore(field.relation, this[field.name]);
        if (res) {
            this[field.setRelationTo] = res;
        }
    }

    getMaxLength (fieldName) {
        if (this.fields[fieldName] && this.fields[fieldName].length) {
            return this.fields[fieldName].length;
        }
    }

    setComputedFields (list) {
        let res = list;
        if (!list) res = [];
        for (let field of this.fieldsList) {
            if (!field) {
                let data = [{
                    constructor: this.constructor.name,
                    field: field,
                    fieldsList: this.fieldsList
                }]
                api.post('/api/send_logs/', JSON.stringify(data));
            }
            if (field.computed) {
                let value = field.computed(this, this.$parent);
                if (value!=null && value!=undefined) {
                    if (!this['$' + field.name]) continue;
                    this['$' + field.name].value = value;
                    let params = [];
                    if (this.$parentField) params.push(this.$parentField);
                    if (this._rowNr != undefined) params.push(this._rowNr);
                    params.push(field.name)
                    res.push({
                        params: params,
                        value: value,
                        action: 'set'
                    })
                }
            }
        }
        if (this.hasOwnProperty('$parent')) {
            this.$parent.setComputedFields(res);
        }
        return res;
    }

    getPropertiesNames (proto, values) {
        if (proto.constructor.name == 'Object') {
            return values;
        } else {
            let v = values;
            if (!values) v = [];
            const names = Object.getOwnPropertyNames (proto);
            v.push(...names);
            return this.getPropertiesNames(Object.getPrototypeOf (proto), v);
        }
        return [];
    }

    initComputedGetters () {
        const names = this.getPropertiesNames(this);
        const getters = names.filter (name => name.substring(0, 3)=='$$_');
        for (let getterName of getters) {
            let name = getterName.replace('$$_', '');
            this._computedGetters[name] = getterName;
            Object.defineProperty(this, name, {
                enumerable : true,
                configurable : true,
                set: (value) => {
                    this.setValue({fieldName: field.name, value});
                },
                get: () => {
                    this.checkCacheFromGetters(name);
                    if (!this['$' + name]) this['$' + name] = {value: null, getters: []};
                    if (_cache.objects[this._id][name].update) {
                        _cache.objects[this._id][name].status = true;
                        let r = this[getterName]
                        this['$' + name].value = r;
                        this.updateGetters(name)
                        _cache.objects[this._id][name].update = false;
                        _cache.objects[this._id][name].status = false;
                    }
                    return this['$' + name].value;
                }
            });
        }
    }

    forceGetter (name) {
        console.log('forceGetter', name)
        if (!this['$' + name]) this['$' + name] = {value: null, getters: []};
        let value = this['$$_' + name];
        this['$' + name].value = value;
    }

    checkCacheFromGetters (name) {
        for (let _id in _cache.objects) {
            if (!_cache.objects[_id]) continue;
            let obj = _cache.objects[_id];
            for (let getter in obj) {
                if (getter == name) continue
                if (obj[getter].status) {
                    if (!this['$' + name]) this['$' + name] = {value: null, getters: []}
                    if (!this['$' + name].getters[getter]) this['$' + name].getters[getter] = [];
                    if (this['$' + name].getters[getter].indexOf(_id)==-1) {
                        this['$' + name].getters[getter].push(_id)
                    }
                }
            }
        }
    }

    getComputedGetter ({name, getterName}) {
        if (!_cache.objects[this._id]) {
            _cache.objects[this._id] = {};
        }
        if (!_cache.objects[this._id][name]) {
            _cache.objects[this._id][name] = {status: false, update: true};
        }
        _cache.objects[this._id][name].update = true;
    }


    setComputedGetters (fieldName, updateParent) {
        for (let name in this._computedGetters) {
            if (fieldName && name == fieldName) continue;
            let getterName = this._computedGetters[name];
            this.getComputedGetter({name, getterName})
        }
        if ((fieldName || updateParent) && this.hasOwnProperty('$parent')) {
            this.$parent.setComputedGetters(null, true);
        }
    }

    setModified (value) {
        if (this.hasOwnProperty('_modified')) {
            if (this._modified != value) {
                this._modified = value;
                //api.setModified(value);
            }
        } else if (this.hasOwnProperty('$parent')) {
            this.$parent.setModified(value);
        }
    }

    get isNew () {
        if (this.hasOwnProperty('_new')) {
            return this._new;
        } else if (this.hasOwnProperty('$parent')) {
            return this.$parent.isNew;
        }
    }

    async edit (field) {
        this.setModified(true);
        if (field && field.relation && field.setRelationTo) {
            await this.setRelationTo(field);
        }
    }

    get getName () {
        if (this.Name) return this.Name;
    }

    getFieldLabel (fieldName) {
        let field = _.find(this.fields, (f) => f.name == fieldName);
        if (field && field.label) return field.label;
        if (field) return field.name;
        let tabs = _.filter(this.fields, (f) => f.editor == 'tab');
        if (tabs) {
            for (let tab of tabs) {
                let field = _.find(tab.fields, (f) => f.name == fieldName);
                if (field && field.label) return field.label;
                if (field) return field.name;
            }
        }
        return fieldName;
    }

}

export default BaseModel;
