<template>
    <div class="form-group form-default">
        <input
            type="text"
            class="form-control text-right"
            :class="classes"
            v-model="value"
            @change="change"
            @blur="blur"
            @focus="focusOn"
            :disabled="disabled"
            :style="style"
            >
                <span class="form-bar">
                </span>
                <label v-if="!hideLabel" class="float-label" :class="labelClass">{{tr(label)}}</label>
        </input>
    </div>
</template>

<script>
export default {
    name: 'number-input',
    props: ['v', 'd', 'disabled', 'label', 'hideLabel', 'addClass', 'labelClass', 'currency', 'changeStyle'],
    data () {
        return {
            value: '',
            decimal: 0,
            lastValue: null,
        }
    },
    computed: {
        classes () {
            let r = {};
            if (typeof this.addClass == 'object') {
                r = this.addClass;
            } else {
                if (this.addClass) r[this.addClass] = true;
            }
            r.fill = !!this.v || this.v === 0;
            r.disabled = this.disabled;
            return r;
        },
        style () {
            if (!this.changeStyle) return '';
            let res = [];
            if (this.currency && this.currency.FontSize) {
                res.push(`font-size: ${this.currency.FontSize}px`);
            }
            return res.join(';');
        },
    },
    mounted () {
        this.setDecimals();
        if (this.v === null || this.v === undefined || this.v === '') {
            this.lastValue = this.v;
            return;
        }
        this.value = this.format(this.v);
        this.lastValue = this.value;
    },
    watch: {
        v () {
            if (this.v) {
                this.value = this.format(this.v);
            } else {
                this.value = this.v;
            }
            this.lastValue = this.value;
        },
        currency: {
            handler () {
                this.setDecimals();
            },
            deep: true,
        },
    },
    methods: {
        blur () {
        },
        setDecimals (v) {
            if (this.currency && this.currency.Decimals != null) this.decimal = this.currency.Decimals;
            if (this.d != null && this.d != undefined) this.decimal = this.d;
            if (v && parseFloat(v) > 999 && this.decimal > 3) this.decimal = 2;
        },
        change () {
            if (!this.value && this.value!=0) this.value = '';
            if (Number.isNaN(this.value)) this.value = '';
            let v = this.value;
            if (v) {
                v = v.replace(/,/g, '');
                v = parseFloat(v);
            }
            if (Number.isNaN(v)) v = '';
            if (v !== this.lastValue) {
                this.$emit('update:v', v)
                this.$emit('change', v);
                this.lastValue == v;
            }
            if (v) this.value = v.toFixed(this.decimal).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        focusOn () {
            this.$emit('focus', this.value);
        },
        format (value) {
            if (Number.isNaN(value)) return '';
            let v = value;
            this.setDecimals(v);
            if (typeof value === 'string') {
                v = parseFloat(value).toFixed(this.decimal);
            }
            if (typeof value === 'number') {
                v = value.toFixed(this.decimal);
            }
            if (parseFloat(v) > 999) {
                return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            return v.toString();
        }
    }
}
</script>
