<template>
    <div>
        <nav class="navbar navbar-expand-md navbar-dark fixed-top py-0">
            <div class="toggle-sidebar">
                <font-awesome-icon icon="bars" @click="toggle" class="mr-3"/>
                <span></span>
            </div>
            <img src="@/img/LOGO_NUBBO.png" width="150" class="cursor-pointer" @click="setPath({path: '/'})">
            <div class="collapse navbar-collapse ml-3" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto">
                    <li class="d-flex align-items-center">
                        <timer ref="mainTimer" v-if="!customerId"></timer>
                    </li>
                    <li class="nav-item dropdown no-arrow" >
                        <search-box class="mt-2" v-model="search" @focusOn="focusOn"></search-box>
                        <div class="dropdown-menu dropdown-menu-right show" aria-labelledby="navbarSearch" v-if="searchList.length || paxList.length">
                            <a v-for="(n, idx) in searchList" class="dropdown-item" @click="setRoute(n)">
                              <b>{{tr(n.label)}}</b>
                            </a>
                            <a v-for="(n, idx) in paxList" class="dropdown-item" @click="setRoute(n)">
                              <b>{{tr(n.label)}}</b>
                            </a>
                        </div>
                    </li>

                    <li class="nav-item dropdown no-arrow">
                        <a v-if="user" class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="mr-2 d-none d-lg-inline"> {{ user.UserName }} </span>
                            <img class="img-profile rounded-circle" :src="user.Image.url" v-if="user.ImageId">
                        </a>
                        <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                            <a class="dropdown-item cursor-pointer" @click="showUserProfile">
                                <font-awesome-icon icon="user" class="fa-sm fa-fw mr-2 text-gray-400"/>
                                {{tr('Profile')}}
                            </a>
                            <menu-item
                                class="dropdown-item"
                                icon="key"
                                iconClass="fa-sm fa-fw mr-2 text-gray-400"
                                v-if="user"
                                endpoint="change-password"
                                label="Change Password"
                            ></menu-item>
                            <a class="dropdown-item cursor-pointer" @click="openChangeLog">
                                <svg-change-log class="service-actions"></svg-change-log>
                                {{tr('Change Log')}}
                            </a>
                            <a class="dropdown-item cursor-pointer" @click="showTimerList" v-if="!customerId">
                                <font-awesome-icon icon="clock" class="fa-sm fa-fw mr-2 text-gray-400"/>
                                {{tr('Timer List')}}
                            </a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item cursor-pointer" @click="logout">
                                <font-awesome-icon icon="sign-out-alt" class="fa-sm fa-fw mr-2 text-gray-400"/>
                                {{tr('Sign out')}}
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
        <app-news-modal
            v-if="showAppNews"
            :showAppNews.sync="showAppNews"
            :news="news"
        ></app-news-modal>
        <select-value
            v-if="selectActivitiesView"
            @save="setActivitiesView"
            :show.sync="selectActivitiesView"
            text="Select Activities View"
            :fieldOptions="activitiesOptions"
            :defValue="defActivitiesView"
        ></select-value>
        <support
            v-if="showSendLogs"
            :show.sync="showSendLogs"
        ></support>
        <change-log ref="changeLog"></change-log>
    </div>
</template>

<script>
import '@/css/app.scss';
const menuItem  = importVueComp('components/tools', 'VueMenuItem');
const support  = importVueComp('components/tools', 'Support');
import { mapState } from "vuex";
import appNewsModal from '@/components/tools/AppNewsModal';
var EventBus = require('@/tools/event-bus').default;
import modules from '@/tools/modules';
import svgChangeLog from '@/components/svg/SvgChangelog';
const changeLog  = importVueComp('components/changelog', 'ChangeLog');
import commitFront from '@/commit.txt';

export default {
    name: 'nav-bar',
    components: {
        'menu-item': menuItem,
        'app-news-modal': appNewsModal,
        'support': support,
        'change-log': changeLog,
        'svg-change-log': svgChangeLog,
    },
    data () {
        return {
            history: [],
            marks: [],
            selectLanguage: false,
            commitFront: commitFront,
            selectActivitiesView: false,
            activitiesOptions: [
                {label: 'Expanded', value: 'EXPANDED'},
                {label: 'Compress', value: 'COMPRESS'},
            ],
            defLanguage: null,
            defActivitiesView: null,
            isMarked: false,
            search: '',
            searchList: [],
            paxList: [],
            showAppNews: false,
            news: [],
            apiNews: null,
            newsTitle: null,
            newNotifications: 0,
            showSendLogs: false,
            updated: true,
        };
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
        customerId () {
            return api.currentUser.CustomerId;
        },
    },
    async mounted () {
        EventBus.$on('check-marked', this.checkMarked);
        EventBus.$on('update-history', this.getHistoryRoutes);
        EventBus.$on('update-profile', this.updateUserProfile);
        this.getHistoryRoutes();
        this.getMarksRoutes();
        this.defActivitiesView = localStorage.getItem('activities-view');
        this.checkMarked();
        this.appNews();
        this.sendCommit();
        setInterval(() => {
            if (this.updated) {
                this.sendCommit()
            }
        },1000*60*10); //10 minutes
    },
    methods: {
        sendLogs () {
            this.showSendLogs = true;
        },
        openChangeLog () {
            this.$refs.changeLog.open()
        },
        async sendCommit () {
            try {
                let commitText = commitFront.split('\n')[0].replace(/\t/g, "")
                let commitList = _.filter(commitText.split(' '),(r) => {
                    return r && r.length > 0;
                })
                let Code = commitList[1]
                if (Code.length < 7) return;
                let commitList2 = commitText.split(Code)
                let CommitDate = moment(commitList2[1].replace(/ /g, '')).format('YYYY-MM-DDTHH:mm:ss')
                var err = false;
                let res = await api.post('/api/save_commit/',JSON.stringify({Code, CommitDate}), null, () => {
                    err = true;
                });
                if (!res && !err) {
                    this.$root.$children[0].appWarning = tr("There is a new update, please save changes and reload the site");
                    this.updated = false;
                }
            } catch(err) {
                console.log(err)
            }
        },
        appNews () {
            let storedNews = localStorage.getItem('app-news');
            if (storedNews) {
                let r = JSON.parse(storedNews);
                for (let key in r) {
                    if (!r[key] && this.apiNews[key]) {
                        for (let n of this.apiNews[key]) {
                            this.news.push(n);
                        }
                        r[key] = true;
                    }
                }
                localStorage.setItem('app-news', JSON.stringify(r));
                if (this.news.length>0) {
                    this.showAppNews = true;
                }
            }
        },
        logout () {
            this.$router.push("/logout");
        },
        async showUserProfile () {
            let syncVersion = await api.get('/api/get_sync_version/user/' + this.user.id);
            if (syncVersion && syncVersion!=this.user.syncVersion) {
                let user = await api.getUser();
                if (user) {
                    this.$store.commit('setUser', user);
                }
            }
            this.$root.$children[0].openModalRecord({modalRecord: null, modalTable: 'user', view: 'userprofile', modalId: this.user.id, closeAction: 'update-profile'});
        },
        getHistoryRoutes () {
            let routerHistory = localStorage.getItem('router-history');
            if (routerHistory) {
                this.history = [];
                routerHistory = JSON.parse(routerHistory);
                for (let i=routerHistory.length-1; i>0; i= i - 1) {
                    let p = routerHistory[i].path.split('/');
                    if (!parseInt(p[p.length-1])) continue;
                    this.history.push(routerHistory[i]);
                }
            }
        },
        setPath (r) {
            localStorage.setItem('reportFilters', '');
            if (r.endpoint) {
                if (this.$router.currentRoute.name == r.endpoint) return;
                this.$router.push({name: r.endpoint});
            } else if (r.path) {
                if (this.$router.currentRoute.path == r.path) return;
                this.$router.push({path: r.path});
            }
        },
        routeName (r) {
            let l = r.path.split('/');
            if (l.length==3 && l[1]=='abm') {
                return tr(l[2]);
            }
            let name = l[l.length-1];
            if (r.name) name = r.name;
            let prev = l[l.length-2];
            if (prev == 'report') {
                let route;
                for (let i in modules) {
                    let m = modules[i];
                    for (let e of m.endpoints) {
                        if (e.report == name) {
                            route = e;
                        }
                    }
                };
                if (route && route.label) return tr(l[l.length-2]) + ': ' + tr(route.label);
            }
            return tr(l[l.length-2]) + ': ' + tr(name);
        },
        addToMarks () {
            let routerMarks = localStorage.getItem('router-marks');
            if (routerMarks) {
                routerMarks = JSON.parse(routerMarks);
            } else {
                routerMarks = [];
            }
            let name = '';
            if (this.recordStore) name = this.recordStore.getName;
            routerMarks.push({path: this.$router.currentRoute.path, name: name})
            localStorage.setItem('router-marks', JSON.stringify(routerMarks));
            this.isMarked = true;
            this.getMarksRoutes();
        },
        checkMarked (path) {
            let routerMarks = localStorage.getItem('router-marks');
            if (routerMarks) {
                routerMarks = JSON.parse(routerMarks);
                let p = path;
                if (!p) p = this.$router.currentRoute.path;
                let f = _.find(routerMarks, (r) => r.path == p);
                 if (f) {
                    this.isMarked = true;
                } else {
                    this.isMarked = false;
                }
            }
        },
        removeToMarks () {
            let routerMarks = localStorage.getItem('router-marks');
            if (routerMarks) {
                routerMarks = JSON.parse(routerMarks);
                let f = _.findIndex(routerMarks, (r) => r.path == this.$router.currentRoute.path);
                if (f>-1) {
                    routerMarks.splice(f, 1);
                    localStorage.setItem('router-marks', JSON.stringify(routerMarks));
                    this.isMarked = false;
                    this.getMarksRoutes();
                }
            }
        },
        getMarksRoutes () {
            let routerMarks = localStorage.getItem('router-marks');
            if (routerMarks) {
                this.marks = JSON.parse(routerMarks);
            }
        },
        toggle () {
            $('#sidebar').toggleClass('active');
        },
        filterSearch (data) {
            let values = this.search.split(' ')
            for (let value of values){
                let re = new RegExp(tools.normalize(value), 'i')
                let m = tools.normalize(data).match(re)
                if (!m) return false;
            };
            return true;
        },
        setRoute (r) {
            //if (this.$router.currentRoute.name == r.endpoint) return;
            if (r.path) {
                if (this.$router.currentRoute.path == r.path) return;
                this.$router.push({path: r.path});
            } else {
                vueTools.redirect(this.$router, r.endpoint, r.tableName, r.report, r.module, r.componentName)
            }
            //this.search = '';
            this.searchList = [];
            this.paxList = [];
        },
        updateUserProfile () {
            this.$store.commit('setUser', api.currentUser);
        },
        setSearch () {
            if (this.search.length < 3) {
                this.searchList = [];
                this.paxList = [];
                return;
            }
            let res = [];
            for (let name in modules) {
                let module = modules[name];
                let access = tools.canAccessModule(this.user, module);
                if (!access) continue;
                for (let endpoint of module.endpoints) {
                    let access = frontTools.routeAccess(endpoint);
                    let userAccess = tools.canAccess(this.user, "view", "GET", `/${access}`);
                    if (!userAccess) continue;
                    let label = endpoint;
                    if (endpoint.label) label = endpoint.label;
                    if (this.filterSearch(tr(label))) {
                        if (!endpoint.label) {
                            endpoint = {
                                tableName: endpoint,
                                label: endpoint,
                                endpoint: 'abm',
                            }
                        } else {
                            endpoint = {
                                endpoint: endpoint.endpoint,
                                label: endpoint.label,
                                table: endpoint.table,
                                report: endpoint.report,
                                componentName: endpoint.componentName,
                            }
                        }
                        let f = _.find(res, (r) => {
                            return label == r.label;
                        })
                        if (!f) {
                            res.push(endpoint);
                        }
                    }
                }
            }
            this.searchList = res;
        },
        focusOn () {
            this.setSearch()
        },
        showTimerList () {
            vueTools.redirect(this.$router, 'report', null, 'timerlist', 'System', 'TimerListReport');
        }
    },
    watch: {
        search: {
          handler () {
              this.setSearch()
          }
        }
    },
    beforeDestroy() {
        EventBus.$off('check-marked');
        EventBus.$off('update-history', this.getHistoryRoutes);
        EventBus.$off('update-profile');
    },
};
</script>

<style>
    .nav-item .form-material .form-group input {
        color: rgba(255,255,255,.5) !important;
    }
    .nav-item .form-material .form-group .float-label {
        color: rgba(255,255,255,.5) !important;
    }
</style>
