<template>
    <div class="form-group form-default" v-if="options">
        <div class="d-flex align-items-end">
            <div :class="{'w-75': icons, 'w-100': !icons}">
                <label v-if="labelTop" :for="fieldName">{{tr(labelTop)}}</label>
                <select v-model="fieldValue" :name="fieldName" @change="change" @blur="blur"
                  @focus="focusOn" :class="classes"
                  class="form-control" :id="id || fieldName" :disabled="disabled">
                    <option v-for="opt of options" :value="opt.value"
                    :selected="opt.value == fieldValue" :disabled="opt.disabled">
                        {{tr(opt.label)}}
                    </option>
                </select>
                <span class="form-bar">
                </span>
                <label class="float-label" v-if="label">{{tr(label)}}</label>
            </div>
            <font-awesome-icon :icon="fieldValue" v-if="icons && fieldValue" class="w-25 mb-1" size="lg"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'select-input',
    props: ['fieldName', 'fieldOptions', 'label', 'labelTop', 'currentValue', 'required', 'disabled', 'id', 'addClass',
        'addBlank', 'notSort', 'sortInteger', 'field', 'record', 'icons'],
    data () {
        return {
            fieldValue: null,
        }
    },
    computed:{
        classes () {
            let r = [];
            if (this.fieldValue) r.push('fill');
            if (this.addClass) r.push(this.addClass);
            if (this.disabled) r.push('disabled');
            return r.join(' ');
        },
        readOnly () {
            if (this.disabled) return true;
            if (this.field && this.field.disabledIf && this.record) {
                return this.field.disabledIf(this.currentValue);
            }
        }
    },
    created () {
        this.fieldValue = this.currentValue;
    },
    watch: {
        currentValue () {
            this.fieldValue = this.currentValue;
        },
    },
    asyncComputed: {
        async options () {
            let self = this;
            let options;
            if (this.fieldOptions) {
                options = _.cloneDeep(this.fieldOptions);
            } else if (this.field) {
                let fieldOptions = await tools.calculateFieldOptions([this.field], null, this.record, null, true,
                  this.field.name, this.currentValue);
                options = fieldOptions[this.field.name];
            }
            if (this.addBlank && options) {
                options.push({value: null, label: ''})
            }
            if (options && !this.notSort) {
                options.sort(( a, b ) => {
                    let aLabel = a.label;
                    if (aLabel && typeof aLabel === 'object') {
                        aLabel = aLabel.label;
                    }
                    if (aLabel) aLabel = tr(aLabel)
                    if (a.sortBy) aLabel = a.sortBy;

                    let bLabel = b.label;
                    if (bLabel && typeof bLabel === 'object') {
                        bLabel = bLabel.label;
                    }
                    if (bLabel) bLabel = tr(bLabel)
                    if (b.sortBy) bLabel = b.sortBy;
                    if (self.sortInteger) {
                        aLabel = parseInt(aLabel);
                        bLabel = parseInt(bLabel);
                    } else {
                        if (aLabel) aLabel = aLabel.toLowerCase()
                        if (bLabel) bLabel = bLabel.toLowerCase()
                    }
                    if ( aLabel < bLabel ){
                        return -1;
                    }
                    if ( aLabel > bLabel ){
                        return 1;
                    }
                    return 0;
                });
            }
            return options;
        }
    },
    methods: {
        async change () {
            await this.$nextTick();
            this.$emit('update:currentValue', this.fieldValue);
            this.$emit('change', this.fieldValue, this.id, this.fieldName);
        },
        focusOn () {
            this.$emit('focus', this.fieldValue, this.id, this.fieldName);
        },
        blur () {
            this.$emit('blur');
        }
    }
}
</script>
