<template>
    <div class="modal fade bd-example-modal-lg" id="ticket-comments-modal" tabindex="-1" role="dialog"
        data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true" v-if="ready">
      <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
          <div class="modal-header text-center">
            <div class="d-flex justify-content-between w-100 align-items-center">
                <h4 class="modal-title">Ticket: {{newTicket? 'new': record.id}} / {{record.Subject}}</h4>
                <timer :ticketId="record.id" v-if="!customerId"></timer>
            </div>
            <button type="button" class="close" @click="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
              <div class="form-row">
                  <div class="col-3 border-right d-flex align-items-start">
                      <div class="form-row form-material">
                          <div class="col-12 mb-0 pr-4" v-if="fieldStatus">
                              <input-field
                                :field="fieldStatus"
                                :defValue.sync="record.Status"
                              ></input-field>
                          </div>
                          <div class="col-12 mb-2" v-else>Status: {{record.Status}}</div>

                          <div class="col-12 mb-0 pr-4" v-if="fieldPriority">
                              <input-field
                                :field="fieldPriority"
                                :defValue.sync="record.Priority"
                              ></input-field>
                          </div>
                          <div class="col-12 mb-2" v-else>Priority: {{record.Priority}}</div>
                          <div class="col-12 mb-2 pr-4" v-if="!customerId && fieldCustomer">
                              <input-field
                                :field="fieldCustomer"
                                :defValue.sync="record.CustomerId"
                              ></input-field>
                          </div>
                          <div class="col-12 mb-2 pr-4" v-else-if="!customerId && record.Customer">
                              {{record.Customer.Name}}
                          </div>
                          <div class="col-12 mb-2 pr-4" v-if="!customerId && fieldTicketType">
                              <input-field
                                :field="fieldTicketType"
                                :defValue.sync="record.TicketTypeId"
                              ></input-field>
                          </div>
                          <div class="col-12 mb-2 pr-4" v-else-if="!customerId && record.TicketType">
                              {{record.TicketType.Name}}
                          </div>
                          <div class="col-12 mb-0 pr-4" v-if="fieldUser">
                              <input-field
                                :field="fieldUser"
                                :defValue.sync="record.CreatedUserId"
                              ></input-field>
                          </div>
                          <div class="col-12 mb-2 pr-4" v-else-if="record.CreatedUserId">
                              Added by: {{record.CreatedUser.UserName}}
                          </div>
                          <div class="col-12 mb-0 pr-4" v-if="fieldAssigned">
                              <input-field
                                :field="fieldAssigned"
                                :defValue.sync="record.AssignedUserId"
                              ></input-field>
                          </div>
                          <div class="col-12 mb-0 pr-4" v-if="!customerId">
                              <input-field
                                :field="billableField"
                                :defValue.sync="record.Billable"
                              ></input-field>
                          </div>
                          <div class="col-12 mb-2 pr-4" v-else-if="record.AssignedUserId">
                              Assigned to: {{record.AssignedUser.UserName}}
                          </div>

                          <div class="col-12 mb-2 pr-4" v-if="record.CreatedUTC">
                              Date created: {{record.CreatedUTC | toLocalDateTime}}
                          </div>
                          <div class="mt-3 mr-3 text-center w-100">
                              <button type="button" class="btn btn-secondary w-100" @click="addComment">
                                  NEW COMMENT
                              </button>
                          </div>
                          <div class="mt-3 mr-3 text-center w-100">
                              <button type="button" class="btn btn-secondary w-100" @click="post"
                                  :disabled="!record.id">
                                  UPDATE
                              </button>
                          </div>
                    </div>
                </div>
                  <div class="col-9 form-material" id="row-tickets">
                      <div v-for="row of record.TicketComments" class="form-row py-3 mx-3">
                          <div class="w-100 border border-dark rounded px-3">
                              <label class="font-weight-bold pt-2 mt-0">Posted by: {{userName(row.CreatedUserId)}} {{row.CreatedUTC | toLocalDateTime}}</label>
                              <text-rows-style :text="row.Text" class="border-top pt-2 mt-0"></text-rows-style>
                              <attach :attached="row.attachments" :canAttach="false" :hideTitle="true" class="">
                              </attach>

                          </div>
                      </div>
                      <div class="mx-3" v-if="newComment">
                          <input-field
                            v-if="newTicket"
                            :field="{name: 'Subject', editor: 'text', label: 'Subject'}"
                            :defValue.sync="record.Subject"
                          ></input-field>
                          <text-area-style
                            :text.sync="comment"
                            class="w-100 mr-3"
                          ></text-area-style>
                          <div class="d-flex flex-row align-items-center justify-content-between">
                              <attach :dataList.sync="attachList" :canAttach="true" class="mt-2" :id="id">
                              </attach>
                              <div>
                                  <button type="button" class="btn btn-secondary mt-2 mr-3" @click="post"
                                      :disabled="!record.Priority || !record.Subject">
                                      SEND
                                  </button>
                                  <button type="button" class="btn btn-secondary mt-2" @click="cancel">CANCEL</button>
                              </div>
                          </div>

                      </div>

                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
</template>


</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'ticket-comments',
    props: ['id', 'newTicket'],
    data () {
        return {
            newComment: false,
            comment: null,
            fieldOptions: { name: 'comment', editor: 'text-area'},
            record: {},
            ready: false,
            fieldPriority: null,
            fieldStatus: null,
            fieldCustomer: null,
            fieldTicketType: null,
            billableField: null,
            fieldUser: null,
            fieldAssigned: null,
            attachList: [],
        }
    },
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
        }),
        customerId () {
            return api.currentUser.CustomerId;
        },
    },
    async mounted () {
        if (this.newTicket) {
            this.ready = true;
            let record = {
                Subject: null,
                Priority: null,
                Status: 'REQUESTED'
            }
            await this.setRecord(record);
            this.newComment = true;
            this.ready = true;
            await this.$nextTick();
            $('#ticket-comments-modal').modal({backdrop: 'static', keyboard: false}, 'show');
        } else if (await this.getRecord(this.id)) {
            this.ready = true;
            await this.$nextTick();
            $('#ticket-comments-modal').modal({backdrop: 'static', keyboard: false}, 'show');
            let interval = setInterval(() => {
                var objDiv = document.getElementById("row-tickets");
                if (objDiv) {
                    objDiv.scrollTop = objDiv.scrollHeight;
                    clearInterval(interval);
                }
            }, 200);
        } else {
            this.$emit('update:id', false);
            this.$emit('update:newTicket', false);
        }
    },
    components: {
    },
    methods: {
        async setRecord (record) {
            let model = api.importMixinModule('record', 'model');
            let options = await api.getModuleFieldsOptions('ticket');
            let r = await new model({tableName: 'ticket', options, record});
            this.fieldPriority = r.fieldsObject.Priority;
            this.fieldCustomer = r.fieldsObject.CustomerId;
            this.fieldTicketType = r.fieldsObject.TicketTypeId;
            this.billableField = r.fieldsObject.Billable;
            if (api.currentUser && !api.currentUser.CustomerId) {
                this.fieldStatus = r.fieldsObject.Status;
                this.fieldUser = r.fieldsObject.CreatedUserId;
                this.fieldAssigned = r.fieldsObject.AssignedUserId;
            }
            this.record = r;
        },
        async getRecord (id) {
            api.setProcessing(true);
            let res = await api.get('/api/ticket/' + id);
            if (res) {
                await this.setRecord(res)
                //this.record = res;
                api.setProcessing(false);
                return res;
            }
            api.setProcessing(false);
        },
        cancel () {
            this.newComment = false;
            this.comment = null;
            if (this.newTicket) {
                this.close();
            }
        },
        addComment () {
            this.newComment = true;
            setTimeout(() => {
                var objDiv = document.getElementById("row-tickets");
                if (objDiv) {
                    objDiv.scrollTop = objDiv.scrollHeight;
                }
            }, 100);
        },
        close () {
            if (!this.customerId && this.$root.$children[0].$refs.navBar.$refs.mainTimer) {
                this.$root.$children[0].$refs.navBar.$refs.mainTimer.refresh();
            }
            this.$emit('update:id', false);
            this.$emit('update:newTicket', false);
            if (this.$parent.getTickets) this.$parent.getTickets();
            $('#ticket-comments-modal').modal('hide');
        },
        async post () {
            api.setProcessing(true)
            let res = await api.post('/api/tickets/new_comment/', JSON.stringify(
                {
                    id: this.id,
                    comment: this.comment,
                    priority: this.record.Priority,
                    subject: this.record.Subject,
                    customerId: this.record.CustomerId,
                    ticketTypeId: this.record.TicketTypeId,
                    cratedUserId: this.record.CreatedUserId,
                    status: this.record.Status,
                    assignedUserId: this.record.AssignedUserId,
                    billable: this.record.Billable
                }
            ));
            if (res) {
                if (res.TicketComments && res.TicketComments[res.TicketComments.length - 1] && res.TicketComments[res.TicketComments.length - 1].id) {
                    await api.saveAttachments('ticketcomment', res.TicketComments[res.TicketComments.length - 1].id, this.attachList, (r) => {
                        //console.log(r)
                    });

                }
            }
            if (res) {
                this.newComment = false;
                this.$emit('update:id', res.id);
                this.$emit('update:newTicket', false);
                this.comment = null;
                await this.getRecord(res.id);
            }
            api.setProcessing(false)
        },
        userName (userId) {
            let user = _.find(api.tables.user, (r) => r.id==userId);
            if (user) {
                return user.UserName;
            }
        },

    }
}
</script>

<style scoped>
 #ticket-comments-modal {
    overflow-y: hidden;
    max-height: 90vh !important;
    height: 90vh !important;
    display: block !important;
 }
.modal-dialog{
    overflow-y: initial !important
}
#row-tickets {
    overflow-y: scroll !important;
    position: relative;
    height: 75vh !important;
}

</style>
