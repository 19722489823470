<template>
    <div class="page-title-container mb-3 ">
        <nav class="row d-flex px-3">
            <div class="col-lg-6 col-xs-10">
                <div class="pb-0 display-4"><h3>{{tr(pageTitle)}}</h3></div>
            </div>
        </nav>
        <abm
            :key="table"
            v-if="user && ready && tableName"
            :endpoint="apiEndpoint"
            :options="options"
            :tableName="tableName"
        ></abm>
    </div>
</template>

<script>
const abm = importVueComp('components/tools', 'abm');
import { mapState } from 'vuex';
export default {
    props: ['table'],
    name: 'abm-table',
    components: {
        abm,
    },
    async mounted () {
        await this.onMount();
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
        pageTitle () {
            if (this.options.title) return this.options.title;
            return this.tableName;
        }
    },
    data () {
        return {
            apiEndpoint: null,
            ready: false,
            options: {},
            tableName: null,
            module: null,
        }
    },
    methods: {
        async onMount () {
            let table = this.table;
            if (!table) {
                table = this.$router.currentRoute.path.split('/')[2];
            }
            this.module = frontTools.getModuleByPath(table);
            this.tableName = table;
            this.apiEndpoint = '/api/' + table + '/';
            this.options = Object.assign(this.options, await api.getModuleFieldsOptions(table));
            if (this.options.apiEndpoint) {
                this.apiEndpoint = this.options.apiEndpoint;
            }
            if (this.options.getEndpoint) {
                this.apiEndpoint = this.options.getEndpoint;
            }
            if (this.options.tableName) {
                this.tableName = this.options.tableName;
            }
            this.ready = true;
        },
        toggle () {
            $('#sidebar').toggleClass('active');
        }
    }
}
</script>

