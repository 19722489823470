import Record from '@/base/model/record';

class Mail extends Record {

    static moduleName = 'mail';

    async saveMailAndSend (callback) {
        let res = await this.sendMail(this.id, callback)
        if (res) {
            await this.init(res);
            this.addNotification('Message sent');
        }
        if (callback) callback(null, {update: true});
    }

    async sendMail (mailId, callback){
    	  let data = new FormData();
        data.append('mailId', mailId);
        return await api.post('/api/mail/send_mail', data, {}, callback);
    }

    get titleName () {
        if (this.id) return `Mail ${this.id}`;
        return 'Mail'
    }

    async getAttached () {
        await super.getAttached();
        if (this && this.id) {
            for (let row of this.MailAttachments) {
                this.attached.push({
                    url: row.URL,
                    key: row.Key,
                    name: row.Name? row.Name: row.Key
                })
            }
        }
    }

    get attachPending () {
        let res = super.attachPending;
        for (let row of this.MailAttachments) {
            if (!row.id) {
                res.push({name: row.Name})
            }
        }
        return res;
    }

}


export default Mail;
