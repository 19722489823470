<template>
    <div class="mr-3 d-flex justify-content-center align-items-center font-weight-bold" :class="{'nav-timer': !ticketId}">
       <div class="ml-2 w-ticket" v-if="!ticketId && lastOpen">
          <span v-if="lastOpen.TicketId">T: {{lastOpen.TicketId}}</span>
       </div>
        <div class="w-clock">
            <span v-if="showCLock" class="ml-3">{{time}}</span>
        </div>
        <font-awesome-icon icon="play" @click="start" v-if="showPlay" class="ml-3 cursor-pointer"/>
        <font-awesome-icon icon="stop" @click="stop" v-else class="ml-3 cursor-pointer button-stop"/>
        <div v-if="!ticketId" class="ml-3 d-flex align-items-center form-material">
             <div class="w-subject pl-2">
                 <label v-if="lastOpen && lastOpen.Subject && !editSubject" class="mb-0" @click="setEditSubject">
                    {{lastOpen.Subject}}
                 </label>
                 <div class="px-1" v-else-if="lastOpen">
                   <input type="text" v-model="subject" class="form-control text-white" @blur="setSubject" placeholder="Add subject" >
                      <span class="form-bar"></span>
                   </input>
                 </div>
             </div>
             <div class="w-customer ml-1">
                <label v-if="lastOpen && lastOpen.CustomerId && !editCustomer" class="mb-0 px-2" @click="setEditCustomer">
                    {{lastOpen.Customer.Code}}
                 </label>
                 <div class="px-1" v-else-if="lastOpen && customerOptions && !lastOpen.TicketId">
                     <select-input :fieldOptions="customerOptions"
                        :label="customerId? '': 'Customer'"
                        class="my-0"
                        addClass="text-white"
                        :currentValue.sync="customerId"
                        @change="setCustomer"
                        @blur="setCustomer"
                    ></select-input>
                 </div>
             </div>
             <div class="w-type ml-1">
                <label v-if="lastOpen && lastOpen.TicketTypeId && !editTicketType" class="mb-0 px-2" @click="setEditTicketType">
                    {{lastOpen.TicketType.Name}}
                 </label>
                 <div class="px-1" v-else-if="lastOpen && ticketTypesOptions && !lastOpen.TicketId">
                     <select-input :fieldOptions="ticketTypesOptions"
                        :label="ticketTypeId? '': 'Type'"
                        class="my-0"
                        addClass="text-white"
                        :currentValue.sync="ticketTypeId"
                        @change="setTicketType"
                        @blur="setTicketType"
                    ></select-input>
                 </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['ticketId'],
    name: 'timer',
    components: {
    },
    computed: {
        showPlay () {
            if (!this.lastOpen) return true;
            if (this.ticketId && this.lastOpen && this.lastOpen.TicketId != this.ticketId) return true;
            if (!this.ticketId && this.lastOpen && this.lastOpen.EndDateTime) return true;
            if (this.ticketId && this.lastOpen && this.lastOpen.Ticket == this.ticketId && this.lastOpen.EndDateTime) return true;
        },
        showCLock () {
            if (!this.ticketId && this.lastOpen && !this.lastOpen.EndDateTime) return true;
            if (this.ticketId && this.lastOpen && this.lastOpen.TicketId == this.ticketId && !this.lastOpen.EndDateTime) return true;
            if (this.lastOpen && this.lastOpen.EndDateTime) return true;
        }
    },
    async mounted () {
        this.refresh();
        if (!api.tables.customer) await api.getTable('customer');
        if (!api.tables.tickettype) await api.getTable('tickettype');
        this.customerOptions = _.map(api.tables.customer, (r) => {
            return {value: r.id, label: r.Code}
        });
        this.ticketTypesOptions = _.map(api.tables.tickettype, (r) => {
            return {value: r.id, label: r.Name}
        });
    },
    data () {
        return {
            lastOpen: null,
            interval: null,
            time: null,
            subject: null,
            customerId: null,
            customerOptions: [],
            editSubject: false,
            editCustomer: false,
            editTicketType: false,
            ticketTypeId: null,
            ticketTypesOptions: [],
        }
    },
    methods: {
        async refresh () {
            let res = await api.get('/api/timer/last_open/');
            if (res) {
                this.lastOpen = res;
                this.subject = null;
                this.customerId = null;
                this.ticketTypeId = null;
                this.editSubject = false;
                this.editCustomer = false;
                this.editTicketType = false;
                if (this.lastOpen.Subject) this.subject = this.lastOpen.Subject;
                if (this.lastOpen.CustomerId) this.customerId = this.lastOpen.CustomerId;
                if (this.lastOpen.TicketTypeId) this.ticketTypeId = this.lastOpen.TicketTypeId;
                if (!this.lastOpen.EndDateTime) {
                    this.startClock()
                };
            } else {
                this.clean();
            }
        },
        clean () {
            this.lastOpen = null;
            this.subject = null;
            this.customerId = null;
            this.ticketTypeId = null;
            clearInterval(this.interval)
            this.interval = null;
        },
        startClock () {
            if (this.interval) return;
            this.interval = setInterval(() => {
                this.updateClock();
            }, 1000);
        },
        updateClock() {
            const now = moment.utc();
            const elapsedTime = moment.duration(now.diff(moment.utc(this.lastOpen.StartDateTime)));
            const hours = elapsedTime.hours();
            const minutes = elapsedTime.minutes();
            const seconds = elapsedTime.seconds();
            const formattedTime = `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
            this.time = formattedTime;
        },
        padZero(num) {
            return num < 10 ? `0${num}` : num;
        },
        async start () {
            if (this.ticketId) {
                let res = await api.post('/api/timer/start_timer/', JSON.stringify({ticketId: this.ticketId}))
                if (res) {
                    this.lastOpen = res;
                    this.updateMain();
                    this.startClock();
                }
            } else if (this.lastOpen && this.lastOpen.id) {
                let data = {
                    id: this.lastOpen.id,
                    ticketId: this.lastOpen.TicketId,
                    subject: this.lastOpen.Subject,
                    customerId: this.lastOpen.CustomerId,
                    ticketTypeId: this.lastOpen.ticketTypeId,
                }
                let res = await api.post('/api/timer/start_timer/', JSON.stringify(data))
                if (res) {
                    this.lastOpen = res;
                    this.updateMain();
                    this.startClock();
                }
            } else {
                let data = {
                }
                let res = await api.post('/api/timer/start_timer/', JSON.stringify(data))
                if (res) {
                    this.lastOpen = res;
                    this.updateMain();
                    this.startClock();
                }
            }

        },
        async stop () {
            if (this.lastOpen && this.lastOpen.id) {
                let res = await api.post('/api/timer/end_timer/', JSON.stringify({id: this.lastOpen.id}))
                if (res) {
                    this.clean();
                    this.updateMain();
                  }
            }
        },
        updateMain () {
            if (this.ticketId) {
                this.$root.$children[0].$refs.navBar.$refs.mainTimer.refresh();
            }
        },
        async setSubject () {
            let res = await api.post('/api/timer/update_data/', JSON.stringify({id: this.lastOpen.id, subject: this.subject }))
            if (res) {
                this.lastOpen = res;
            }
            this.editSubject = false;
        },
        async setCustomer () {
            if (this.lastOpen && this.lastOpen.CustomerId == this.customerId) {
                this.editCustomer = false;
                return;
            }
            let res = await api.post('/api/timer/update_data/', JSON.stringify({id: this.lastOpen.id, customerId: this.customerId }))
            if (res) {
                this.lastOpen = res;
            }
            this.editCustomer = false;
        },
        async setTicketType () {
            if (this.lastOpen && this.lastOpen.TicketTypeId == this.ticketTypeId) {
                this.editTicketType = false;
                return;
            }
            let res = await api.post('/api/timer/update_data/', JSON.stringify({id: this.lastOpen.id, ticketTypeId: this.ticketTypeId }))
            if (res) {
                this.lastOpen = res;
            }
            this.editTicketType = false;
        },
        setEditSubject () {
            if (this.lastOpen && this.lastOpen.TicketId) return;
            this.editSubject = true;
        },
        setEditCustomer () {
            if (this.lastOpen && this.lastOpen.TicketId) return;
            this.editCustomer = true;
        },
        setEditTicketType () {
            if (this.lastOpen && this.lastOpen.TicketId) return;
            this.editTicketType = true;
        },
    },
    beforeDestroy() {
        clearInterval(this.interval)
    },
}
</script>

<style scoped>
input::placeholder {
  font-weight: bold;
  opacity: 0.5;
  color: white;
}
.w-subject {
  min-width: 200px;
  border-right: 1px solid white;
  border-left: 1px solid white;
}
.w-customer {
  min-width: 80px;
  border-right: 1px solid white;
}
.w-type {
  min-width: 120px;
}
label {
  font-weight: bold;
  color: white;
}
.nav-timer {
  width: 800px;
  color: white;
}
.form-material {
}
.w-ticket {
  min-width: 50px;
  border-right: 1px solid white;
}
.w-clock {
  min-width: 80px;
}

</style>