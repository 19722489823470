<template>
    <div v-if="notifications" class="col-12 notifications">
        <div v-if="notifications.message" class="alert alert-success" role="alert">
            {{ tr(notifications.message) }}.
        </div>
        <div v-if="notifications.warning" class="alert alert-warning" role="alert">
            {{ tr(notifications.warning) }}.
        </div>
        <div v-if="notifications.errors.length>0" class="alert alert-danger" role="alert">
            <span>
                {{ notificationsError }}.
            </span>
            <span v-if="dependencies" class="cursor-pointer" @click="showDependencies">
                {{tr('View Dependencies')}}.
            </span>
            <span v-if="notifications.errorDetail && !detail" class="cursor-pointer" @click="detail = true;">
                {{tr('View Detail')}}.
            </span>
            <span v-if="detail">
                {{ notifications.errorDetail }}.
            </span>
        </div>

    </div>
</template>

<script>
export default {
    name: 'app-notification',
    props: ['notifications', 'id', 'dependencies', 'tableName'],
    data () {
        return {
            detail: false,
        }
    },
    methods: {
        showDependencies () {
            let router = require('@/router/index').default;
            let route = this.$router.resolve({
                name: 'report',
                params: {
                    report: 'dependencies',
                },
            })
            let query = {
                tableName: this.tableName,
                id: this.id
            }
            query = new URLSearchParams(query).toString();
            window.open(route.href + '?' + query, '_blank');
        },
    },
    computed: {
        notificationsError () {
            return _.map(this.notifications.errors, (p) => {return tr(p)}).join('; ');
        }

    }

}
</script>
