import Record from '@/base/model/record';

class Timer extends Record {

    static moduleName = 'timer';

    constructor({fields, tableName, options, endpoint, record}) {
        super({fields, tableName, options, endpoint, record});
        let self = this;
        return (async () => {
            let r = await new Promise((resolve, reject) => {
                resolve(self);
            })
            if (r.StartDateTime) r.setStart();
            if (r.EndDateTime) r.setEnd();
            return r;
        })();
    }



    afterEdit() {
        return {
            StartTime: (r) => {r.setStartDateTime()},
            StartDate: (r) => {r.setStartDateTime()},
            EndTime: (r) => {r.setEndDateTime()},
            EndDate: (r) => {r.setEndDateTime()},
            StartDateTime: (r) => {setStart()},
            EndDateTime: (r) => {setEnd()}
        }
    }

    setStartDateTime () {
        if (this.StartDate && this.StartTime) {
            let v = this.StartDate + 'T' + this.StartTime;
            this.$StartDateTime.value = moment(v).utc().format('YYYY-MM-DDTHH:mm:ss');
        }
    }

    setEndDateTime () {
        if (this.EndDate && this.EndTime) {
            let v = this.EndDate + 'T' + this.EndTime;
            this.$EndDateTime.value = moment(v).utc().format('YYYY-MM-DDTHH:mm:ss');
        }
    }

    setStart () {
        if (this.StartDateTime) {
            this.$StartDate.value = moment.utc(this.StartDateTime).local().format('YYYY-MM-DD')
            this.$StartTime.value = moment.utc(this.StartDateTime).local().format('HH:mm')
        }
    }

    setEnd () {
        if (this.EndDateTime) {
            this.$EndDate.value = moment.utc(this.EndDateTime).local().format('YYYY-MM-DD')
            this.$EndTime.value = moment.utc(this.EndDateTime).local().format('HH:mm')
        }
    }


}


export default Timer;
