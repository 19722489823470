export default  {
    systemMenu: {
        label: 'System',
        user_access: 'system_menu',
        icon: 'cog',
        value: false,
        def: true,
        endpoints: [
            'mail',
            'user',
            'customer',
            'ticket',
            'tickettype',
            'timer',
            'accessgroup',
            { endpoint: 'request', label: 'Request'},
            {endpoint: 'report', label: 'Timer List', componentName: 'TimerListReport', report: 'timerlist'},
            {endpoint: 'report', label: 'User Activity', componentName: 'UserActivityReport', report: 'useractivity'},
            {endpoint: 'report', label: 'User Sessions', componentName: 'UserSessionReport', report: 'userasession'},
            {endpoint: 'report', label: 'System Logs', componentName: 'SystemLogsReport', report: 'systemlogs'},
        ]
    },
    ticketsMenu: {
        label: 'Tickets',
        icon: 'tools',
        user_access: 'tickets_menu',
        items_access: 'tickets_menu_items',
        value: false,
        def: true,
        endpoints: [
            { endpoint: 'dashboard', label: 'Tickets'},
        ],
    },
}
