import View from '@/base/views/view';

class MailView extends View {

    static table = 'mail';

    static options () {
        let obj = {
            fieldsDataReplace: {
                MailAttachments: { name: 'MailAttachments', editor: [], label: 'MailAttachments', label: 'Link a Adjuntos',
                    rowFields: [
                        { name: 'Name', label: 'Name', columns: 3},
                        { name: 'Key', label: 'Key', columns: 4},
                        { name: 'URL', label: 'URL', columns: 4},
                   ]
                },
            },
            extraFields: [
                { name: 'tab4', editor: 'component', component: 'MailActivity', label: 'Activity',
                       componentName: 'MailActivity', componentPath: 'components/tools', inline: true
                },
                { name: 'Attach', label: 'Attachments', editor: 'attach', columns: 12, hideFromList: true},
            ],
            canCopy: true,
            title: 'Mails',
            actions: [
                {
                    label: 'Send',
                    method: 'saveMailAndSend',
                    id: 'sendMail'
                }
            ],
            beforeSave (self) {
                if (!self.MailFrom) {
                    self.addError('Falta configurar casill de correo');
                    return true;
                }
            }
        }
        return obj
    }

    static async getTableOptions () {
        let res = await super.getTableOptions(this.options());
        let id = _.find(res.fields, (r) => r.name == 'id');
        if (id) {
            id.hidden = false;
            id.hideFromList = false;
        }
        let body = _.find(res.fields, (r) => r.name == 'MessageBody');
        if (body) body.rows = 10;
        return res;
    }

}

export default MailView;
