import View from '@/base/views/view';

class UserProfileView extends View {

    static table = 'user';

    static getTableOptions () {
        return {
            fields: [
                { name: 'FirstName', label: 'Name'},
                { name: 'LastName', label: 'Last Name'},
                { name: 'Email', label: 'Email'},
                { name: 'Phone', label: 'Phone'},
                { name: 'DecimalSeparator', label: 'Decimal Separator'},
            ],
            title: 'User',
            afterSave: (record, self) => {
                api.updateUser(record)
            },
        }
    }

}

export default UserProfileView;
