import View from '@/base/views/view';

class TimerView extends View {

    static table = 'timer';

    static options () {
        return {
            serverSide: true,
            canAdd: true,
            canCopy: true,
        }
    }

    static async getTableOptions () {
        let res = await super.getTableOptions(this.options());
        let ticket = _.find(res.fields, (r) => r.name == 'TicketId');
        ticket.optionLabels = 'Subject';
        res.fields.splice(0, 0, { name: 'id', readonly: true })
        res.fields.push({ name: 'StartDate', editor: 'date', hideFromList: true})
        res.fields.push({ name: 'StartTime', editor: 'time', hideFromList: true})
        res.fields.push({ name: 'EndDate', editor: 'date', hideFromList: true})
        res.fields.push({ name: 'EndTime', editor: 'time', hideFromList: true})
        return res;
    }
}

export default TimerView;
