
import $ from 'jquery';
import jQuery from 'jquery';
window.$ = $;
window.jQuery = $;
import * as _ from 'lodash';
window._ = _;
var moment = require('moment');
window.moment = moment;

import '@/base/api';
import '@/base/tools';
import '@/base/model/cache';
import '@/base/abm';

window.modelTables = {
    timer: true,
    mail: true,
}

