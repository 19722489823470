
class Abm {

    constructor({table, endpoint, options}) {
        let self = this;
        return (async () => {
            self.table = table;
            self.showClosed = false;
            self.serverSide = options.serverSide;
            self.originEndpoint = endpoint;
            self.endpoint = self.getEndpoint();
            self.search = null;
            self.fields = options.fields;
            if (!options.fields) {
                let o = await api.getModuleFieldsOptions(table);
                self.fields = o.fields;
            }
            self.fieldsAbmList = options.fieldsAbmList;
            self.dataList = [];
            self.dataFilter = options.dataFilter;
            self.listValues = {};
            self.hasClosed = self.getHasClosed();
            self.notifications = {
                errors: [],
                message: null,
                warning: null,
            }
            self.tableSpec = {};
            self.serverFields = null;
            self.fieldOptions = await tools.calculateFieldOptions(self.abmFields, self.serverSide);
            self.columnsNames = self.getColumnsNames();
            self.headings = self.getHeadings();
            self.templates = self.getTemplates();
            return self;
        })();
    }

    getEndpoint () {
      let endpoint = this.originEndpoint;
      let char = '?';
      if (endpoint.includes('?')) char = '&';
      return endpoint + char +'IncludeClosed=' + this.showClosed;

    }

    get abmFields () {
        return tools.getAbmFields(this.fields, true);
    }

    getHasClosed () {
        for (let f of this.fields) {
            if (f === 'Closed' || f.name === 'Closed') return true;
        }
        return false;
    }

    async fetchList (tableName, endpoint, filters, refresh) {
        let e = endpoint ? endpoint: this.getEndpoint();
        let list;
        list = await tools.fetchTable(tableName, e, filters, refresh, null, this.showClosed);
        if (list){
            this.dataList = _.each(_.filter(list, (r) => {
                if (r.Closed && !this.showClosed) return false;
                if (this.dataFilter) {
                    if (!this.dataFilter(r)) return false;
                }
                return true;
            }), (c) => {
                c.original_id = c.id
            })
        }
    }

    getValues () {
        this.listValues = {};
        for (let field of this.abmFields) {
            for (let row of this.dataList) {
                let r = tools.getDisplayValue(row, field, this.serverSide, this.fieldOptions, true);
                if (!this.listValues[[field.name, row[field.name]]]) {
                    this.listValues[[field.name, row[field.name]]] = r;
                }
            }
        }
    }

    abmList () {
        return tools.abmList(this);
    }

    getColumnsNames () {
        let res = [];
        for (let f of this.abmFields) {
            if (f.type=='password') continue;
            if (f.hideFromList) continue;
            if (f.hidden) continue;
            if (f.editor=='tab') continue;
            //if (f.editor=='button') continue;
            if (f.editor=='component') continue;
            if (typeof f.editor === 'object') continue;
            if (Array.isArray(f.editor)) continue;
            res.push(f.name);
        }
        return res;
    }

    getHeadings () {
        let res = {};
        for (let f of this.abmFields) {
            if (f.type=='password') continue;
            if (f.hidden) continue;
            if (f.label) {
                res[f.name] = f.label;
            }
        }
        return res;
    }

    getTemplates () {
        let res = {}
        let self = this;
        for (let field of this.abmFields) {
            if (field.editor == 'text-area-style') continue;
            res[field.name] = function(t, row) {
                return tools.getDisplayValue(row, field, self.serverSide, self.fieldOptions, true);
            }
        }
        return res;
    }

    async requestFunction (data) {
        let f = Object.assign({}, data)
        f.fields = _.filter(this.abmFields, (r) => {
            return this.columnsNames.indexOf(r.name)>-1;
        });
        /*return await api.get(this.endpoint, {getCount: true, toJSON: true, IncludeClosed: this.showClosed,
            data: JSON.stringify(f)}).then((d) => {
              let dataList = _.each(d.query, (c) => {
                  c.original_id = c.id;
              })
              return {data: dataList, count: d.count};
            }).catch(function(e) {
              //this.dispatch('error', e);
            }.bind(this));*/
        let res = await api.get(this.endpoint, {getCount: true, toJSON: true, IncludeClosed: this.showClosed,
            data: JSON.stringify(f)})
        if (res) {
            let dataList = _.each(res.query, (c) => {
                c.original_id = c.id;
            })
            return {data: dataList, count: res.count};
        }
        return {data: [], count: 0};
    }


}

export default Abm;
window.abm = Abm;
